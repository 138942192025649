import { OperationI } from '@robotrader/common-types';
import { roundNumber } from '@robotrader/common-utils';

import { Block, Flex, Text } from '@/atoms';
import Colors from '@/Colors';

const EntryExitFee = (o: OperationI) => {
  const { fee, closingOperation } = o;

  const formatPrice = (p: number) => roundNumber(p, 5);

  return (
    <Flex.Container flexDirection="column" gap="0.5rem">
      <Block $inline>
        <Text.Label color={Colors.white}>E: </Text.Label>
        <Text.Label color={Colors.red}>{formatPrice(-fee)}</Text.Label>
      </Block>
      {closingOperation && (
        <Block $inline>
          <Text.Label color={Colors.white}>S: </Text.Label>
          <Text.Label color={Colors.red}>{formatPrice(-closingOperation.fee)}</Text.Label>
        </Block>
      )}
    </Flex.Container>
  );
};

export default EntryExitFee;
