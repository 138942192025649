import { useEffect, useState } from 'react';

import { isMethod, Method } from '@robotrader/common-types';
import { capitalizeString, dayjs } from '@robotrader/common-utils';
import {
  Block,
  Card,
  Colors,
  DynamicTable,
  Flex,
  Icon,
  LoadingBlock,
  RouterLink,
  Select,
  TableObjectProperties,
  Text,
} from '@robotrader/design-system';
import { useSearchParams } from 'react-router-dom';

import { useCradle } from '@/app/contexts';
import { useIsMounted } from '@/app/hooks';
import { Cradle } from '@/di/Cradle';
import { User } from '@/modules/user';

const PARAM_METHOD = 'method';

const UsersPage = () => {
  const { userBloc } = useCradle<Cradle>();
  const isMounted = useIsMounted();
  const [searchParams, setSearchParams] = useSearchParams();
  const [users, setUsers] = useState<User[] | undefined>(undefined);
  const [selectedMethod, setSelectedMethod] = useState<Method | undefined>();

  const loadUsers = async (method?: Method) => {
    userBloc.getAll({ method }).then(setUsers);
  };

  const USERS_PROPS: Array<TableObjectProperties<User>> = [
    {
      label: 'Email',
      data: 'email',
      routerLink: (u: User) => `/users/${u.id}`,
    },
    { label: 'Name', data: (u: User) => `${u.profile.name} ${u.profile.surname}` },
    {
      label: 'Is Trading?',
      data: (u: User) => (u.profile.isTrading ? 'Sí' : 'No'),
      color: (u: User) => (u.profile.isTrading ? Colors.green : Colors.red),
    },
    { label: 'Method', data: (u: User) => u.profile.method || undefined },
    { label: 'Leverage', data: (u: User) => `${u.profile.leverage}` },
    // { label: 'Leverage', data: UserLeverage },
    {
      label: 'Type',
      data: (u: User) => (u.isAdmin ? 'Admin' : 'User'),
      color: (u: User) => (u.isAdmin ? Colors.blue : Colors.white),
    },
    { label: 'Last Login', data: (u: User) => dayjs(u.lastLogin).format('DD-MM-YYYY HH:mm:ss') },
  ];

  useEffect(() => {
    if (!isMounted()) return;

    loadUsers(selectedMethod);
  }, [selectedMethod, isMounted]);

  useEffect(() => {
    if (!isMounted()) return;

    const method = searchParams.get(PARAM_METHOD);

    if (isMethod(method)) {
      setSelectedMethod(method);
    } else {
      setSelectedMethod(undefined);
    }
  }, [searchParams, isMounted]);

  if (users === undefined) {
    return <LoadingBlock loading text="Loading users..." />;
  }

  return (
    <>
      <Card.Container>
        <Card.Body>
          <Flex.Container justifyContent="space-between">
            <Block $inline>
              <Select
                isClearable
                hideSelectedOptions
                placeholder="Method"
                value={Object.entries(Method)
                  ?.filter((e) => e[1] === selectedMethod)
                  .map((e) => ({
                    value: e[1],
                    label: capitalizeString(e[0]),
                  }))}
                // options={exchanges ? exchanges.map((e) => ({ value: e.id, label: e.name })) : []}
                options={Object.entries(Method).map((e) => ({
                  value: e[1],
                  label: capitalizeString(e[0]),
                }))}
                onChange={(newValue) => {
                  const { value } = (newValue as any) || {};

                  if (isMethod(value)) {
                    searchParams.set(PARAM_METHOD, value);
                  } else {
                    searchParams.delete(PARAM_METHOD);
                  }

                  setSearchParams(searchParams);
                }}
              />
            </Block>
            <Block $inline>
              <RouterLink $size="md" to="/users/new">
                <Text.Span style={{ marginRight: '1rem' }}>Create</Text.Span>
                <Icon.User width={18} />
              </RouterLink>
            </Block>
          </Flex.Container>
        </Card.Body>
      </Card.Container>
      <Card.Container style={{ overflow: 'auto', marginTop: '1rem' }}>
        <Card.Body>
          <DynamicTable elements={users} showIdColumn properties={USERS_PROPS} uniqueKeyName="id" />
        </Card.Body>
      </Card.Container>
    </>
  );
};

export default UsersPage;
