import {
  createContext,
  createElement,
  FunctionComponent,
  memo,
  ReactNode,
  useContext,
} from 'react';

import { asValue, AwilixContainer } from 'awilix';

import { AppConfig, config, EnvironmentsConfig } from '@/core/config';

interface ContainerStore {
  container?: AwilixContainer;
}

interface ContainerProviderProps {
  container: AwilixContainer;
  children: ReactNode;
}

function getEnvVars(environments: EnvironmentsConfig, env: string | undefined): AppConfig {
  if (env === 'development' || env === 'dev') {
    return environments.dev;
  }
  if (env === 'staging') {
    return environments.staging;
  }
  if (env === 'prod') {
    return environments.prod;
  }

  return environments.prod;
}

const ContainerContext = createContext<ContainerStore>({});

const ContextProvider: FunctionComponent<ContainerProviderProps> = ({
  container,
  children,
}: ContainerProviderProps) => {
  container.register({ config: asValue(getEnvVars(config, process.env.REACT_APP_ENV)) });

  return createElement(ContainerContext.Provider, { value: { container } }, children);
};

export default memo(ContextProvider);

export function useCradle<Cradle extends object = any>(): Cradle {
  const context = useContext(ContainerContext);

  if (context.container === undefined) {
    throw new Error('AwilixContainer not provided');
  }

  const container = context.container as AwilixContainer<Cradle>;

  return container.cradle;
}
