import { MethodParameters, MethodParametersId } from '@robotrader/common-types';
import { DataError, Either } from '@robotrader/core-lib';

import { AppConfig } from '@/core/config';
import { HTTPService } from '@/modules/shared';
import {
  EditParametersProps,
  GetAllMethodParametersProps,
  MethodParametersRepository,
} from '@/modules/trader/domain';

import { GetMethodParametersResponse } from './GetMethodParametersResponse';
import { GetMethodsParametersResponse } from './GetMethodsParametersResponse';
import { MethodParametersMapper } from './MethodParametersMapper';

interface MethodParametersHTTPRepositoryProps {
  httpService: HTTPService;
  config: AppConfig;
}

export class MethodParametersHTTPRepository
  implements MethodParametersRepository
{
  private http: HTTPService;
  private config: AppConfig;

  constructor({ httpService, config }: MethodParametersHTTPRepositoryProps) {
    this.http = httpService;
    this.config = config;
  }

  async getAll(
    filters?: GetAllMethodParametersProps,
  ): Promise<Either<DataError, MethodParameters[]>> {
    const { exchangeName, method } = filters || {};

    try {
      let {
        data: { data: methodsParametersDto },
      } = await this.http.get<GetMethodsParametersResponse>(
        `${this.config.apiUrl}/admin/method-parameters`,
      );

      methodsParametersDto = methodsParametersDto
        .filter(
          (mP) =>
            exchangeName === undefined || mP.exchange.name === exchangeName,
        )
        .filter((mP) => method === undefined || mP.method === method);

      return Either.right(
        MethodParametersMapper.methodParametersDtoArrayToMethodParametersArray(
          methodsParametersDto.sort((e1, e2) => e1.id - e2.id),
        ),
      );
    } catch (error) {
      return Either.left({ kind: 'UnexpectedError', message: error as Error });
    }
  }

  async editParameters(
    mpId: MethodParametersId,
    props: EditParametersProps,
  ): Promise<Either<DataError, MethodParameters>> {
    const { parameters, enabled, schedule } = props;

    try {
      const {
        data: { data: methodParametersDto },
      } = await this.http.put<GetMethodParametersResponse>(
        `${this.config.apiUrl}/admin/method-parameters/${mpId}`,
        { parameters, enabled, schedule },
      );

      return Either.right(
        MethodParametersMapper.methodParametersDtoToMethodParameters(
          methodParametersDto,
        ),
      );
    } catch (error) {
      return Either.left({ kind: 'UnexpectedError', message: error as Error });
    }
  }
}
