import styled from 'styled-components/macro';

interface BlockProps {
  readonly $inline?: boolean;
}

const Block = styled.div<BlockProps>`
  display: ${(props) => (props.$inline ? 'inline-block' : 'block')};
  width: ${(props) => (props.$inline ? 'auto' : '100%')};
`;

export default Block;
