import {
  Email,
  Method,
  Name,
  Password,
  Surname,
  UserId,
} from '@robotrader/common-types';
import { Bloc } from '@robotrader/core-lib';

import {
  CreateUserUseCase,
  DeleteUserUseCase,
  EditUserLeverageUseCase,
  EditUserMethodUseCase,
  GetUsersUseCase,
  GetUserUseCase,
} from '@/modules/user/app';
import { GetAllUsersProps, User } from '@/modules/user/domain';

interface EditUserProps {
  name: Name;
  surname: Surname;
}

interface UserBlocProps {
  getUserUseCase: GetUserUseCase;
  getUsersUseCase: GetUsersUseCase;
  deleteUserUseCase: DeleteUserUseCase;
  createUserUseCase: CreateUserUseCase;
  editUserLeverageUseCase: EditUserLeverageUseCase;
  editUserMethodUseCase: EditUserMethodUseCase;
}

export class UserBloc extends Bloc<{}> {
  private getUserUseCase: GetUserUseCase;
  private getUsersUseCase: GetUsersUseCase;
  private deleteUserUseCase: DeleteUserUseCase;
  private editUserLeverageUseCase: EditUserLeverageUseCase;
  private editUserMethodUseCase: EditUserMethodUseCase;
  private createUserUseCase: CreateUserUseCase;

  constructor({
    getUserUseCase,
    getUsersUseCase,
    deleteUserUseCase,
    createUserUseCase,
    editUserLeverageUseCase,
    editUserMethodUseCase,
  }: UserBlocProps) {
    super({});

    this.getUserUseCase = getUserUseCase;
    this.getUsersUseCase = getUsersUseCase;
    this.deleteUserUseCase = deleteUserUseCase;
    this.createUserUseCase = createUserUseCase;
    this.editUserLeverageUseCase = editUserLeverageUseCase;
    this.editUserMethodUseCase = editUserMethodUseCase;
  }

  async getAll(props?: GetAllUsersProps): Promise<User[]> {
    const result = await this.getUsersUseCase.execute(props);

    return result.getOrElse([]);
  }

  async getUser(userId: UserId): Promise<User | undefined> {
    const result = await this.getUserUseCase.execute({ userId });

    if (result.isLeft()) return undefined;

    return result.getOrThrow();
  }

  // eslint-disable-next-line class-methods-use-this
  async editUser(
    userId: UserId,
    props: Partial<EditUserProps>,
  ): Promise<User | undefined> {
    // TODO: Unused ATM
    // eslint-disable-next-line no-console
    console.log({ userId, props });

    return undefined;
  }

  async deleteUser(userId: UserId): Promise<boolean> {
    const result = await this.deleteUserUseCase.execute({ userId });

    if (result.isLeft()) return false;

    return true;
  }

  async editLeverage({
    userId,
    leverage,
  }: {
    userId: UserId;
    leverage: number;
  }): Promise<boolean> {
    const result = await this.editUserLeverageUseCase.execute({
      userId,
      leverage,
    });

    if (result.isLeft()) return false;

    return true;
  }

  async changeMethod({
    userId,
    method,
  }: {
    userId: UserId;
    method: Method;
  }): Promise<boolean> {
    const result = await this.editUserMethodUseCase.execute({
      userId,
      method,
    });

    if (result.isLeft()) return false;

    return true;
  }

  async removeMethod({ userId }: { userId: UserId }): Promise<boolean> {
    const result = await this.editUserMethodUseCase.execute({
      userId,
    });

    if (result.isLeft()) return false;

    return true;
  }

  async createUser(props: {
    username: Email;
    password: Password;
    name?: Name;
    surname?: Surname;
  }): Promise<boolean> {
    const result = await this.createUserUseCase.execute(props);

    if (result.isLeft()) return false;

    return true;
  }
}
