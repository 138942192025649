"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MethodParameters = void 0;
const common_utils_1 = require("@robotrader/common-utils");
class MethodParameters {
    constructor(props) {
        this.id = props.id;
        this.method = props.method;
        this.enabled = props.enabled;
        this.parameters = props.parameters;
        this.exchange = props.exchange;
        this.lastRun = props.lastRun;
        this.createdAt = props.createdAt;
        this.updatedAt = props.updatedAt;
        this.schedule = props.schedule;
    }
    static create(props) {
        return new MethodParameters(props);
    }
    isWorkingHours() {
        const now = (0, common_utils_1.dayjs)();
        const dayOfWeek = now.day();
        const workingDay = this.schedule[dayOfWeek];
        if (!workingDay || !workingDay.enabled)
            return false;
        const { from, to } = workingDay;
        const fromDate = (0, common_utils_1.dayjs)(`${now.format('YYYY-MM-DD')} ${from}`);
        const toDate = (0, common_utils_1.dayjs)(`${now.format('YYYY-MM-DD')} ${to}`);
        if (!fromDate.isValid() || !toDate.isValid())
            return false;
        return now.isBetween(fromDate, toDate);
    }
    get lastRunTime() {
        return this.lastRun
            ? (0, common_utils_1.dayjs)(this.lastRun).format('DD-MM-YY HH:mm:ss Z[Z]')
            : undefined;
    }
}
exports.MethodParameters = MethodParameters;
