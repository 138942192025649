import { DataError, Either, UseCase } from '@robotrader/core-lib';

import { TradingSignalRepository } from '@/modules/trader/domain';

interface StopTraderUseCaseProps {
  tradingSignalRepository: TradingSignalRepository;
}

export class StopTraderUseCase
  implements UseCase<void, Either<DataError, void>>
{
  private tradingSignalRepository: TradingSignalRepository;

  constructor({ tradingSignalRepository }: StopTraderUseCaseProps) {
    this.tradingSignalRepository = tradingSignalRepository;
  }

  execute(): Promise<Either<DataError, void>> {
    return this.tradingSignalRepository.stopTrader();
  }
}
