import { AuthToken, Email, Password } from '@robotrader/common-types';
import { DataError, Either } from '@robotrader/core-lib';

import { AppConfig } from '@/core/config';
import {
  Auth,
  AuthRepository,
  ChangeUserPasswordProps,
} from '@/modules/auth/domain';
import { HTTPService } from '@/modules/shared';

import { GetMeResponse } from './GetMeResponse';
import { LoginResponse } from './LoginResponse';

interface AuthHTTPRepositoryProps {
  httpService: HTTPService;
  config: AppConfig;
}

export class AuthHTTPRepository implements AuthRepository {
  private http: HTTPService;
  private config: AppConfig;

  constructor({ httpService, config }: AuthHTTPRepositoryProps) {
    this.http = httpService;
    this.config = config;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getMe(token: AuthToken): Promise<Either<DataError, Auth>> {
    this.http.setToken(token);

    try {
      const {
        data: { data },
      } = await this.http.get<GetMeResponse>(`${this.config.apiUrl}/user/me`);
      const { id, email } = data;

      const auth = Auth.create({
        username: email,
        id,
        token,
      });

      return Either.right(auth);
    } catch (error) {
      return Either.left({ kind: 'Unauthorized', message: error as Error });
    }
  }

  async logIn(
    email: Email,
    password: Password,
  ): Promise<Either<DataError, Auth>> {
    try {
      const {
        data: { data },
      } = await this.http.post<LoginResponse>(
        `${this.config.apiUrl}/auth/admin/login`,
        { username: email, password },
      );
      const { accessToken, userId } = data;

      const auth = Auth.create({
        username: email,
        id: userId,
        token: accessToken,
      });

      this.http.setToken(accessToken);
      return Either.right(auth);
    } catch (error) {
      return Either.left({ kind: 'Unauthorized', message: error as Error });
    }
  }

  async logOut(): Promise<Either<DataError, void>> {
    this.http.setToken(null);

    return Either.right(undefined as void);
  }

  async changeUserPassword(
    props: ChangeUserPasswordProps,
  ): Promise<Either<DataError, void>> {
    const { userId, password } = props;
    try {
      await this.http.post<LoginResponse>(
        `${this.config.apiUrl}/auth/admin/change-password`,
        { userId, password },
      );
      return Either.right(undefined as void);
    } catch (error) {
      return Either.left({ kind: 'Unauthorized', message: error as Error });
    }
  }
}
