import { Method, UserId } from '@robotrader/common-types';
import { DataError, Either, UseCase } from '@robotrader/core-lib';

import { UserRepository } from '@/modules/user/domain';

interface EditUserMethodProps {
  userId: UserId;
  method?: Method;
}

interface EditUserMethodUseCaseProps {
  userRepository: UserRepository;
}

export class EditUserMethodUseCase
  implements UseCase<EditUserMethodProps, Either<DataError, void>>
{
  private userRepository: UserRepository;

  constructor({ userRepository }: EditUserMethodUseCaseProps) {
    this.userRepository = userRepository;
  }

  execute(props: EditUserMethodProps): Promise<Either<DataError, void>> {
    const { userId, method } = props;

    if (method) {
      return this.userRepository.editUserMethod(userId, method);
    }

    return this.userRepository.removeUserMethod(userId);
  }
}
