import styled, { css } from 'styled-components/macro';

import Colors from '@/Colors';

interface FontProps {
  readonly fontWeight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
  readonly fontSize?: number;
  readonly fontFamily?: string;
  readonly color?: string;
  readonly textAlign?: 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit';
  readonly wordBreak?:
    | 'normal'
    | 'break-all'
    | 'keep-all'
    | 'auto-phrase'
    | 'break-word'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'revert-layer'
    | 'unset';
}

const CommonCSS = css<FontProps>`
  word-break: ${(props) => (props.wordBreak ? props.wordBreak : 'break-word')};
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '16px')};
  font-weight: ${(props) => (props.fontWeight ? `${props.fontWeight}` : 400)};
  ${(props) =>
    props.fontFamily
      ? `font-family: ${props.fontFamily}`
      : `font-family: ''Work Sans'', 'Open Sans', serif, sans-serif;`}
  color: ${(props) => props.color || Colors.white};
  ${(props) => (props.textAlign ? `text-align: ${props.textAlign};` : null)}
`;

const H1 = styled.h1<FontProps>`
  ${CommonCSS}
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '32px')};
  font-weight: ${(props) => (props.fontWeight ? `${props.fontWeight}` : 800)};
`;

const H2 = styled.h2<FontProps>`
  ${CommonCSS}
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '24px')};
  font-weight: ${(props) => (props.fontWeight ? `${props.fontWeight}` : 700)};
`;

const H3 = styled.h3<FontProps>`
  ${CommonCSS}
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '20px')};
  font-weight: ${(props) => (props.fontWeight ? `${props.fontWeight}` : 600)};
`;

const H4 = styled.h4<FontProps>`
  ${CommonCSS}
`;

const H5 = styled.h5<FontProps>`
  ${CommonCSS}
`;

const Paragraph = styled.p<FontProps>`
  ${CommonCSS}

  padding: 1rem 0;
`;

const Title1 = styled(H2).attrs({
  fontWeight: 900,
  fontSize: 48,
})``;

const Title2 = styled(H2).attrs({
  fontWeight: 700,
  fontSize: 24,
})``;

const Caption = styled.caption<FontProps>`
  ${CommonCSS}
`;

const Strong = styled.strong<FontProps>`
  ${CommonCSS}
  font-weight: 700;
`;

const Small = styled.small<FontProps>`
  ${CommonCSS}
`;

const Span = styled.span<FontProps>`
  ${CommonCSS}
`;

const Label = styled.label<FontProps>`
  ${CommonCSS}

  color: ${(props) => props.color || Colors.grey3};
`;

const List = styled.ul<FontProps>`
  ${CommonCSS}

  padding-left: 2rem;
`;

const ListItem = styled.li<FontProps>`
  ${CommonCSS}
`;

const Typography = {
  H1,
  H2,
  H3,
  H4,
  H5,
  Paragraph,
  Caption,
  Title1,
  Title2,
  Strong,
  Small,
  Span,
  Label,
  List,
  ListItem,
};

export default Typography;
