import { createElement } from 'react';

import { Block, Text } from '@/atoms';

type BlockProps = React.ComponentProps<typeof Block>;

type LabelValueProps = BlockProps & {
  label: string;
  value: string;
  valueColor?: string;
};

const LabelValue = (props: LabelValueProps) => {
  const { label, value, valueColor } = props;

  return createElement(
    Block,
    { $inline: true, ...props },
    <>
      <Text.Label>{label}</Text.Label>
      <Text.Span fontWeight={700} fontSize={18} style={{ marginLeft: '0.5rem', color: valueColor }}>
        {value}
      </Text.Span>
    </>,
  );
};

export default LabelValue;
