import { ChangeEvent, useEffect, useState } from 'react';

import {
  Action,
  Exchange,
  ExchangeName,
  isAction,
  isMethod,
  Method,
} from '@robotrader/common-types';
import { capitalizeString, dayjs, stringToNumber } from '@robotrader/common-utils';
import {
  Block,
  ButtonLoading,
  Card,
  Input,
  LoadingBlock,
  Select,
  Text,
  TradingSignalsSummary,
} from '@robotrader/design-system';
import { useSearchParams } from 'react-router-dom';

import { useCradle } from '@/app/contexts';
import { useIsMounted } from '@/app/hooks';
import { ModalTradingSignalOperations } from '@/app/ui/organisms';
import { Cradle } from '@/di/Cradle';
import { TradingSignal } from '@/modules/trader';

const PARAM_EXCHANGE_NAME = 'exchangeName';
const PARAM_METHOD = 'method';
const PARAM_ACTION = 'action';
const PARAM_TIME_FROM = 'timeFrom';
const PARAM_TIME_TO = 'timeTo';

const TradingSignalsPage = () => {
  const { traderBloc } = useCradle<Cradle>();
  const isMounted = useIsMounted();
  const [searchParams, setSearchParams] = useSearchParams();
  const [tradingSignals, setTradingSignals] = useState<TradingSignal[] | undefined>(undefined);
  const [selectedTradingSignal, setSelectedTradingSignal] = useState<TradingSignal | undefined>(
    undefined,
  );
  const [selectedMethod, setSelectedMethod] = useState<Method | undefined>(undefined);
  const [selectedAction, setSelectedAction] = useState<Action | undefined>(undefined);
  const [exchangeName, setExchangeName] = useState<ExchangeName | undefined>(undefined);
  const [exchanges, setExchanges] = useState<Exchange[] | undefined>(undefined);
  const [timeFrom, setTimeFrom] = useState<number | undefined>(undefined);
  const [timeTo, setTimeTo] = useState<number | undefined>(undefined);
  const [includeCheckpoints, setCheckpoint] = useState<boolean | undefined>(false);
  const [isLoadingTradingSignals, setIsLoadingTradingSignals] = useState<boolean | undefined>(
    false,
  );

  const loadTradingSignals = () => {
    if (isLoadingTradingSignals) return;

    setIsLoadingTradingSignals(true);

    traderBloc
      .getTradingSignals({
        exchange: exchangeName,
        includeCheckpoints,
        timeFrom,
        timeTo,
        method: selectedMethod,
        action: selectedAction,
      })
      .then(setTradingSignals)
      .finally(() => {
        setIsLoadingTradingSignals(false);
      });
  };

  useEffect(() => {
    if (!isMounted()) return;

    if (exchanges === undefined) {
      traderBloc.getExchanges().then(setExchanges);
    }
  }, [isMounted]);

  useEffect(() => {
    if (!isMounted()) return;

    const eName = searchParams.get(PARAM_EXCHANGE_NAME);
    const method = searchParams.get(PARAM_METHOD);
    const action = searchParams.get(PARAM_ACTION);
    const dateFrom = searchParams.get(PARAM_TIME_FROM);
    const dateTo = searchParams.get(PARAM_TIME_TO);

    if (eName) {
      setExchangeName(eName);
    } else {
      setExchangeName(undefined);
    }

    if (isMethod(method)) {
      setSelectedMethod(method);
    } else {
      setSelectedMethod(undefined);
    }

    if (isAction(action)) {
      setSelectedAction(action);
    } else {
      setSelectedAction(undefined);
    }

    if (dateFrom && !Number.isNaN(dateFrom)) {
      setTimeFrom(stringToNumber(dateFrom));
    } else {
      setTimeFrom(undefined);
    }

    if (dateTo && !Number.isNaN(dateTo)) {
      setTimeTo(stringToNumber(dateTo));
    } else {
      setTimeTo(undefined);
    }
  }, [searchParams, isMounted]);

  useEffect(() => {
    if (!isMounted()) return;

    loadTradingSignals();
  }, [
    exchangeName,
    timeFrom,
    timeTo,
    selectedMethod,
    includeCheckpoints,
    selectedAction,
    isMounted,
  ]);

  if (tradingSignals === undefined) {
    return <LoadingBlock loading text="Loading trading signals..." />;
  }

  return (
    <>
      <Card.Container>
        <Card.Body style={{ display: 'flex', alignItems: 'end', overflow: 'auto' }}>
          <Block $inline>
            <Select
              isClearable
              hideSelectedOptions
              placeholder="Action"
              value={Object.entries(Action)
                ?.filter((e) => e[1] === selectedAction)
                .map((e) => ({
                  value: e[1],
                  label: e[0].replace('_', ' ').toUpperCase(),
                }))}
              options={Object.entries(Action).map((e) => ({
                value: e[1],
                label: e[0].replace('_', ' ').toUpperCase(),
              }))}
              onChange={(newValue) => {
                const { value } = (newValue as any) || {};

                if (isAction(value)) {
                  searchParams.set(PARAM_ACTION, value);
                } else {
                  searchParams.delete(PARAM_ACTION);
                }

                setSearchParams(searchParams);
              }}
            />
          </Block>
          <Block $inline style={{ marginLeft: '1rem' }}>
            <Select
              isClearable
              hideSelectedOptions
              placeholder="Method"
              value={Object.entries(Method)
                ?.filter((e) => e[1] === selectedMethod)
                .map((e) => ({
                  value: e[1],
                  label: capitalizeString(e[0]),
                }))}
              options={Object.entries(Method).map((e) => ({
                value: e[1],
                label: capitalizeString(e[0]),
              }))}
              onChange={(newValue) => {
                const { value } = (newValue as any) || {};

                if (isMethod(value)) {
                  searchParams.set(PARAM_METHOD, value);
                } else {
                  searchParams.delete(PARAM_METHOD);
                }

                setSearchParams(searchParams);
              }}
            />
          </Block>
          <Block $inline style={{ marginLeft: '1rem' }}>
            <Select
              isClearable
              hideSelectedOptions
              placeholder="Exchange"
              value={exchanges
                ?.filter((e) => e.name === exchangeName)
                .map((e) => ({ value: e.name, label: capitalizeString(e.name) }))}
              options={
                exchanges
                  ? exchanges.map((e) => ({ value: e.name, label: capitalizeString(e.name) }))
                  : []
              }
              onChange={(newValue) => {
                const { value: selectedExchangeName } = newValue || {};

                if (!selectedExchangeName) {
                  searchParams.delete(PARAM_EXCHANGE_NAME);
                } else {
                  searchParams.set(PARAM_EXCHANGE_NAME, selectedExchangeName);
                }

                setSearchParams(searchParams);
              }}
            />
          </Block>
          <Block $inline style={{ marginLeft: '1rem' }}>
            <Text.Strong style={{ marginRight: '1rem' }}>From:</Text.Strong>
            <Input.DateTime
              defaultValue={timeFrom ? dayjs(timeFrom).format('YYYY-MM-DDTHH:mm') : undefined}
              style={{ width: 'auto' }}
              onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                const { value } = evt.target;

                const date = dayjs(value);
                let timestamp: number | undefined;

                if (date.isValid()) {
                  timestamp = date.valueOf();
                } else {
                  timestamp = undefined;
                }

                if (!timestamp) {
                  searchParams.delete(PARAM_TIME_FROM);
                } else {
                  searchParams.set(PARAM_TIME_FROM, `${timestamp}`);
                }

                setSearchParams(searchParams);
              }}
            />
          </Block>
          <Block $inline style={{ marginLeft: '1rem' }}>
            <Text.Strong style={{ marginRight: '1rem' }}>To:</Text.Strong>
            <Input.DateTime
              defaultValue={timeTo ? dayjs.utc(timeTo).format('YYYY-MM-DDTHH:mm') : undefined}
              style={{ width: 'auto' }}
              onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                const { value } = evt.target;

                const date = dayjs(value);
                let timestamp: number | undefined;

                if (date.isValid()) {
                  timestamp = date.valueOf();
                } else {
                  timestamp = undefined;
                }

                if (!timestamp) {
                  searchParams.delete(PARAM_TIME_TO);
                } else {
                  searchParams.set(PARAM_TIME_TO, `${timestamp}`);
                }

                setSearchParams(searchParams);
              }}
            />
          </Block>
          <Block $inline style={{ marginLeft: '1rem', alignSelf: 'center' }}>
            <Text.Label>
              <Text.Strong style={{ marginRight: '1rem' }} wordBreak="normal">
                Checkpoints?
              </Text.Strong>
              <Input.Checkbox
                checked={includeCheckpoints}
                onChange={() => {
                  setCheckpoint(!includeCheckpoints);
                }}
              />
            </Text.Label>
          </Block>
          <Block $inline style={{ marginLeft: 'auto' }}>
            <ButtonLoading
              $size="md"
              onClick={loadTradingSignals}
              loading={isLoadingTradingSignals}
              disabled={isLoadingTradingSignals}
            >
              <Text.Span fontWeight={700} wordBreak="normal">
                Load
              </Text.Span>
            </ButtonLoading>
          </Block>
        </Card.Body>
      </Card.Container>
      <Block style={{ marginTop: '1rem' }}>
        <TradingSignalsSummary
          tradingSignals={tradingSignals}
          showMethod={selectedMethod === undefined}
          showGraph={selectedMethod !== undefined}
          showExchange={exchangeName === undefined}
          onTradingSignalClick={setSelectedTradingSignal}
        />
      </Block>
      {selectedTradingSignal && (
        <ModalTradingSignalOperations
          isOpen={selectedTradingSignal !== undefined}
          onClose={() => {
            setSelectedTradingSignal(undefined);
          }}
          tradingSignal={selectedTradingSignal}
        />
      )}
    </>
  );
};

export default TradingSignalsPage;
