import { Card, Flex, Text } from '@/atoms';

interface WidgetCounterProps {
  label: string;
  count: number;
  icon: JSX.Element;
}

const WidgetCounter = (props: WidgetCounterProps) => {
  const { label, count, icon } = props;

  return (
    <Card.Container>
      <Card.Body>
        <Flex.Container>
          <Flex.Item>
            <Text.Paragraph>{label}</Text.Paragraph>
            <Text.H3>{count}</Text.H3>
          </Flex.Item>
          <Flex.Item style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {icon}
          </Flex.Item>
        </Flex.Container>
      </Card.Body>
    </Card.Container>
  );
};

export default WidgetCounter;
