import { useEffect, useState } from 'react';

import { CandleI, Pair, PairId } from '@robotrader/common-types';
import { dayjs } from '@robotrader/common-utils';
import {
  Block,
  ButtonLoading,
  Card,
  Colors,
  Flex,
  LabelValue,
  LightweightChart,
  LoadingBlock,
  Text,
  TradingSignalsSummary,
  UpdateTimeInfo,
} from '@robotrader/design-system';

import { useCradle } from '@/app/contexts';
import { useInterval, useIsMounted } from '@/app/hooks';
import { Cradle } from '@/di/Cradle';
import { TradingSignal } from '@/modules/trader';

const DELAY_TIME = 2000; // milliseconds

interface PairViewProps {
  pairId: PairId;
  showCandles?: boolean;
}

const PairView = (props: PairViewProps) => {
  const { pairId, showCandles } = props;
  const { traderBloc } = useCradle<Cradle>();
  const isMounted = useIsMounted();
  const [pair, setPair] = useState<Pair | undefined>();
  const [tradingSignals, setTradingSignals] = useState<TradingSignal[] | undefined>(undefined);
  const [isLoadingTradingSignals, setIsLoadingTradingSignals] = useState<boolean | undefined>(
    false,
  );
  const [pairCandles, setPairCandles] = useState<CandleI[] | undefined>();
  const [loadingCandles, setLoadingCandles] = useState<boolean>(false);

  const loadTradingSignals = (p: Pair) => {
    if (isLoadingTradingSignals) return;

    setIsLoadingTradingSignals(true);

    traderBloc
      .getTradingSignals({
        timeFrom: dayjs().subtract(15, 'days').valueOf(),
        exchange: p.exchange.name,
        pair: p.name,
      })
      .then(setTradingSignals)
      .finally(() => {
        setIsLoadingTradingSignals(false);
      });
  };

  const loadCandles = async () => {
    if (loadingCandles) return;

    setLoadingCandles(true);

    try {
      const candles = await traderBloc.getPairCandles(Number(pairId));
      setPairCandles(candles);
    } catch (error) {
      //
    }

    setLoadingCandles(false);
  };

  useEffect(() => {
    if (pairId && !Number.isNaN(pairId)) {
      traderBloc.getPair(Number(pairId)).then((p: Pair) => {
        setPair(p);
        loadTradingSignals(p);
      });

      if (showCandles) loadCandles();
    }
  }, [pairId]);

  useInterval(
    () => {
      const now = dayjs();
      const minutes = now.minute();
      const seconds = now.second();

      // each 5 minutes load new candles
      if (minutes % 5 === 0 && seconds === 10) {
        // wait 10 secs for candles to update
        loadCandles();
      }
    },
    // Delay in milliseconds or null to stop it
    isMounted() ? DELAY_TIME : null,
  );

  if (pair === undefined) {
    return <LoadingBlock loading text="Loading pair..." />;
  }

  return (
    <>
      <Text.H3 style={{ marginBottom: '1rem' }}>{pair.name}</Text.H3>
      <UpdateTimeInfo milliseconds={DELAY_TIME} textColor={Colors.grey5} loading={loadingCandles} />
      <Card.Container style={{ marginBottom: '1rem', marginTop: '1rem' }}>
        <Card.Body>
          <Flex.Container gap="1rem">
            <Flex.Item style={{ flex: 2 }}>
              <LabelValue
                label="Created At"
                value={dayjs(pair.createdAt).format('DD-MM-YYYY HH:mm:ss Z[Z]')}
              />
            </Flex.Item>
            <Flex.Item style={{ flex: 2 }}>
              <LabelValue label="Last Updated" value={pair.lastCandlesUpdateTime} />
            </Flex.Item>
            <Flex.Item style={{ flex: 1 }}>
              <LabelValue
                label="Status"
                value={pair.enabled ? 'Enabled' : 'Disabled'}
                valueColor={pair.enabled ? Colors.green : Colors.red}
              />
            </Flex.Item>
            {showCandles && (
              <Flex.Item style={{ flex: 1 }}>
                <ButtonLoading $size="sm" onClick={loadCandles} loading={loadingCandles}>
                  Load Candles
                </ButtonLoading>
              </Flex.Item>
            )}
          </Flex.Container>
        </Card.Body>
      </Card.Container>
      {showCandles && pairCandles && (
        <LightweightChart
          pair={pair}
          style={{
            // flex: 1,
            // marginTop: '2rem',
            height: 'max(300px, 35vh)',
            minHeight: '300px',
            maxHeight: '35vh',
          }}
          candles={pairCandles.map((c) => ({
            ...c,
            // time: dayjs(c.openTime).add(5, 'minutes').valueOf(),
            time: dayjs(c.openTime).valueOf(),
          }))}
          tradingSignals={tradingSignals}
        />
      )}
      {tradingSignals !== undefined && tradingSignals.length && (
        <Block style={{ marginTop: '1rem' }}>
          <TradingSignalsSummary
            tradingSignals={tradingSignals}
            showGraph={false}
            searcheable={false}
            pagination={false}
            // showMethod={selectedMethod === undefined}
            // showExchange={exchangeName === undefined}
          />
        </Block>
      )}
    </>
  );
};

export default PairView;
