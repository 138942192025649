import { useState } from 'react';

import { Block, Card, Text } from '@robotrader/design-system';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useCradle } from '@/app/contexts';
import { NewUserForm } from '@/app/ui';
import { Cradle } from '@/di/Cradle';

const NewUserPage = () => {
  const { userBloc } = useCradle<Cradle>();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const createUser = async (props: {
    username: string;
    password: string;
    name?: string;
    surname?: string;
  }) => {
    // eslint-disable-next-line react/prop-types
    const { username } = props;
    setLoading(true);

    try {
      const created = await userBloc.createUser(props);

      if (!created) {
        toast.error('Some error ocurred while creating the user');
      } else {
        navigate('/users', { replace: true });
        toast.success(`User with email ${username} created successfully!`);
      }
    } catch (error) {
      toast.error('Some error ocurred while creating the user');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Text.H3 style={{ marginBottom: '1rem' }}>New User</Text.H3>
      <Card.Container style={{ marginBottom: '1rem' }}>
        <Card.Body>
          <Block style={{ width: '90vw', maxWidth: '600px' }}>
            <NewUserForm submit={createUser} loading={loading} />
          </Block>
        </Card.Body>
      </Card.Container>
    </>
  );
};

export default NewUserPage;
