import { ExchangeOrderI } from '@robotrader/common-types';
import { DataError, Either, UseCase } from '@robotrader/core-lib';

import {
  CloseExchangeOrderProps,
  OperationRepository,
} from '@/modules/trader/domain';

interface CloseExchangeOrderUseCaseProps {
  operationRepository: OperationRepository;
}

export class CloseExchangeOrderUseCase
  implements
    UseCase<CloseExchangeOrderProps, Either<DataError, ExchangeOrderI>>
{
  private operationRepository: OperationRepository;

  constructor({ operationRepository }: CloseExchangeOrderUseCaseProps) {
    this.operationRepository = operationRepository;
  }

  async execute(
    props: CloseExchangeOrderProps,
  ): Promise<Either<DataError, ExchangeOrderI>> {
    return this.operationRepository.closeExchangeOrder(props);
  }
}
