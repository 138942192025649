import { Pair, PairId } from '@robotrader/common-types';
import { DataError, Either, UseCase } from '@robotrader/core-lib';

import { EditPairParameters, PairRepository } from '@/modules/trader/domain';

export interface EditPairProps {
  pairId: PairId;
  parameters: EditPairParameters;
}

interface EditPairUseCaseProps {
  pairRepository: PairRepository;
}

export class EditPairUseCase
  implements UseCase<EditPairProps, Either<DataError, Pair>>
{
  private pairRepository: PairRepository;

  constructor({ pairRepository }: EditPairUseCaseProps) {
    this.pairRepository = pairRepository;
  }

  execute(props: EditPairProps): Promise<Either<DataError, Pair>> {
    const { pairId, parameters } = props;

    return this.pairRepository.editPair(pairId, parameters);
  }
}
