import { useEffect, useState } from 'react';

import { ExchangeOrderI, UserId } from '@robotrader/common-types';
import { Button, ButtonLoading, Flex, Modal } from '@robotrader/design-system';
import { toast } from 'react-toastify';

import { useCradle } from '@/app/contexts';
import { Cradle } from '@/di/Cradle';
import { Operation } from '@/modules/trader';

import ImportOrder from './ImportOrder';

interface ModalImportOrderProps {
  isOpen: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  onError?: () => void;
  operations: Operation[];
  exchangeOrder: ExchangeOrderI;
  userId: UserId;
}

const ModalImportOrder = (props: ModalImportOrderProps) => {
  const { isOpen, onClose, onSuccess, onError, operations, exchangeOrder, userId } = props;
  const { traderBloc } = useCradle<Cradle>();
  const [loadingImport, setLoadingImport] = useState<boolean>(false);
  const [openingOperation, setOpeningOperation] = useState<Operation | undefined>(undefined);

  useEffect(() => {
    // this is necessary! openingOperation operation must be cleared out every new opening
    setOpeningOperation(undefined);
  }, [isOpen]);

  const importExchangeOrder = async (eo: ExchangeOrderI, uId: UserId) => {
    const { closeOrder: isCloseOrder } = eo;

    if (isCloseOrder && openingOperation === undefined) {
      toast.error('Close order must have an Opening operation selected');
      return;
    }

    setLoadingImport(true);

    try {
      await traderBloc.importExchangeOrder({
        userId: uId,
        exchangeName: eo.exchange,
        orderId: eo.id,
        openingOperation,
      });
      toast.success(`Order imported successfully!`);
      setOpeningOperation(undefined);
      if (onSuccess) onSuccess();
    } catch (error) {
      toast.error('An error ocurred while importing order');
      if (onError) onError();
    }

    setLoadingImport(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" position="center" title="Import Order">
      <ImportOrder
        exchangeOrder={exchangeOrder}
        operations={operations}
        setOpeningOperation={setOpeningOperation}
      />
      <Flex.Container flexDirection="row" gap="2rem" style={{ marginTop: '2rem' }}>
        <Flex.Item flex={2} />
        <Flex.Item>
          <Button $size="md" $variant="cancel" style={{ width: '100%' }} onClick={onClose}>
            Cancel
          </Button>
        </Flex.Item>
        <Flex.Item>
          <ButtonLoading
            $size="md"
            style={{ width: '100%' }}
            loading={loadingImport}
            onClick={() => {
              if (exchangeOrder !== undefined) {
                importExchangeOrder(exchangeOrder, userId);
              }
            }}
          >
            Submit
          </ButtonLoading>
        </Flex.Item>
      </Flex.Container>
    </Modal>
  );
};

export default ModalImportOrder;
