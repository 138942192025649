import { createElement, SVGProps } from 'react';

import { faCircleStop } from '@fortawesome/free-regular-svg-icons';
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
  faAngleUp,
  faArrowRightFromBracket,
  faArrowTrendUp,
  faArrowUpRightFromSquare,
  faBars,
  faCode,
  faCoins,
  faDollarSign,
  faDownload,
  faEuroSign,
  faEye,
  faFileExcel,
  faFilePdf,
  faMoneyBillTrendUp,
  faPrint,
  faRepeat,
  faRobot,
  faSpinner,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

import { Block } from '@/atoms';
import Colors from '@/Colors';
import BinanceIcon from '@/icons/BinanceIcon';
import BoxIcon from '@/icons/BoxIcon';
import KucoinIcon from '@/icons/KucoinIcon';
import LogoIcon from '@/icons/Logo';
import PauseIcon from '@/icons/PauseIcon';
import PlayIcon from '@/icons/PlayIcon';
import PlusIcon from '@/icons/PlusIcon';
import TrashIcon from '@/icons/TrashIcon';
import UserIcon from '@/icons/UserIcon';

type FontAwesomeIconPropsNoIcon = Omit<FontAwesomeIconProps, 'icon'>;

/* ##### FONT AWESEOME ICONS ################################################ */

const FAIcon = (props: FontAwesomeIconProps) => {
  const { color, className, onClick } = props;

  return createElement(FontAwesomeIcon, {
    ...props,
    color: color || Colors.primary,
    className: `${className !== undefined ? className : ''}${
      onClick !== undefined ? ' clickable' : ''
    }`,
  });
};

const ArrowRight = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    icon: faAngleRight,
  });

const ArrowsRight = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    icon: faAnglesRight,
  });

const ArrowLeft = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    icon: faAngleLeft,
  });

const ArrowsLeft = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    icon: faAnglesLeft,
  });

const ArrowDown = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    icon: faAngleDown,
  });

const ArrowUp = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    icon: faAngleUp,
  });

const ExternalLink = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    icon: faArrowUpRightFromSquare,
  });

const UserCircle = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    icon: faUserCircle,
  });

const LogOut = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    icon: faArrowRightFromBracket,
  });

const Robot = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    color: Colors.white,
    icon: faRobot,
  });

const ArrowTrendUp = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    color: Colors.white,
    icon: faArrowTrendUp,
  });

const Coins = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    color: Colors.white,
    icon: faCoins,
  });

const Euro = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    color: Colors.white,
    icon: faEuroSign,
  });

const Dollar = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    color: Colors.white,
    icon: faDollarSign,
  });

const Exchanges = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    color: Colors.white,
    icon: faMoneyBillTrendUp,
  });

const Code = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    color: Colors.white,
    icon: faCode,
  });

const Repeat = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    color: Colors.white,
    icon: faRepeat,
  });

const Stop = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    color: Colors.white,
    icon: faCircleStop,
  });

const Bars = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    color: Colors.white,
    icon: faBars,
  });

const Spinner = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    icon: faSpinner,
    // eslint-disable-next-line react/destructuring-assignment
    className: `${props.className} fa-spin`,
  });

const Download = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    color: Colors.white,
    icon: faDownload,
  });

const FileExcel = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    color: Colors.white,
    icon: faFileExcel,
  });

const FilePdf = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    color: Colors.white,
    icon: faFilePdf,
  });

const Print = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    color: Colors.white,
    icon: faPrint,
  });

const Eye = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    color: Colors.white,
    icon: faEye,
  });

/* ##### CUSTOM ICONS ####################################################### */

type CustomIconPorps = SVGProps<SVGSVGElement> & {
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
};

const CustomIcon = (props: CustomIconPorps) => {
  const { width, height, className, onClick, icon } = props;

  return (
    <Block
      $inline
      className={`${className !== undefined ? className : ''}${
        onClick !== undefined ? ' clickable' : ''
      }`}
      style={{ width, height }}
    >
      {createElement(icon, { ...props, height: undefined, width: undefined, icon: undefined })}
    </Block>
  );
};

const Logo = (props: SVGProps<SVGSVGElement>) =>
  createElement(CustomIcon, { ...props, icon: LogoIcon });

const User = (props: SVGProps<SVGSVGElement>) =>
  createElement(CustomIcon, { ...props, icon: UserIcon });

const Play = (props: SVGProps<SVGSVGElement>) =>
  createElement(CustomIcon, { ...props, icon: PlayIcon });

const Pause = (props: SVGProps<SVGSVGElement>) =>
  createElement(CustomIcon, { ...props, icon: PauseIcon });

const Plus = (props: SVGProps<SVGSVGElement>) =>
  createElement(CustomIcon, { ...props, icon: PlusIcon });

const Box = (props: SVGProps<SVGSVGElement>) =>
  createElement(CustomIcon, { ...props, icon: BoxIcon });

const Trash = (props: SVGProps<SVGSVGElement>) =>
  createElement(CustomIcon, { ...props, icon: TrashIcon });

const Binance = (props: SVGProps<SVGSVGElement>) =>
  createElement(CustomIcon, { ...props, icon: BinanceIcon });

const Kucoin = (props: SVGProps<SVGSVGElement>) =>
  createElement(CustomIcon, { ...props, icon: KucoinIcon });

const Icons = {
  ArrowRight,
  ArrowsRight,
  ArrowLeft,
  ArrowsLeft,
  ArrowDown,
  ArrowUp,
  ArrowTrendUp,
  ExternalLink,
  Bars,
  UserCircle,
  LogOut,
  Stop,
  Spinner,
  Coins,
  Euro,
  Dollar,
  Exchanges,
  Code,
  Repeat,
  Logo,
  User,
  Play,
  Pause,
  Plus,
  Box,
  Binance,
  Kucoin,
  Trash,
  Robot,
  Download,
  FileExcel,
  FilePdf,
  Print,
  Eye,
};

export default Icons;
