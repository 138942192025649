import { Pair, PairId } from '@robotrader/common-types';
import { DataError, Either, UseCase } from '@robotrader/core-lib';

import { PairRepository } from '@/modules/trader/domain';

interface GetPairUseCaseProps {
  pairRepository: PairRepository;
}

export class GetPairUseCase
  implements UseCase<PairId, Either<DataError, Pair>>
{
  private pairRepository: PairRepository;

  constructor({ pairRepository }: GetPairUseCaseProps) {
    this.pairRepository = pairRepository;
  }

  execute(pairId: PairId): Promise<Either<DataError, Pair>> {
    return this.pairRepository.getPair(pairId);
  }
}
