import { JobI, QueueStatusI } from '@robotrader/common-types';
import { DataError, Either } from '@robotrader/core-lib';

import { AppConfig } from '@/core/config';
import { JobRepository } from '@/modules/job/domain';
import { HTTPService } from '@/modules/shared';

import { GetQueueJobsResponse } from './GetQueueJobsResponse';
import { GetQueuesResponse } from './GetQueuesResponse';
import { GetQueueStatusResponse } from './GetQueueStatusResponse';

interface JobHTTPRepositoryProps {
  httpService: HTTPService;
  config: AppConfig;
}

export class JobHTTPRepository implements JobRepository {
  private http: HTTPService;
  private config: AppConfig;

  constructor({ httpService, config }: JobHTTPRepositoryProps) {
    this.http = httpService;
    this.config = config;
  }

  async getAllQueues(): Promise<Either<DataError, string[]>> {
    try {
      const {
        data: { data },
      } = await this.http.get<GetQueuesResponse>(
        `${this.config.apiUrl}/admin/jobs/queues`,
      );

      return Either.right(data);
    } catch (error) {
      return Either.left({ kind: 'Unauthorized', message: error as Error });
    }
  }

  async getQueueStatus(
    queueName: string,
  ): Promise<Either<DataError, QueueStatusI>> {
    try {
      const {
        data: { data },
      } = await this.http.get<GetQueueStatusResponse>(
        `${this.config.apiUrl}/admin/jobs/queue/${queueName}/status`,
      );

      return Either.right(data);
    } catch (error) {
      return Either.left({ kind: 'Unauthorized', message: error as Error });
    }
  }

  async getQueueLastJobs<T>(
    queueName: string,
  ): Promise<Either<DataError, Array<JobI<T>>>> {
    try {
      const {
        data: { data },
      } = await this.http.get<GetQueueJobsResponse<T>>(
        `${this.config.apiUrl}/admin/jobs/queue/${queueName}/jobs`,
      );

      return Either.right(data.sort((j1, j2) => j2.timestamp - j1.timestamp));
    } catch (error) {
      return Either.left({ kind: 'Unauthorized', message: error as Error });
    }
  }
}
