import { createRef, useEffect, useState } from 'react';

import { Direction, Exchange, Pair, Symbol } from '@robotrader/common-types';
import { capitalizeString, dayjs } from '@robotrader/common-utils';
import {
  Block,
  Button,
  ButtonLoading,
  Flex,
  Icon,
  Input,
  Select,
  Text,
} from '@robotrader/design-system';
import { toast } from 'react-toastify';

import { useCradle } from '@/app/contexts';
import { useConfig, useIsMounted } from '@/app/hooks';
import { Cradle } from '@/di/Cradle';
import { TrailingStopV1UseCaseProps } from '@/modules/trader';

interface TrailingStopSimulationFormProps {
  submit: (props: TrailingStopV1UseCaseProps) => void;
  loading?: boolean;
  exchange: Exchange;
}

const TrailingStopSimulationForm = (props: TrailingStopSimulationFormProps) => {
  const { submit, loading, exchange } = props;
  const { traderBloc } = useCradle<Cradle>();
  const config = useConfig();
  const isMounted = useIsMounted();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [pairs, setPairs] = useState<Pair[] | undefined>(undefined);
  const [detectDayChange, setDetectDayChange] = useState<boolean>(false);
  const [symbol, setSymbol] = useState<Symbol | undefined>();
  const [initialDirection, setInitialDirection] = useState<Direction>(Direction.LONG);
  const dateFromRef = createRef<HTMLInputElement>();
  const dateToRef = createRef<HTMLInputElement>();
  const trailingDeltaRef = createRef<HTMLInputElement>();
  const goalMarginRef = createRef<HTMLInputElement>();
  const maxIterationsRef = createRef<HTMLInputElement>();

  useEffect(() => {
    if (!isMounted()) return;

    traderBloc.getPairs(exchange.id).then(setPairs);
    setSymbol(undefined);
  }, [isMounted, exchange]);

  const onSubmit = () => {
    const dateFrom = dateFromRef.current ? dateFromRef.current.value : undefined;
    const dateTo = dateToRef.current ? dateToRef.current.value : undefined;
    const trailingDelta =
      trailingDeltaRef.current !== null
        ? Number.parseFloat(trailingDeltaRef.current.value)
        : undefined;
    const goalMargin =
      goalMarginRef.current !== null ? Number.parseFloat(goalMarginRef.current.value) : undefined;
    const maxIterations =
      maxIterationsRef.current !== null
        ? Number.parseFloat(maxIterationsRef.current.value)
        : undefined;

    if (
      symbol !== undefined &&
      initialDirection !== undefined &&
      dateFrom !== undefined &&
      dateTo !== undefined &&
      trailingDelta !== undefined &&
      goalMargin !== undefined &&
      maxIterations !== undefined
    ) {
      submit({
        symbol,
        initialDirection,
        exchange: exchange.name,
        dateFrom,
        dateTo,
        trailingDelta,
        maxIterations,
        goalMargin,
        detectDayChange,
      });
    } else {
      toast.error('You must provide all the fields');
    }
  };

  return (
    <>
      <Flex.Container style={{ marginBottom: isCollapsed ? '0' : '2rem' }}>
        <Text.H3>Simulation Form</Text.H3>
        <Button
          $transparent
          style={{ marginLeft: 'auto' }}
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <Text.Span fontWeight={700}>
            {isCollapsed ? <Icon.ArrowDown /> : <Icon.ArrowUp />}
          </Text.Span>
        </Button>
      </Flex.Container>
      <Block style={{ display: isCollapsed ? 'none' : 'initial' }}>
        <Flex.Container
          style={{
            display: 'grid',
            gap: '1rem',
            gridAutoFlow: 'dense',
            gridAutoRows: '1fr',
            gridTemplateColumns: 'repeat(auto-fill, minmax(220px, 1fr))',
          }}
        >
          <Flex.Item>
            <Text.Span style={{ display: 'block', marginBottom: '1rem' }}>Pair</Text.Span>
            <Select
              isClearable
              hideSelectedOptions
              placeholder="Pair"
              value={pairs
                ?.filter((p) => p.name === symbol)
                .map((p) => ({ value: p.name, label: p.name }))}
              options={pairs ? pairs.map((p) => ({ value: p.name, label: p.name })) : []}
              onChange={(newValue) => {
                const { value } = (newValue as any) || {};

                setSymbol(value);
              }}
            />
          </Flex.Item>
          <Flex.Item>
            <Text.Span style={{ display: 'block', marginBottom: '1rem' }}>
              Initial direction
            </Text.Span>
            <Select
              isClearable
              hideSelectedOptions
              placeholder="Initial Direction"
              value={Object.entries(Direction)
                ?.filter((e) => e[1] === initialDirection)
                .map((e) => ({
                  value: e[1],
                  label: capitalizeString(e[0]),
                }))}
              options={Object.entries(Direction).map((e) => ({
                value: e[1],
                label: capitalizeString(e[0]),
              }))}
              onChange={(newValue) => {
                const { value } = (newValue as any) || {};

                setInitialDirection(value);
              }}
            />
          </Flex.Item>
          <Flex.Item>
            <Text.Span style={{ display: 'block', marginBottom: '1rem' }}>Date From</Text.Span>
            <Input.Date
              style={{ flex: 1 }}
              ref={dateFromRef}
              placeholder="YYYY/MM/DD"
              defaultValue={dayjs()
                .subtract(config.development ? 1 : 12, 'months')
                .format('YYYY-MM-DD')}
            />
          </Flex.Item>
          <Flex.Item>
            <Text.Span style={{ display: 'block', marginBottom: '1rem' }}>Date To</Text.Span>
            <Input.Date
              style={{ flex: 1 }}
              ref={dateToRef}
              placeholder="YYYY/MM/DD"
              defaultValue={dayjs().subtract(1, 'day').format('YYYY-MM-DD')}
            />
          </Flex.Item>
          <Flex.Item>
            <Text.Span style={{ display: 'block', marginBottom: '1rem' }}>Trailing Delta</Text.Span>
            <Input.Number
              style={{ flex: 1 }}
              min={0.001}
              max={1}
              step={0.001}
              ref={trailingDeltaRef}
              defaultValue={0.03}
            />
          </Flex.Item>
          <Flex.Item>
            <Text.Span style={{ display: 'block', marginBottom: '1rem' }}>Goal Margin</Text.Span>
            <Input.Number
              style={{ flex: 1 }}
              min={0}
              max={1}
              step={0.001}
              ref={goalMarginRef}
              defaultValue={0.1}
            />
          </Flex.Item>
          <Flex.Item>
            <Text.Span style={{ display: 'block', marginBottom: '1rem' }}>
              Max Iterations (unused)
            </Text.Span>
            <Input.Number
              style={{ flex: 1 }}
              min={1}
              step={1}
              ref={maxIterationsRef}
              defaultValue={10}
            />
          </Flex.Item>
          <Flex.Item style={{ alignSelf: 'center' }}>
            <Text.Label>
              <Text.Strong style={{ marginRight: '1rem' }}>Close on Day Change</Text.Strong>
              <Input.Checkbox
                style={{ flex: 1 }}
                checked={detectDayChange}
                onChange={() => {
                  setDetectDayChange(!detectDayChange);
                }}
              />
            </Text.Label>
          </Flex.Item>
        </Flex.Container>
        <Flex.Container>
          <Flex.Item
            style={{
              display: 'flex',
              marginTop: '1rem',
              alignItems: 'end',
              justifyContent: 'end',
            }}
          >
            <ButtonLoading $size="md" loading={loading} onClick={onSubmit}>
              <Text.Span fontWeight={600}>Simulate</Text.Span>
            </ButtonLoading>
          </Flex.Item>
        </Flex.Container>
      </Block>
    </>
  );
};

export default TrailingStopSimulationForm;
