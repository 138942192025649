import { ReactNode } from 'react';

import styled from 'styled-components/macro';

import { Button, Flex, Text } from '@/atoms';
import Colors from '@/Colors';

interface TabTitleProps {
  label: string;
  isActive?: boolean;
  onClick: (tab: string) => void;
}

const TabTitle = (props: TabTitleProps) => {
  const { label, isActive, onClick } = props;

  return (
    <Button
      $transparent
      onClick={() => onClick(label)}
      style={{
        paddingBottom: '1rem',
        borderRadius: 0,
        borderBottom: isActive ? `2px solid ${Colors.white}` : 'none',
      }}
    >
      <Text.Span fontWeight={600}>{label}</Text.Span>
    </Button>
  );
};

interface TabsProps {
  tabs: string[];
  currentTab: string;
  setCurrentTab: (tab: string) => void;
  children?: ReactNode;
  style?: React.CSSProperties;
  className?: string;
  titles?: Array<typeof TabTitle>;
}

const TabsComponent = (props: TabsProps) => {
  const { tabs, className, setCurrentTab, currentTab, style, children, titles } = props;

  return (
    <div className={className} style={style}>
      <Flex.Container
        className="TabTitles"
        style={{
          backgroundColor: Colors.notSoBlack,
          paddingTop: '2rem',
          paddingLeft: '2rem',
          borderTopLeftRadius: '6px',
          borderTopRightRadius: '6px',
          gap: '4rem',
        }}
      >
        <>
          {titles !== undefined && { titles }}
          {titles === undefined &&
            tabs.map((tab) => (
              <TabTitle
                key={tab}
                label={tab}
                isActive={currentTab === tab}
                onClick={() => setCurrentTab(tab)}
              />
            ))}
        </>
      </Flex.Container>
      <div
        className="TabsContainer"
        style={{
          backgroundColor: style?.backgroundColor || Colors.grey2,
        }}
      >
        {children}
      </div>
    </div>
  );
};

const TabsContainer = styled(TabsComponent)``;

const TabsItem = styled.div`
  display: block;
`;

const Tabs = { Container: TabsContainer, Item: TabsItem };

export default Tabs;
