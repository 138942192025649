import { DataError, Either, UseCase } from '@robotrader/core-lib';

import {
  ImportExchangeOrdersProps,
  Operation,
  OperationRepository,
} from '@/modules/trader/domain';

interface ImportExchangeOrdersUseCaseProps {
  operationRepository: OperationRepository;
}

export class ImportExchangeOrdersUseCase
  implements UseCase<ImportExchangeOrdersProps, Either<DataError, Operation>>
{
  private operationRepository: OperationRepository;

  constructor({ operationRepository }: ImportExchangeOrdersUseCaseProps) {
    this.operationRepository = operationRepository;
  }

  async execute(
    props: ImportExchangeOrdersProps,
  ): Promise<Either<DataError, Operation>> {
    return this.operationRepository.importExchangeOrders(props);
  }
}
