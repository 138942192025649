import { UserDTO } from '@robotrader/common-types';

import { User } from '@/modules/user/domain';

export class UserMapper {
  static userDtoArrayToUserArray(usersDto: Array<UserDTO>): User[] {
    return usersDto.map(UserMapper.userDtoToUser);
  }

  static userDtoToUser(userDto: UserDTO): User {
    return User.create(userDto);
  }
}
