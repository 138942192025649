import { DataError, Either, UseCase } from '@robotrader/core-lib';

import { AuthRepository, ChangeUserPasswordProps } from '@/modules/auth/domain';

interface EditUserPasswordUseCaseProps {
  authRepository: AuthRepository;
}

export class EditUserPasswordUseCase
  implements UseCase<ChangeUserPasswordProps, Either<DataError, void>>
{
  private authRepository: AuthRepository;

  constructor({ authRepository }: EditUserPasswordUseCaseProps) {
    this.authRepository = authRepository;
  }

  execute(props: ChangeUserPasswordProps): Promise<Either<DataError, void>> {
    const { userId, password } = props;
    return this.authRepository.changeUserPassword({ userId, password });
  }
}
