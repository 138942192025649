import { ExchangePositionI } from '@robotrader/common-types';

import ExchangeNameAndIcon from '../ExchangeNameAndIcon';

const PositionExchangeIcon = (ePosition: ExchangePositionI) => {
  const { exchange } = ePosition;

  return <ExchangeNameAndIcon exchangeName={exchange} />;
};

export default PositionExchangeIcon;
