import { KeyboardEvent, useEffect, useState } from 'react';

import { Exchange } from '@robotrader/common-types';
import { dayjs, stringToNumber } from '@robotrader/common-utils';
import {
  Block,
  Card,
  Colors,
  DynamicTable,
  ExchangeNameAndIcon,
  Flex,
  Input,
  LoadingBlock,
  TableObjectProperties,
  // Block,
  // RouterLink,
  Text,
  UpdateTimeInfo,
  // Icon,
} from '@robotrader/design-system';
import { toast } from 'react-toastify';

import { useCradle } from '@/app/contexts';
import { useInterval, useIsMounted } from '@/app/hooks';
import { Cradle } from '@/di/Cradle';
import { EditExchangeParameters } from '@/modules/trader';

const DELAY_TIME = 15000; // milliseconds

const ExchangeIcon = (exchange: Exchange) => {
  const { name } = exchange;

  return <ExchangeNameAndIcon exchangeName={name} />;
};

const LastUpdate = (exchange: Exchange) => {
  const { lastCandlesUpdate, enabled } = exchange;
  const [duration, setDuration] = useState<string>();

  useEffect(() => {
    const now = dayjs();
    const dDuration = dayjs.duration(now.diff(lastCandlesUpdate));

    setDuration(`${dDuration.humanize()} ago`);
  }, [exchange]);

  return (
    <>
      <Block>
        <Text.Span color={enabled ? Colors.green : Colors.red}>
          {enabled ? 'Update Enabled' : 'Update Disabled'}
        </Text.Span>
      </Block>
      <Block>{duration}</Block>
    </>
  );
};

const ExchangesPage = () => {
  const { traderBloc } = useCradle<Cradle>();
  const isMounted = useIsMounted();
  const [exchanges, setExchanges] = useState<Exchange[] | undefined>(undefined);
  const [loadExchanges, setLoadExchanges] = useState<boolean>(true);
  const [isLoadingExchanges, setIsLoadingExchanges] = useState<boolean>(false);

  const editExchange = async (exchange: Exchange, parameters: EditExchangeParameters) => {
    try {
      await traderBloc.editExchange({
        exchangeId: exchange.id,
        parameters,
      });

      toast.success(`Exchange ${exchange.name} edited successfully!`);
    } catch (error: any) {
      if ('error' in error) {
        toast.error(error.error);
      } else if ('message' in error) {
        toast.error(error.message);
      } else {
        toast.error(error);
      }
    }
  };

  const EXCHANGES_PROPS: Array<TableObjectProperties<Exchange>> = [
    {
      label: 'Name',
      data: ExchangeIcon,
    },
    {
      label: 'Min Risk Limit',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (e: Exchange) => (
        <Flex.Container flexDirection="row" alignItems="center">
          <Input.Number
            style={{ marginRight: '0.5rem', width: '120px' }}
            defaultValue={e.minRiskLimitAllowed}
            onKeyDown={async (event: KeyboardEvent<HTMLInputElement>) => {
              if (event.key !== 'Enter') return;

              const riskLimit: number = stringToNumber(event.currentTarget.value);

              editExchange(e, { minRiskLimitAllowed: riskLimit });
            }}
          />
          <Text.Span style={{ wordBreak: 'keep-all' }}>USDT</Text.Span>
        </Flex.Container>
      ),
    },
    {
      label: 'Min Turnover 24h',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (e: Exchange) => (
        <Flex.Container flexDirection="row" alignItems="center">
          <Input.Number
            style={{ marginRight: '0.5rem', width: '120px' }}
            defaultValue={e.minTurnoverOf24hAllowed}
            onKeyDown={async (event: KeyboardEvent<HTMLInputElement>) => {
              if (event.key !== 'Enter') return;

              const turnoverOf24h: number = stringToNumber(event.currentTarget.value);

              editExchange(e, { minTurnoverOf24hAllowed: turnoverOf24h });
            }}
          />
          <Text.Span style={{ wordBreak: 'keep-all' }}>USDT</Text.Span>
        </Flex.Container>
      ),
    },
    {
      label: 'Min Months',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (e: Exchange) => (
        <Flex.Container flexDirection="row" alignItems="center">
          <Input.Number
            min={0}
            style={{ marginRight: '0.5rem', width: '120px' }}
            defaultValue={e.minMonthsAlive}
            onKeyDown={async (event: KeyboardEvent<HTMLInputElement>) => {
              if (event.key !== 'Enter') return;

              const monthsAlive: number = stringToNumber(event.currentTarget.value);

              editExchange(e, { minMonthsAlive: monthsAlive });
            }}
          />
        </Flex.Container>
      ),
    },
    {
      label: 'Last Update',
      data: LastUpdate,
    },
    {
      label: 'Actions',
      data: (e: Exchange) => (e.enabled ? 'Disable' : 'Enable'),
      onClick: async (e: Exchange) => {
        const action = e.enabled ? 'Disable' : 'Enable';
        // eslint-disable-next-line no-alert
        const confirm = window.confirm(`${action} exchange ${e.name}?`);

        if (!confirm) return;

        await traderBloc.editExchange({
          exchangeId: e.id,
          parameters: { enabled: !e.enabled },
        });

        setLoadExchanges(true);
      },
      color: (e: Exchange) => (e.enabled ? Colors.red : Colors.blue),
    },
  ];

  useEffect(() => {
    if (!loadExchanges) return;

    setIsLoadingExchanges(true);

    traderBloc
      .getExchanges()
      .then(setExchanges)
      .finally(() => {
        setLoadExchanges(false);
        setIsLoadingExchanges(false);
      });
  }, [loadExchanges]);

  useInterval(
    () => {
      setLoadExchanges(true);
    },
    // Delay in milliseconds or null to stop it
    isMounted() ? DELAY_TIME : null,
  );

  if (exchanges === undefined) {
    return <LoadingBlock loading text="Loading exchanges..." />;
  }

  return (
    <>
      {/* <Block style={{ textAlign: 'right' }}>
        <RouterLink $size="md" to="/exchanges/new">
          <Text.Span style={{ marginRight: '1rem' }}>Create</Text.Span>
          <Icon.Exchanges width={18} />
        </RouterLink>
      </Block> */}
      <UpdateTimeInfo
        milliseconds={DELAY_TIME}
        textColor={Colors.grey5}
        loading={isLoadingExchanges}
      />
      <Card.Container style={{ overflow: 'auto', marginTop: '1rem' }}>
        <Card.Body>
          <DynamicTable
            elements={exchanges}
            showIdColumn
            properties={EXCHANGES_PROPS}
            uniqueKeyName="id"
          />
        </Card.Body>
      </Card.Container>
    </>
  );
};

export default ExchangesPage;
