export interface ApiResponse<T> {
  code: number;
  message: string;
  timestamp: number;
  data: T;
}

export function isApiResponse<T>(payload: any): payload is ApiResponse<T> {
  if (!('code' in payload) || typeof payload.code !== 'number') return false;
  if (!('message' in payload) || typeof payload.message !== 'string')
    return false;
  if (!('timestamp' in payload) || typeof payload.timestamp !== 'number')
    return false;

  if (!('data' in payload)) return false;

  return true;
}
