import { ChangeEvent, useEffect, useState } from 'react';

import {
  Exchange,
  ExchangeName,
  ExchangeOrderI,
  ExchangeOrderId,
  ExchangePositionI,
  OrderSide,
  UserId,
} from '@robotrader/common-types';
import { capitalizeString, dayjs, humanizeNumber, roundNumber } from '@robotrader/common-utils';
import {
  Block,
  Button,
  ButtonLoading,
  Card,
  Colors,
  DynamicTable,
  Flex,
  Input,
  LoadingBlock,
  PositionExchangeIcon,
  Select,
  TableObjectProperties,
  Text,
} from '@robotrader/design-system';
import { useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useCradle } from '@/app/contexts';
import { useInterval, useIsMounted } from '@/app/hooks';
import { Cradle } from '@/di/Cradle';
import { Operation } from '@/modules/trader';
import { User } from '@/modules/user/domain';

import ExchangeOrderActions from './ExchangeOrderActions';
import ModalImportManyOrders from './ModalImportManyOrders';
import ModalImportOrder from './ModalImportOrder';

const EXCHANGE_POSITION_PROPS: Array<TableObjectProperties<ExchangePositionI>> = [
  {
    label: 'createdAt',
    data: (p: ExchangePositionI) => dayjs(p.openingTimestamp).format('DD-MM-YY HH:mm:ss Z[Z]'),
  },
  { label: 'Exchange', data: PositionExchangeIcon },
  { label: 'symbol', data: 'symbol', align: 'center' },
  {
    label: 'Entry Price',
    data: (p: ExchangePositionI) => `${humanizeNumber(p.avgEntryPrice)} USDT`,
    align: 'center',
  },
  { label: 'Qty', data: 'currentQty', align: 'center' },
  {
    label: 'Cost',
    data: (p: ExchangePositionI) => `${roundNumber(Math.abs(p.currentCost), 3)} USDT`,
    align: 'center',
  },
  { label: 'Leverage', data: 'realLeverage', align: 'center' },
  {
    label: 'realisedPnl',
    data: (p: ExchangePositionI) => `${roundNumber(p.realisedPnl, 2)} USDT`,
    color: Colors.red,
    align: 'center',
  },
  {
    label: 'unrealisedPnl',
    data: (p: ExchangePositionI) => {
      const pnl = p.unrealisedPnl;
      const cost = Math.abs(p.currentCost);
      const pnlPercentage = (pnl / cost) * 100 * p.realLeverage;

      return `${roundNumber(pnl, 2)} USDT (${roundNumber(pnlPercentage, 2)} %)`;
    },
    color: (p: ExchangePositionI) => {
      const pnl = p.unrealisedPnl;

      return pnl < 0 ? Colors.red : Colors.green;
    },
    align: 'center',
  },
];
const DELAY_TIME = 5000; // milliseconds
const EXCHANGE_NAME = 'exchangeName';

const UserExchangeInformation = () => {
  const { traderBloc, userBloc } = useCradle<Cradle>();
  const { userId } = useParams();
  const isMounted = useIsMounted();
  const [searchParams, setSearchParams] = useSearchParams();
  const [user, setUser] = useState<User | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [automaticLoad, setAutomaticLoad] = useState<boolean>(false);
  const [isImportModalOpen, setImportModalOpen] = useState<boolean>(false);
  const [isImportManyModalOpen, setImportManyModalOpen] = useState<boolean>(false);
  const [loadExchangeInfo, setLoadExchangeInfo] = useState<boolean>(false);
  const [exchanges, setExchanges] = useState<Exchange[] | undefined>(undefined);
  const [exchangeName, setExchangeName] = useState<ExchangeName | undefined>(undefined);
  const [userOperations, setUserOperations] = useState<Operation[] | undefined>(undefined);
  const [exchangeOrders, setExchangeOrders] = useState<ExchangeOrderI[] | undefined>(undefined);
  const [exchangeOrderToImport, setExchangeOrderToImport] = useState<ExchangeOrderI | undefined>(
    undefined,
  );
  const [exchangeOrdersToImport, setExchangeOrdersToImport] = useState<
    ExchangeOrderI[] | undefined
  >(undefined);
  const [exchangePositions, setExchangePositions] = useState<ExchangePositionI[] | undefined>(
    undefined,
  );
  const [eosMap, setEosMap] = useState<Map<ExchangeOrderId, ExchangeOrderI>>(
    new Map<ExchangeOrderId, ExchangeOrderI>(),
  );

  const loadUser = (uId: UserId) => {
    userBloc.getUser(uId).then(setUser);
  };

  const loadPositions = async (uId: UserId) => {
    if (!exchangeName) return;

    if (exchangeName === '') return;

    setLoading(true);

    try {
      await Promise.all([
        traderBloc.getOperations({ userId: uId }).then(setUserOperations),
        traderBloc.loadExchangeOrders({ userId: uId, exchangeName }).then(setExchangeOrders),
        traderBloc.loadExchangePositions({ userId: uId, exchangeName }).then(setExchangePositions),
      ]);
    } catch (error) {
      toast.error('An error ocurred loading exchange information from user');
    }

    setLoading(false);
  };

  const closeExchangeOrder = async (eo: ExchangeOrderI, uId: UserId) => {
    // eslint-disable-next-line no-alert
    const confirm = window.confirm(
      `This action will manually close order ${eo.id} from ${eo.exchange}, are you sure?`,
    );

    if (!confirm) return;

    setLoading(true);

    try {
      await traderBloc.closeExchangeOrder({
        userId: uId,
        exchangeName: eo.exchange,
        symbol: eo.symbol,
        orderId: eo.id,
      });
      toast.success(`Order closed successfully!`);
    } catch (error) {
      toast.error('An error ocurred while closing order');
    }

    setLoading(false);
    setLoadExchangeInfo(true);
  };

  const importExchangeOrders = async (eos: ExchangeOrderI[]) => {
    if (eos.length <= 0) return;

    const [firstEO] = eos;

    if (eos.some((eo) => eo.symbol !== firstEO.symbol)) {
      toast.error('All Exchange Orders must be of the same symbol');
      return;
    }

    if (eos.some((eo) => eo.side !== firstEO.side)) {
      toast.error('All Exchange Orders must have the same side');
      return;
    }

    const someOperationIsStop = eos.some((eo) => {
      const isStopOrder = eo.stopPrice !== null && eo.filledSize === 0;

      return isStopOrder;
    });

    if (someOperationIsStop) {
      toast.error('Close Orders can not be selected to be imported');
      return;
    }

    setExchangeOrdersToImport(eos);
    setImportManyModalOpen(true);
  };

  // eslint-disable-next-line no-console
  const onRowSelected = (eo: ExchangeOrderI) => {
    if (eosMap.get(eo.id)) {
      eosMap.delete(eo.id);
    } else {
      eosMap.set(eo.id, eo);
    }

    setEosMap(new Map(eosMap));
  };

  useEffect(() => {
    if (!loadExchangeInfo || !user) return;

    loadPositions(user.id);
    setLoadExchangeInfo(false);
  }, [loadExchangeInfo, user]);

  useInterval(
    () => {
      setLoadExchangeInfo(true);
    },
    // Delay in milliseconds or null to stop it
    isMounted() && automaticLoad ? DELAY_TIME : null,
  );

  useEffect(() => {
    if (isMounted()) {
      traderBloc.getExchanges().then(setExchanges);
    }
  }, [isMounted]);

  useEffect(() => {
    if (!isMounted()) return;

    if (userId && !Number.isNaN(userId)) {
      setUser(undefined);
      loadUser(Number(userId));
    }
  }, [isMounted, userId]);

  useEffect(() => {
    if (!isMounted()) return;

    const eName = searchParams.get(EXCHANGE_NAME);

    if (eName) {
      setExchangeName(eName);
    } else {
      setExchangeName(undefined);
    }
  }, [searchParams, isMounted]);

  if (user === undefined) {
    return <LoadingBlock loading text="Loading user..." />;
  }

  const EXCHANGE_ORDER_PROPS: Array<TableObjectProperties<ExchangeOrderI>> = [
    {
      label: '',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (eo: ExchangeOrderI) => (
        <Input.Checkbox readOnly checked={!!eosMap.get(eo.id)} id={eo.id} />
      ),
    },
    // { label: 'id', data: 'id' },
    {
      label: 'createdAt',
      data: (eo: ExchangeOrderI) => dayjs(eo.createdAt).format('DD-MM-YY HH:mm:ss Z[Z]'),
    },
    {
      label: 'type',
      color: (eo: ExchangeOrderI) => {
        const { closeOrder: isCloseOrder } = eo;
        const isStopOrder = eo.stopPrice !== null && eo.filledSize === 0;

        if (isStopOrder) return Colors.blue;
        if (isCloseOrder) return Colors.red;

        return Colors.green;
      },
      data: (eo: ExchangeOrderI) => {
        const { closeOrder: isCloseOrder } = eo;
        const isStopOrder = eo.stopPrice !== null && eo.filledSize === 0;

        if (isStopOrder) return 'Stop';
        if (isCloseOrder) return 'Close';

        return 'Open';
      },
    },
    { label: 'Exchange', data: 'exchange' },
    { label: 'symbol', data: 'symbol' },
    {
      label: 'Qty',
      data: (eo: ExchangeOrderI) => {
        const filledSize = Math.abs(eo.filledSize);
        const qty = eo.side === OrderSide.SELL.toLowerCase() ? -filledSize : filledSize;

        return `${qty}`;
      },
    },
    { label: 'Amount', data: (eo: ExchangeOrderI) => `${humanizeNumber(eo.filledValue)} USDT` },
    { label: 'Lvrg.', data: (eo: ExchangeOrderI) => `${eo.leverage}` },
    { label: 'Stop Price', data: (eo: ExchangeOrderI) => `${humanizeNumber(eo.stopPrice)} USDT` },
    {
      label: 'Actions',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (eo: ExchangeOrderI) => (
        <ExchangeOrderActions
          exchangeOrder={eo}
          exchangePositions={exchangePositions || []}
          userOperations={userOperations || []}
          userId={user.id}
          closeExchangeOrder={closeExchangeOrder}
          importExchangeOrder={(exchangeOrder: ExchangeOrderI) => {
            setExchangeOrderToImport(exchangeOrder);
            setImportModalOpen(true);
          }}
        />
      ),
    },
  ];

  return (
    <>
      <Card.Container style={{ marginTop: '1rem' }}>
        <Card.Body>
          <Flex.Container alignItems="center">
            <Block style={{ marginRight: '1rem' }} $inline>
              <Select
                isClearable
                hideSelectedOptions
                placeholder="Exchange"
                value={exchanges
                  ?.filter((e) => e.name === exchangeName)
                  .map((e) => ({ value: e.name, label: capitalizeString(e.name) }))}
                options={
                  exchanges
                    ? exchanges.map((e) => ({ value: e.name, label: capitalizeString(e.name) }))
                    : []
                }
                onChange={(newValue) => {
                  const { value } = (newValue as any) || {};
                  const selectedExchangeName = value !== '' ? value : undefined;

                  if (selectedExchangeName === undefined) {
                    searchParams.delete(EXCHANGE_NAME);
                  } else {
                    searchParams.set(EXCHANGE_NAME, selectedExchangeName);
                  }

                  setSearchParams(searchParams);
                }}
              />
            </Block>
            <ButtonLoading
              $size="sm"
              onClick={() => {
                if (!exchangeName) return;

                if (exchangeName === '') {
                  toast.error('Select exchange first');
                  return;
                }

                setLoadExchangeInfo(true);
              }}
              loading={loading}
              style={{ marginRight: '1rem' }}
            >
              Load Positions
            </ButtonLoading>
            <Text.Label>
              <Input.Checkbox
                style={{ marginRight: '1rem' }}
                onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                  setAutomaticLoad(evt.target.checked);
                }}
              />
              <Text.Span>Automatic Load?</Text.Span>
            </Text.Label>
          </Flex.Container>
        </Card.Body>
      </Card.Container>
      {exchangeOrders && exchangePositions && (
        <Flex.Container flexDirection="column">
          <Flex.Item flex={1}>
            <Text.H4 style={{ marginTop: '1rem' }}>Active Positions</Text.H4>
            <Card.Container style={{ marginTop: '1rem', maxHeight: '65vh', overflow: 'auto' }}>
              <Card.Body>
                <DynamicTable
                  elements={exchangePositions}
                  properties={EXCHANGE_POSITION_PROPS}
                  uniqueKeyName="id"
                  fallbackText="User has no active positions"
                />
              </Card.Body>
            </Card.Container>
          </Flex.Item>
          <Flex.Item flex={1}>
            <Text.H4 style={{ marginTop: '1rem' }}>Last Exchange Orders</Text.H4>
            <Card.Container style={{ marginTop: '1rem', maxHeight: '65vh', overflow: 'auto' }}>
              <Card.Body>
                <Block style={{ marginBottom: '1rem', textAlign: 'right' }}>
                  <Button
                    $size="sm"
                    disabled={eosMap.size <= 1}
                    onClick={() => importExchangeOrders(Array.from(eosMap.values()))}
                  >
                    Import Many
                  </Button>
                </Block>
                <DynamicTable
                  elements={exchangeOrders}
                  properties={EXCHANGE_ORDER_PROPS}
                  onRowSelected={onRowSelected}
                  uniqueKeyName="id"
                />
              </Card.Body>
            </Card.Container>
          </Flex.Item>
        </Flex.Container>
      )}
      {exchangeOrderToImport && userOperations && (
        <ModalImportOrder
          isOpen={isImportModalOpen}
          onClose={() => {
            setImportModalOpen(false);
          }}
          onSuccess={() => {
            setImportModalOpen(false);
            setExchangeOrderToImport(undefined);
            setLoadExchangeInfo(true);
          }}
          operations={userOperations}
          exchangeOrder={exchangeOrderToImport}
          userId={user.id}
        />
      )}
      {exchangeOrdersToImport && userOperations && (
        <ModalImportManyOrders
          isOpen={isImportManyModalOpen}
          onClose={() => {
            setImportManyModalOpen(false);
          }}
          onSuccess={() => {
            setImportManyModalOpen(false);
            setLoadExchangeInfo(true);
          }}
          operations={userOperations}
          exchangeOrders={exchangeOrdersToImport}
          userId={user.id}
        />
      )}
    </>
  );
};

export default UserExchangeInformation;
