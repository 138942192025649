import { AuthToken } from '@robotrader/common-types';
import { DataError, Either, UseCase } from '@robotrader/core-lib';

import { Auth, AuthRepository } from '@/modules/auth/domain';

interface GetAuthProps {
  token: AuthToken;
}

interface GetAuthUseCaseProps {
  authRepository: AuthRepository;
}

export class GetAuthUseCase
  implements UseCase<GetAuthProps, Either<DataError, Auth>>
{
  private authRepository: AuthRepository;

  constructor({ authRepository }: GetAuthUseCaseProps) {
    this.authRepository = authRepository;
  }

  execute(props: GetAuthProps): Promise<Either<DataError, Auth>> {
    const { token } = props;
    return this.authRepository.getMe(token);
  }
}
