import { QueueStatusJobsI } from '@robotrader/common-types';
import { Bloc } from '@robotrader/core-lib';

import { GetAllQueuesStatusUseCase } from '@/modules/job/app';

interface JobBlocProps {
  getAllQueuesStatusUseCase: GetAllQueuesStatusUseCase;
}

export class JobBloc extends Bloc<{}> {
  private getAllQueuesStatusUseCase: GetAllQueuesStatusUseCase;

  constructor({ getAllQueuesStatusUseCase }: JobBlocProps) {
    super({});

    this.getAllQueuesStatusUseCase = getAllQueuesStatusUseCase;
  }

  async getAllQueuesStatus(): Promise<QueueStatusJobsI<unknown>[]> {
    const result = await this.getAllQueuesStatusUseCase.execute();

    return result.getOrElse([]);
  }
}
