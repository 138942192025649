import { useEffect, useState } from 'react';

import { ExchangeOrderI, ExchangePositionI, UserId } from '@robotrader/common-types';
import { Button } from '@robotrader/design-system';

import { Operation } from '@/modules/trader';

interface ExchangeOrderActionsProps {
  exchangeOrder: ExchangeOrderI;
  userOperations: Operation[];
  exchangePositions: ExchangePositionI[];
  userId: UserId;
  closeExchangeOrder: (eo: ExchangeOrderI, userId: UserId) => void;
  importExchangeOrder: (eo: ExchangeOrderI, userId: UserId) => void;
}

const ExchangeOrderActions = (props: ExchangeOrderActionsProps) => {
  const {
    exchangeOrder,
    userOperations,
    exchangePositions,
    userId,
    closeExchangeOrder,
    importExchangeOrder,
  } = props;
  const [operationFound, setOperationFound] = useState<boolean>(false);
  const [hasClosingOperation, setHasClosingOperation] = useState<boolean>(false);
  const [hasOpenPosition, setHasOpenPosition] = useState<boolean>(false);

  const { closeOrder: isCloseOrder } = exchangeOrder;
  const isStopOrder = exchangeOrder.stopPrice !== null && exchangeOrder.filledSize === 0;

  useEffect(() => {
    const found = userOperations.find((o) => o.exchangeOrderId.includes(exchangeOrder.id));

    if (!isCloseOrder && !isStopOrder) {
      if (found) {
        const { closingOperation } = found;

        setHasClosingOperation(closingOperation !== undefined);
      }

      if (exchangePositions.length) {
        const symbolOpenPositions = exchangePositions.filter(
          (ep) => ep.symbol === exchangeOrder.symbol && exchangeOrder.endAt > ep.openingTimestamp,
        );
        setHasOpenPosition(symbolOpenPositions.length > 0);
      } else {
        setHasOpenPosition(false);
      }
    }

    setOperationFound(found !== undefined);
  }, [userOperations]);

  return (
    <>
      {!isCloseOrder && !hasClosingOperation && !isStopOrder && hasOpenPosition && (
        <Button
          $size="sm"
          style={{ marginRight: '1rem' }}
          $variant="cancel"
          onClick={($event) => {
            $event.stopPropagation();
            closeExchangeOrder(exchangeOrder, userId);
          }}
        >
          Close
        </Button>
      )}
      {!operationFound && !isStopOrder && (
        <Button
          $size="sm"
          onClick={($event) => {
            $event.stopPropagation();
            importExchangeOrder(exchangeOrder, userId);
          }}
        >
          Import
        </Button>
      )}
    </>
  );
};

export default ExchangeOrderActions;
