"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Exchange = void 0;
class Exchange {
    constructor(props) {
        this.id = props.id;
        this.name = props.name;
        this.lastCandlesUpdate = props.lastCandlesUpdate;
        this.minRiskLimitAllowed = props.minRiskLimitAllowed;
        this.minTurnoverOf24hAllowed = props.minTurnoverOf24hAllowed;
        this.minMonthsAlive = props.minMonthsAlive;
        this.createdAt = props.createdAt;
        this.enabled = props.enabled;
    }
    static create(props) {
        return new Exchange(props);
    }
}
exports.Exchange = Exchange;
