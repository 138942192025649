import { createRef, KeyboardEvent } from 'react';

import { ButtonLoading, Flex, Input, Text } from '@robotrader/design-system';
import { toast } from 'react-toastify';

import { User } from '@/modules/user/domain';

interface UserEditLeverageFormProps {
  user: User;
  submit: (props: { leverage: number }) => void;
  loading?: boolean;
}

const UserEditLeverageForm = (props: UserEditLeverageFormProps) => {
  const { submit, loading, user } = props;
  const leverageRef = createRef<HTMLInputElement>();

  const onSubmit = () => {
    if (leverageRef.current === null) {
      toast.error('Leverage must be provided');
      return;
    }

    const { value } = leverageRef.current;
    const leverage = Number(value);

    submit({ leverage });
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && !loading) {
      onSubmit();
    }
  };

  return (
    <Flex.Container>
      <Text.Span style={{ flex: 1 }}>Leverage</Text.Span>
      <Flex.Container style={{ flex: 1 }}>
        <Input.Number
          style={{ marginRight: '1rem', flex: 1 }}
          min={1}
          max={10}
          ref={leverageRef}
          placeholder="Name"
          onKeyDown={handleKeyDown}
          defaultValue={user.profile.leverage}
        />
        <ButtonLoading $size="sm" loading={loading} onClick={onSubmit}>
          <Text.Span fontWeight={600}>Edit Leverage</Text.Span>
        </ButtonLoading>
      </Flex.Container>
    </Flex.Container>
  );
};

export default UserEditLeverageForm;
