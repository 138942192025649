import styled from 'styled-components/macro';

interface ImgProps {
  readonly width?: string | number;
  readonly height?: string | number;
}

const Img = styled.img<ImgProps>`
  display: inline-block;
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: ${(props) => (props.height ? props.height : 'auto')};
`;

export default Img;
