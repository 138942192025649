import { useState } from 'react';

import { ExchangeOrderI, UserId } from '@robotrader/common-types';
import { Button, ButtonLoading, Flex, Modal } from '@robotrader/design-system';
import { toast } from 'react-toastify';

import { useCradle } from '@/app/contexts';
import { Cradle } from '@/di/Cradle';
import { Operation } from '@/modules/trader';

import ImportOrders from './ImportOrders';

interface ModalImportManyOrdersProps {
  isOpen: boolean;
  onClose?: () => void;
  onError?: () => void;
  onSuccess?: () => void;
  operations: Operation[];
  exchangeOrders: ExchangeOrderI[];
  userId: UserId;
}

const ModalImportManyOrders = (props: ModalImportManyOrdersProps) => {
  const { isOpen, onClose, onSuccess, onError, operations, exchangeOrders, userId } = props;
  const { traderBloc } = useCradle<Cradle>();
  const [loadingImport, setLoadingImport] = useState<boolean>(false);
  const [openingOperation, setOpeningOperation] = useState<Operation | undefined>(undefined);

  const importExchangeOrders = async (eos: ExchangeOrderI[], uId: UserId) => {
    if (loadingImport) return;

    setLoadingImport(true);

    const [firstEO] = exchangeOrders;

    try {
      await traderBloc.importExchangeOrders({
        userId: uId,
        exchangeName: firstEO.exchange,
        orderIds: exchangeOrders.map((eo) => eo.id),
        openingOperation,
      });
      toast.success(`Orders imported successfully!`);
      if (onSuccess) onSuccess();
    } catch (error) {
      toast.error('An error ocurred while importing order');
      if (onError) onError();
    }

    setLoadingImport(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      position="center"
      title="Import Orders"
      // backdropCloseable
      // closeable
    >
      <ImportOrders
        exchangeOrders={exchangeOrders}
        operations={operations}
        setOpeningOperation={setOpeningOperation}
      />
      <Flex.Container flexDirection="row" gap="2rem" style={{ marginTop: '2rem' }}>
        <Flex.Item flex={2} />
        <Flex.Item>
          <Button $size="md" $variant="cancel" style={{ width: '100%' }} onClick={onClose}>
            Cancel
          </Button>
        </Flex.Item>
        <Flex.Item>
          <ButtonLoading
            $size="md"
            style={{ width: '100%' }}
            loading={loadingImport}
            onClick={() => {
              importExchangeOrders(exchangeOrders, userId);
            }}
          >
            Submit
          </ButtonLoading>
        </Flex.Item>
      </Flex.Container>
    </Modal>
  );
};

export default ModalImportManyOrders;
