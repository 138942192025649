import LazyLoad, { LazyLoadProps } from 'react-lazyload';

import { Img } from '@/atoms';

import Icons from './Icons';

// eslint-disable-next-line import/prefer-default-export
export const LazyLoadImage = (props: LazyLoadProps & { src: string }) => {
  const { src, height } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <LazyLoad {...props} overflow placeholder={<Icons.Spinner width={height} height={height} />}>
      <Img src={src} height={height} />
    </LazyLoad>
  );
};
