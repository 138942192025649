import { ChangeEvent } from 'react';

import { Schedule, ScheduleWeekdays, WeekSchedule } from '@robotrader/common-types';
import styled from 'styled-components/macro';

import { Block, Flex, Input, Text } from '@/atoms';
import { Checkbox } from '@/molecules';

type WeekDay = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday';
interface DayWorkingHours {
  label: WeekDay;
  first: string;
  short: string;
  weekDay: ScheduleWeekdays;
}

const WEEKDAYS: Array<DayWorkingHours> = [
  { label: 'Monday', short: 'Mon', first: 'M', weekDay: 1 },
  { label: 'Tuesday', short: 'Tue', first: 'T', weekDay: 2 },
  { label: 'Wednesday', short: 'Wed', first: 'W', weekDay: 3 },
  { label: 'Thursday', short: 'Thu', first: 'T', weekDay: 4 },
  { label: 'Friday', short: 'Fri', first: 'F', weekDay: 5 },
  { label: 'Saturday', short: 'Sat', first: 'S', weekDay: 6 },
  { label: 'Sunday', short: 'Sun', first: 'S', weekDay: 0 },
];

interface WorkingHoursProps {
  style?: React.CSSProperties;
  schedule: WeekSchedule;
  classname?: string;
  scheduleChanged: (schedule: WeekSchedule) => void | Promise<void>;
}

const WorkingHours = (props: WorkingHoursProps) => {
  const { schedule, scheduleChanged, classname, style } = props;

  const handleChangeSchedule = (weekDay: ScheduleWeekdays, daySchedule: Schedule) => {
    schedule[weekDay] = daySchedule;

    scheduleChanged(schedule);
  };

  return (
    <Block className={classname} style={style}>
      <Flex.Container flexDirection="row" justifyContent="space-between">
        {WEEKDAYS.map((wd) => {
          const scheduleDay = schedule[wd.weekDay];

          return (
            <Checkbox
              key={wd.weekDay}
              label={wd.first}
              checked={scheduleDay ? scheduleDay.enabled : false}
              rounded
              size="lg"
              onChange={(value: boolean) => {
                if (value) {
                  handleChangeSchedule(wd.weekDay, { enabled: true, from: '08:00', to: '17:01' });
                } else {
                  handleChangeSchedule(wd.weekDay, { enabled: false });
                }
              }}
            />
          );
        })}
      </Flex.Container>
      <Flex.Container flexDirection="column" style={{ marginBottom: '1rem', marginTop: '1rem' }}>
        {WEEKDAYS.map((wd) => {
          const scheduleDay = schedule[wd.weekDay];

          if (scheduleDay === undefined || !scheduleDay.enabled) return null;

          return (
            <Flex.Container key={wd.weekDay} alignItems="center" style={{ marginBottom: '1rem' }}>
              <Text.Span style={{ flex: 1, textAlign: 'left' }}>{wd.short}</Text.Span>
              <Input.Time
                style={{ flex: 1, marginRight: '1rem' }}
                value={scheduleDay.from}
                onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                  scheduleDay.from = evt.target.value;
                  handleChangeSchedule(wd.weekDay, scheduleDay);
                }}
              />
              <Input.Time
                style={{ flex: 1 }}
                value={scheduleDay.to}
                onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                  scheduleDay.to = evt.target.value;
                  handleChangeSchedule(wd.weekDay, scheduleDay);
                }}
              />
            </Flex.Container>
          );
        })}
      </Flex.Container>
    </Block>
  );
};

export default styled(WorkingHours)``;
