import { OperationI } from '@robotrader/common-types';
import { roundNumber } from '@robotrader/common-utils';

import { Flex, Text } from '@/atoms';
import Colors from '@/Colors';

const EntryExitAmount = (o: OperationI) => {
  const { closingOperation, price, quantity } = o;
  const amount = price * quantity;

  const formatAmount = (a: number) => `${roundNumber(a, 3)}`;

  return (
    <Flex.Container flexDirection="column" gap="0.5rem">
      <Text.Label color={Colors.white}>E: {formatAmount(amount)}</Text.Label>
      {closingOperation && (
        <Text.Label color={Colors.white}>
          S: {formatAmount(closingOperation.price * closingOperation.quantity)}
        </Text.Label>
      )}
    </Flex.Container>
  );
};

export default EntryExitAmount;
