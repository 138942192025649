import {
  CandleI,
  Exchange,
  ExchangeId,
  ExchangeName,
  ExchangeOrderI,
  JobI,
  MethodParameters,
  OperationId,
  Pair,
  PairId,
  TradingSignalId,
  UserId,
} from '@robotrader/common-types';
import { Bloc, DataError } from '@robotrader/core-lib';

import {
  CloseExchangeOrderUseCase,
  DeleteOperationUseCase,
  EditExchangeProps,
  EditExchangeUseCase,
  EditMethodParametersProps,
  EditMethodParametersUseCase,
  EditPairProps,
  EditPairUseCase,
  GetAllExchangesUseCase,
  GetAllMethodParametersUseCase,
  GetAllOperationsUseCase,
  GetAllPairsUseCase,
  GetMultiScalpingV1SimulationUseCase,
  GetMultiScalpingV2SimulationUseCase,
  GetMultiScalpingV3SimulationUseCase,
  GetPairCandlesUseCase,
  GetPairUseCase,
  GetTradingSignalOperationsUseCase,
  GetTradingSignalsUseCase,
  GetTrailingStopV1SimulationUseCase,
  GetUserExchangeOrdersUseCase,
  GetUserExchangePositionsUseCase,
  ImportExchangeOrdersUseCase,
  ImportExchangeOrderUseCase,
  StopTraderUseCase,
  traderInitialState,
  TraderState,
} from '@/modules/trader/app';
import {
  CloseExchangeOrderProps,
  GetAllMethodParametersProps,
  GetAllOperationsFilters,
  GetAllTradingSignalsProps,
  ImportExchangeOrderProps,
  ImportExchangeOrdersProps,
  MultiScalperV1UseCaseProps,
  MultiScalperV2UseCaseProps,
  MultiScalperV3UseCaseProps,
  Operation,
  TradingSignal,
  TrailingStopV1UseCaseProps,
} from '@/modules/trader/domain';

interface TraderBlocProps {
  getAllOperationsUseCase: GetAllOperationsUseCase;
  getTradingSignalOperationsUseCase: GetTradingSignalOperationsUseCase;
  getAllExchangesUseCase: GetAllExchangesUseCase;
  deleteOperationUseCase: DeleteOperationUseCase;
  editExchangeUseCase: EditExchangeUseCase;
  getTradingSignalsUseCase: GetTradingSignalsUseCase;
  getMultiScalpingV1SimulationUseCase: GetMultiScalpingV1SimulationUseCase;
  getTrailingStopV1SimulationUseCase: GetTrailingStopV1SimulationUseCase;
  getMultiScalpingV2SimulationUseCase: GetMultiScalpingV2SimulationUseCase;
  getMultiScalpingV3SimulationUseCase: GetMultiScalpingV3SimulationUseCase;
  getAllMethodParametersUseCase: GetAllMethodParametersUseCase;
  editMethodParametersUseCase: EditMethodParametersUseCase;
  stopTraderUseCase: StopTraderUseCase;
  getAllPairsUseCase: GetAllPairsUseCase;
  getPairUseCase: GetPairUseCase;
  editPairUseCase: EditPairUseCase;
  getPairCandlesUseCase: GetPairCandlesUseCase;
  getUserExchangeOrdersUseCase: GetUserExchangeOrdersUseCase;
  getUserExchangePositionsUseCase: GetUserExchangePositionsUseCase;
  closeExchangeOrderUseCase: CloseExchangeOrderUseCase;
  importExchangeOrderUseCase: ImportExchangeOrderUseCase;
  importExchangeOrdersUseCase: ImportExchangeOrdersUseCase;
}

export class TraderBloc extends Bloc<TraderState> {
  private getAllOperationsUseCase: GetAllOperationsUseCase;
  private getTradingSignalOperationsUseCase: GetTradingSignalOperationsUseCase;
  private deleteOperationUseCase: DeleteOperationUseCase;
  private getAllExchangesUseCase: GetAllExchangesUseCase;
  private editExchangeUseCase: EditExchangeUseCase;
  private getTradingSignalsUseCase: GetTradingSignalsUseCase;
  private getMultiScalpingV1SimulationUseCase: GetMultiScalpingV1SimulationUseCase;
  private getMultiScalpingV2SimulationUseCase: GetMultiScalpingV2SimulationUseCase;
  private getMultiScalpingV3SimulationUseCase: GetMultiScalpingV3SimulationUseCase;
  private getTrailingStopV1SimulationUseCase: GetTrailingStopV1SimulationUseCase;
  private getAllMethodParametersUseCase: GetAllMethodParametersUseCase;
  private editMethodParametersUseCase: EditMethodParametersUseCase;
  private stopTraderUseCase: StopTraderUseCase;
  private getAllPairsUseCase: GetAllPairsUseCase;
  private getPairUseCase: GetPairUseCase;
  private editPairUseCase: EditPairUseCase;
  private getPairCandlesUseCase: GetPairCandlesUseCase;
  private getUserExchangeOrdersUseCase: GetUserExchangeOrdersUseCase;
  private getUserExchangePositionsUseCase: GetUserExchangePositionsUseCase;
  private closeExchangeOrderUseCase: CloseExchangeOrderUseCase;
  private importExchangeOrderUseCase: ImportExchangeOrderUseCase;
  private importExchangeOrdersUseCase: ImportExchangeOrdersUseCase;

  constructor({
    getAllOperationsUseCase,
    getTradingSignalOperationsUseCase,
    getAllExchangesUseCase,
    deleteOperationUseCase,
    editExchangeUseCase,
    stopTraderUseCase,
    getTradingSignalsUseCase,
    getMultiScalpingV1SimulationUseCase,
    getMultiScalpingV2SimulationUseCase,
    getMultiScalpingV3SimulationUseCase,
    getTrailingStopV1SimulationUseCase,
    getAllMethodParametersUseCase,
    editMethodParametersUseCase,
    getAllPairsUseCase,
    getPairUseCase,
    editPairUseCase,
    getPairCandlesUseCase,
    getUserExchangeOrdersUseCase,
    getUserExchangePositionsUseCase,
    closeExchangeOrderUseCase,
    importExchangeOrderUseCase,
    importExchangeOrdersUseCase,
  }: TraderBlocProps) {
    super(traderInitialState);

    this.getAllOperationsUseCase = getAllOperationsUseCase;
    this.getTradingSignalOperationsUseCase = getTradingSignalOperationsUseCase;
    this.getAllExchangesUseCase = getAllExchangesUseCase;
    this.deleteOperationUseCase = deleteOperationUseCase;
    this.editExchangeUseCase = editExchangeUseCase;
    this.getAllPairsUseCase = getAllPairsUseCase;
    this.getTradingSignalsUseCase = getTradingSignalsUseCase;
    this.getAllMethodParametersUseCase = getAllMethodParametersUseCase;
    this.editMethodParametersUseCase = editMethodParametersUseCase;
    this.getMultiScalpingV1SimulationUseCase =
      getMultiScalpingV1SimulationUseCase;
    this.getMultiScalpingV2SimulationUseCase =
      getMultiScalpingV2SimulationUseCase;
    this.getMultiScalpingV3SimulationUseCase =
      getMultiScalpingV3SimulationUseCase;
    this.getTrailingStopV1SimulationUseCase =
      getTrailingStopV1SimulationUseCase;
    this.stopTraderUseCase = stopTraderUseCase;
    this.editPairUseCase = editPairUseCase;
    this.getPairCandlesUseCase = getPairCandlesUseCase;
    this.getPairUseCase = getPairUseCase;
    this.getUserExchangeOrdersUseCase = getUserExchangeOrdersUseCase;
    this.getUserExchangePositionsUseCase = getUserExchangePositionsUseCase;
    this.closeExchangeOrderUseCase = closeExchangeOrderUseCase;
    this.importExchangeOrderUseCase = importExchangeOrderUseCase;
    this.importExchangeOrdersUseCase = importExchangeOrdersUseCase;

    this.init();
  }

  // eslint-disable-next-line class-methods-use-this
  private init() {}

  async getOperations(params?: {
    userId?: UserId;
    flat?: boolean;
    filters?: GetAllOperationsFilters;
  }): Promise<Operation[]> {
    const { userId, flat, filters } = params || {};

    const result = await this.getAllOperationsUseCase.execute({
      userId,
      filters,
      flat,
    });

    return result.getOrElse([]);
  }

  async getOperationsFromTradingSignal(
    tradingSignalId: TradingSignalId,
  ): Promise<Operation[]> {
    const result = await this.getTradingSignalOperationsUseCase.execute({
      tradingSignalId,
    });

    return result.getOrElse([]);
  }

  async deleteOperation(oId: OperationId): Promise<boolean> {
    const result = await this.deleteOperationUseCase.execute(oId);

    return result.getOrThrow();
  }

  async loadExchangeOrders(props: {
    userId: UserId;
    exchangeName: ExchangeName;
  }): Promise<any[]> {
    const result = await this.getUserExchangeOrdersUseCase.execute(props);

    return result.getOrThrow();
  }

  async loadExchangePositions(props: {
    userId: UserId;
    exchangeName: ExchangeName;
  }): Promise<any[]> {
    const result = await this.getUserExchangePositionsUseCase.execute(props);

    return result.getOrThrow();
  }

  async closeExchangeOrder(
    props: CloseExchangeOrderProps,
  ): Promise<ExchangeOrderI> {
    const result = await this.closeExchangeOrderUseCase.execute(props);

    return result.getOrThrow();
  }

  async importExchangeOrder(
    props: ImportExchangeOrderProps,
  ): Promise<Operation> {
    const result = await this.importExchangeOrderUseCase.execute(props);

    return result.getOrThrow();
  }

  // eslint-disable-next-line class-methods-use-this
  async importExchangeOrders(
    props: ImportExchangeOrdersProps,
  ): Promise<Operation> {
    const result = await this.importExchangeOrdersUseCase.execute(props);

    return result.getOrThrow();
  }

  async getExchanges(): Promise<Exchange[]> {
    const result = await this.getAllExchangesUseCase.execute();

    return result.getOrElse([]);
  }

  async editExchange(props: EditExchangeProps): Promise<Exchange> {
    const result = await this.editExchangeUseCase.execute(props);

    return result.getOrThrow();
  }

  async editPair(props: EditPairProps): Promise<Pair> {
    const result = await this.editPairUseCase.execute(props);

    return result.getOrThrow();
  }

  async getMethodParameters(
    props?: GetAllMethodParametersProps,
  ): Promise<MethodParameters[]> {
    const result = await this.getAllMethodParametersUseCase.execute(props);

    return result.getOrElse([]);
  }

  async editMethodParameters(
    props: EditMethodParametersProps,
  ): Promise<MethodParameters> {
    const result = await this.editMethodParametersUseCase.execute(props);

    return result.getOrThrow();
  }

  async getPairs(exchangeId?: ExchangeId): Promise<Pair[]> {
    const result = await this.getAllPairsUseCase.execute(exchangeId);

    return result.getOrElse([]);
  }

  async getPair(pairId: PairId): Promise<Pair> {
    const result = await this.getPairUseCase.execute(pairId);

    return result.getOrThrow();
  }

  async getPairCandles(pairId: PairId): Promise<CandleI[]> {
    const result = await this.getPairCandlesUseCase.execute(pairId);

    return result.getOrElse([]);
  }

  async simulateMultiscalperV1(
    props: MultiScalperV1UseCaseProps,
  ): Promise<TradingSignal[]> {
    const result = await this.getMultiScalpingV1SimulationUseCase.execute(
      props,
    );

    return result.getOrElse([]);
  }

  async simulateMultiscalperV2(
    props: MultiScalperV2UseCaseProps,
  ): Promise<TradingSignal[]> {
    const result = await this.getMultiScalpingV2SimulationUseCase.execute(
      props,
    );

    return result.getOrElse([]);
  }

  async simulateMultiscalperV3(
    props: MultiScalperV3UseCaseProps,
  ): Promise<TradingSignal[]> {
    const result = await this.getMultiScalpingV3SimulationUseCase.execute(
      props,
    );

    return result.getOrElse([]);
  }

  async simulateTrailingStopV1(
    props: TrailingStopV1UseCaseProps,
  ): Promise<TradingSignal[]> {
    const result = await this.getTrailingStopV1SimulationUseCase.execute(props);

    return result.getOrElse([]);
  }

  async getTradingSignals(
    props?: GetAllTradingSignalsProps,
  ): Promise<TradingSignal[]> {
    const result = await this.getTradingSignalsUseCase.execute(props);

    return result.getOrElse([]);
  }

  async stopTrader(): Promise<void> {
    const result = await this.stopTraderUseCase.execute();

    return result.getOrThrow();
  }

  // eslint-disable-next-line class-methods-use-this
  async getJobsStatus(): Promise<Array<JobI<any>>> {
    return [];
  }

  private handleError(error: DataError): TraderState {
    const ERROR: TraderState = {
      running: this.state.running,
      kind: 'ErrorTraderState',
      error: 'Sorry, an error has ocurred. Please try again later',
    };

    switch (error.kind) {
      case 'ApiError':
        break;
      case 'UnexpectedError':
        break;
      case 'Unauthorized':
        break;
      case 'NotFound':
        break;
      default:
        break;
    }

    return ERROR;
  }
}
