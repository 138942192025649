import { useEffect, useState } from 'react';

import { Exchange, isMethod, Method } from '@robotrader/common-types';
import { capitalizeString, dayjs } from '@robotrader/common-utils';
import {
  Block,
  Card,
  Select,
  SimpleSecondsCountDown,
  Text,
  TradingSignalsSummary,
} from '@robotrader/design-system';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useCradle } from '@/app/contexts';
import { useIsMounted } from '@/app/hooks';
import {
  MultiscalpingV1SimulationForm,
  MultiscalpingV2SimulationForm,
  TrailingStopSimulationForm,
} from '@/app/ui';
import { Cradle } from '@/di/Cradle';
import {
  MultiScalperV1UseCaseProps,
  MultiScalperV2UseCaseProps,
  MultiScalperV3UseCaseProps,
  TradingSignal,
  TrailingStopV1UseCaseProps,
} from '@/modules/trader';

const CALC_SECS_PER_WEEK_MULTISCALPING = 1.8;
const CALC_SECS_PER_WEEK_TRAILINGSTOP = 0.6;
const PARAM_EXCHANGE_NAME = 'exchangeName';
const PARAM_METHOD = 'method';

const SimulationPage = () => {
  const isMounted = useIsMounted();
  const { traderBloc } = useCradle<Cradle>();
  const [tradingSignals, setTradingSignals] = useState<TradingSignal[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [countdownSecs, setCountdownSecs] = useState<number | undefined>(0);
  const [simulationProps, setSimulationProps] = useState<
    { dateFrom: string; dateTo: string; method: Method } | undefined
  >(undefined);
  const [selectedMethod, setSelectedMethod] = useState<Method | undefined>();
  const [exchanges, setExchanges] = useState<Exchange[] | undefined>(undefined);
  const [exchange, setExchange] = useState<Exchange | undefined>(undefined);
  const [searchParams, setSearchParams] = useSearchParams();

  const simulateMultiscalperV1 = (props: MultiScalperV1UseCaseProps) => {
    setCountdownSecs(undefined);
    setLoading(true);
    setSimulationProps({ ...props, method: Method.MultiScalpingV1 });

    traderBloc
      .simulateMultiscalperV1(props)
      .then(setTradingSignals)
      .catch(() => {
        toast.error('Some error ocurred while simulating');
      })
      .finally(() => setLoading(false));
  };

  const simulateMultiscalperV2 = (props: MultiScalperV2UseCaseProps) => {
    setCountdownSecs(undefined);
    setLoading(true);
    setSimulationProps({ ...props, method: Method.MultiScalpingV2 });

    traderBloc
      .simulateMultiscalperV2(props)
      .then(setTradingSignals)
      .catch(() => {
        toast.error('Some error ocurred while simulating');
      })
      .finally(() => setLoading(false));
  };

  const simulateMultiscalperV3 = (props: MultiScalperV3UseCaseProps) => {
    setCountdownSecs(undefined);
    setLoading(true);
    setSimulationProps({ ...props, method: Method.MultiScalpingV3 });

    traderBloc
      .simulateMultiscalperV3(props)
      .then(setTradingSignals)
      .catch(() => {
        toast.error('Some error ocurred while simulating');
      })
      .finally(() => setLoading(false));
  };

  const simulateTrailingstopV1 = (props: TrailingStopV1UseCaseProps) => {
    setCountdownSecs(undefined);
    setLoading(true);
    setSimulationProps({ ...props, method: Method.TrailingStopV1 });

    traderBloc
      .simulateTrailingStopV1(props)
      .then(setTradingSignals)
      .catch(() => {
        toast.error('Some error ocurred while simulating');
      })
      .finally(() => setLoading(false));
  };

  const weekDiff = (params: { dateFrom: string; dateTo: string }) => {
    const { dateFrom, dateTo } = params;

    const wDiff = Math.abs(dayjs(dateFrom).diff(dateTo, 'weeks'));

    return wDiff;
  };

  useEffect(() => {
    if (!isMounted()) return;

    if (exchanges === undefined) {
      traderBloc.getExchanges().then(setExchanges);
    }
  }, [isMounted]);

  useEffect(() => {
    if (simulationProps === undefined) return;

    const wDiff = weekDiff(simulationProps);
    const initialTime = simulationProps.method === Method.MultiScalpingV1 ? 8 : 0;
    const CALC_SECS_PER_WEEK =
      simulationProps.method === Method.MultiScalpingV1
        ? CALC_SECS_PER_WEEK_MULTISCALPING
        : CALC_SECS_PER_WEEK_TRAILINGSTOP;
    setCountdownSecs(initialTime + wDiff * CALC_SECS_PER_WEEK);
  }, [simulationProps]);

  useEffect(() => {
    setTradingSignals(undefined);
  }, [selectedMethod, exchange]);

  useEffect(() => {
    if (!isMounted() || !exchanges) return;

    const paramExchangeName = searchParams.get(PARAM_EXCHANGE_NAME) || '';
    const method = searchParams.get(PARAM_METHOD);

    const ex = exchanges.find((e) => e.name === paramExchangeName);

    if (ex) {
      setExchange(ex);
    } else {
      setExchange(undefined);
    }

    if (isMethod(method)) {
      setSelectedMethod(method);
    } else {
      setSelectedMethod(undefined);
    }
  }, [searchParams, exchanges, isMounted]);

  return (
    <>
      <Card.Container style={{ marginBottom: '1rem' }}>
        <Card.Body>
          <Block $inline>
            <Select
              isClearable
              hideSelectedOptions
              placeholder="Method"
              value={Object.entries(Method)
                ?.filter((e) => e[1] === selectedMethod)
                .map((e) => ({
                  value: e[1],
                  label: capitalizeString(e[0]),
                }))}
              options={Object.entries(Method).map((e) => ({
                value: e[1],
                label: capitalizeString(e[0]),
              }))}
              onChange={(newValue) => {
                const { value } = (newValue as any) || {};

                if (isMethod(value)) {
                  searchParams.set(PARAM_METHOD, value);
                } else {
                  searchParams.delete(PARAM_METHOD);
                }

                setSearchParams(searchParams);
              }}
            />
          </Block>
          <Block style={{ marginLeft: '1rem' }} $inline>
            <Select
              isClearable
              hideSelectedOptions
              placeholder="Exchange"
              value={exchanges
                ?.filter((e) => e.name === exchange?.name)
                .map((e) => ({ value: e.name, label: capitalizeString(e.name) }))}
              options={
                exchanges
                  ? exchanges.map((e) => ({ value: e.name, label: capitalizeString(e.name) }))
                  : []
              }
              onChange={(newValue) => {
                const { value: selectedExchangeName } = (newValue as any) || {};

                if (selectedExchangeName === undefined || selectedExchangeName === '') {
                  searchParams.delete(PARAM_EXCHANGE_NAME);
                } else {
                  searchParams.set(PARAM_EXCHANGE_NAME, selectedExchangeName);
                }

                setSearchParams(searchParams);
              }}
            />
          </Block>
        </Card.Body>
      </Card.Container>
      <Card.Container style={{ marginBottom: '1rem' }}>
        <Card.Body>
          {selectedMethod && selectedMethod === Method.MultiScalpingV1 && exchange && (
            <MultiscalpingV1SimulationForm
              submit={simulateMultiscalperV1}
              loading={loading}
              exchangeName={exchange.name}
            />
          )}
          {selectedMethod && selectedMethod === Method.MultiScalpingV2 && exchange && (
            <MultiscalpingV2SimulationForm
              submit={simulateMultiscalperV2}
              loading={loading}
              exchangeName={exchange.name}
            />
          )}
          {selectedMethod && selectedMethod === Method.MultiScalpingV3 && exchange && (
            <MultiscalpingV2SimulationForm
              submit={simulateMultiscalperV3}
              loading={loading}
              exchangeName={exchange.name}
            />
          )}
          {selectedMethod && selectedMethod === Method.TrailingStopV1 && exchange && (
            <TrailingStopSimulationForm
              submit={simulateTrailingstopV1}
              loading={loading}
              exchange={exchange}
            />
          )}
        </Card.Body>
      </Card.Container>

      {tradingSignals && !loading && (
        <TradingSignalsSummary
          tradingSignals={tradingSignals}
          showFees
          pagination={false}
          tableOverflow
          showExchange={false}
          showMethod={false}
        />
      )}
      {loading && countdownSecs && (
        <Text.Paragraph textAlign="center">
          Calculating the simulation result. Estimated time remaining:{' '}
          <SimpleSecondsCountDown seconds={countdownSecs} />
        </Text.Paragraph>
      )}
      {!loading && !tradingSignals && (
        <Text.Paragraph textAlign="center"> No simulation data to show</Text.Paragraph>
      )}
    </>
  );
};

export default SimulationPage;
