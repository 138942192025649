import { OperationI } from '@robotrader/common-types';
import { dayjs } from '@robotrader/common-utils';

import { Flex, Text } from '@/atoms';
import Colors from '@/Colors';

const EntryExitDates = (o: OperationI) => {
  const { createdAt, closingOperation } = o;

  const formatDate = (d: Date) => dayjs(d).format('DD/MM/YY HH:mm ZZ[Z]');

  return (
    <Flex.Container flexDirection="column" gap="0.5rem">
      <Text.Label color={Colors.white}>E: {formatDate(createdAt)}</Text.Label>
      {closingOperation && (
        <Text.Label color={Colors.white}>S: {formatDate(closingOperation.createdAt)}</Text.Label>
      )}
    </Flex.Container>
  );
};

export default EntryExitDates;
