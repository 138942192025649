import { Exchange, ExchangeId } from '@robotrader/common-types';
import { DataError, Either, UseCase } from '@robotrader/core-lib';

import {
  EditExchangeParameters,
  ExchangeRepository,
} from '@/modules/trader/domain';

export interface EditExchangeProps {
  exchangeId: ExchangeId;
  parameters: EditExchangeParameters;
}

interface EditExchangeUseCaseProps {
  exchangeRepository: ExchangeRepository;
}

export class EditExchangeUseCase
  implements UseCase<EditExchangeProps, Either<DataError, Exchange>>
{
  private exchangeRepository: ExchangeRepository;

  constructor({ exchangeRepository }: EditExchangeUseCaseProps) {
    this.exchangeRepository = exchangeRepository;
  }

  execute(props: EditExchangeProps): Promise<Either<DataError, Exchange>> {
    const { exchangeId, parameters } = props;

    return this.exchangeRepository.editExchange(exchangeId, parameters);
  }
}
