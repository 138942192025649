import { DataError, Either, UseCase } from '@robotrader/core-lib';

import {
  GetAllTradingSignalsProps,
  TradingSignal,
  TradingSignalRepository,
} from '@/modules/trader/domain';

interface GetTradingSignalsUseCaseProps {
  tradingSignalRepository: TradingSignalRepository;
}

export class GetTradingSignalsUseCase
  implements
    UseCase<GetAllTradingSignalsProps, Either<DataError, TradingSignal[]>>
{
  private tradingSignalRepository: TradingSignalRepository;

  constructor({ tradingSignalRepository }: GetTradingSignalsUseCaseProps) {
    this.tradingSignalRepository = tradingSignalRepository;
  }

  execute(
    props?: GetAllTradingSignalsProps,
  ): Promise<Either<DataError, TradingSignal[]>> {
    return this.tradingSignalRepository.getAll(props);
  }
}
