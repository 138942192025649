import styled, { css } from 'styled-components/macro';

import Colors from '@/Colors';

interface ContainerProps {
  readonly borderStyle?: 'solid' | 'dashed' | 'dotted';
  readonly borderSize?: number;
  readonly backgroundColor?: string;
}

const commonCSS = css`
  padding: 1rem;
  border-top: 1px solid ${Colors.grey4};
  vertical-align: middle;
`;

const Container = styled.table<ContainerProps>`
  width: 100%;
  color: ${Colors.white};
  /* border: ${(props) => `${props.borderSize !== undefined ? props.borderSize : 0}px`}
    ${(props) => (props.borderStyle ? props.borderStyle : 'solid')} ${Colors.secondary};
    border-radius: 4px; */
  ${(props) => props.backgroundColor && `background-color: ${props.backgroundColor};`}
  border-collapse: collapse;
  border-spacing: 0;
`;

const Header = styled.thead``;

const Body = styled.tbody``;

const Footer = styled.tfoot``;

const TR = styled.tr`
  :hover {
    background-color: ${Colors.black};
  }
`;

const THR = styled.tr``;

const TH = styled.th`
  ${commonCSS}
  border-bottom: 2px solid ${Colors.grey4};
`;

const TD = styled.td`
  ${commonCSS}
`;

const Table = { Container, Body, Header, Footer, TH, THR, TR, TD };

export default Table;
