import { NavLink as RouterDomLink } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import Colors from '@/Colors';

interface LinkProps {
  readonly $size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  readonly $full?: boolean;
  readonly $transparent?: boolean;
  readonly $underline?: boolean;
  readonly $underlineHover?: boolean;
  readonly $outline?: boolean;
  readonly $outlineSize?: number;
  readonly $outlineColor?: string;
  readonly $outlineColorHover?: string;
  readonly $backgroundColor?: string;
  readonly $backgroundColorHover?: string;
  readonly $variant?: 'primary' | 'secondary' | 'warning' | 'cancel';
}

const paddingXS = '0.25rem 0.5rem';
const paddingSM = '0.5rem 1rem';
const paddingMD = '1rem 1.5rem';
const paddingLG = '1.5rem 3rem';
const paddingXL = '2rem 4rem';

const fontSizeXS = '12px';
const fontSizeSM = '14px';
const fontSizeMD = '16px';
const fontSizeLG = '20px';
const fontSizeXL = '24px';

const fontWeightXS = 300;
const fontWeightSM = 400;
const fontWeightMD = 500;
const fontWeightLG = 700;
const fontWeightXL = 900;

const commonColorCSS = css<LinkProps>`
  color: ${(props) => {
    if (props.$transparent) {
      switch (props.$variant) {
        case 'secondary':
          return Colors.secondary;
        case 'primary':
        case 'warning':
        case 'cancel':
        default:
          return Colors.white;
      }
    }

    switch (props.$variant) {
      case 'secondary':
        return Colors.black;
      case 'primary':
      case 'warning':
      case 'cancel':
      default:
        return Colors.white;
    }
  }};
`;

const commonCSS = css<LinkProps>`
  background-color: ${(props) => {
    if (props.$transparent) {
      return 'transparent';
    }

    if (props.$backgroundColor) {
      return props.$backgroundColor;
    }

    if (props.$variant === 'secondary') {
      return Colors.secondary;
    }

    if (props.$variant === 'warning') {
      return Colors.yellow;
    }

    if (props.$variant === 'cancel') {
      return Colors.red;
    }

    return Colors.primary;
  }};

  text-decoration: ${(props) => (props.$underline ? 'underline' : 'none')};
  border-radius: 6px;

  border: ${(props) => {
    if (!props.$outline && props.$variant !== 'warning') {
      return 0;
    }

    if (props.$outlineColor) {
      return `${props.$outlineSize || 1}px solid ${props.$outlineColor}`;
    }

    let color = Colors.white;

    switch (props.$variant) {
      case 'secondary':
        color = Colors.secondary;
        break;
      case 'primary':
        break;
      case 'warning':
        color = Colors.yellow2;
        break;
      default:
        break;
    }

    return `${props.$outlineSize || 1}px solid ${color}`;
  }};
  ${(props) => props.$full && 'width: 100%'};
  display: ${(props) => (props.$full ? 'flex' : 'inline-flex')};
  justify-content: center;
  align-items: center;

  font-weight: ${(props) => {
    switch (props.$size) {
      case 'xs':
        return fontWeightXS;
      case 'sm':
        return fontWeightSM;
      case 'md':
        return fontWeightMD;
      case 'lg':
        return fontWeightLG;
      case 'xl':
        return fontWeightXL;
      default:
        return fontWeightMD;
    }
  }};

  font-size: ${(props) => {
    switch (props.$size) {
      case 'xs':
        return fontSizeXS;
      case 'sm':
        return fontSizeSM;
      case 'md':
        return fontSizeMD;
      case 'lg':
        return fontSizeLG;
      case 'xl':
        return fontSizeXL;
      default:
        return fontSizeMD;
    }
  }};

  padding: ${(props) => {
    switch (props.$size) {
      case 'xs':
        return paddingXS;
      case 'sm':
        return paddingSM;
      case 'md':
        return paddingMD;
      case 'lg':
        return paddingLG;
      case 'xl':
        return paddingXL;
      default:
        return '0';
    }
  }};

  ${commonColorCSS}

  &:disabled,
  &[disabled] {
    pointer-events: all !important;
    opacity: 0.7;
    cursor: not-allowed;

    &:hover {
      cursor: not-allowed;
    }
  }

  * {
    ${commonColorCSS}
  }

  &:hover {
    cursor: pointer;

    background-color: ${(props) => {
      if (props.$backgroundColorHover) {
        return props.$backgroundColorHover;
      }

      if (props.$transparent) {
        return 'transparent';
      }

      if (props.$variant === 'secondary') {
        return Colors.secondaryDarker;
      }

      if (props.$variant === 'cancel') {
        return Colors.redDarker;
      }

      if (props.$variant === 'warning') {
        return Colors.yellowDarker;
      }

      return Colors.primaryDarker;
    }};

    ${(props) =>
      props.$outlineColorHover &&
      `border: ${props.$outlineSize || 1}px solid ${props.$outlineColorHover}`}
  }
`;

const underlineHoverCSS = css<LinkProps>`
  &:hover {
    text-decoration: underline;
  }

  &.active {
    text-decoration: none;
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }

    * {
      font-weight: 700;
    }
  }
`;

export const Link = styled.a.attrs((props: LinkProps) => ({
  ...props,
  $transparent: props.$transparent !== undefined ? props.$transparent : false,
}))<LinkProps>`
  ${commonCSS}

  ${(props) => (props.$underlineHover ? `${underlineHoverCSS}` : '')}
`;

export const RouterLink = styled(RouterDomLink).attrs((props: LinkProps) => ({
  ...props,
  $transparent: props.$transparent !== undefined ? props.$transparent : false,
}))<LinkProps>`
  ${commonCSS}

  ${(props) => (props.$underlineHover ? `${underlineHoverCSS}` : '')}
`;

export const Button = styled.button<LinkProps>`
  ${commonCSS}

  ${(props) => (props.$underlineHover ? `${underlineHoverCSS}` : '')}
`;
