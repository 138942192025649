import { Pair, PairDTO } from '@robotrader/common-types';

export class PairMapper {
  static pairDtoArrayToPairArray(exDtos: PairDTO[]): Pair[] {
    return exDtos.map(PairMapper.pairDtoToPair);
  }

  static pairDtoToPair(exDto: PairDTO): Pair {
    return Pair.create(exDto);
  }
}
