import { OperationId } from '@robotrader/common-types';
import { DataError, Either, UseCase } from '@robotrader/core-lib';

import { OperationRepository } from '@/modules/trader/domain';

interface DeleteOperationUseCaseProps {
  operationRepository: OperationRepository;
}

export class DeleteOperationUseCase
  implements UseCase<OperationId, Either<DataError, boolean>>
{
  private operationRepository: OperationRepository;

  constructor({ operationRepository }: DeleteOperationUseCaseProps) {
    this.operationRepository = operationRepository;
  }

  execute(id: OperationId): Promise<Either<DataError, boolean>> {
    return this.operationRepository.delete(id);
  }
}
