import { DataError, Either, UseCase } from '@robotrader/core-lib';

import { AuthRepository } from '@/modules/auth/domain';

interface LogoutUseCaseProps {
  authRepository: AuthRepository;
}

export class LogoutUseCase implements UseCase<void, Either<DataError, void>> {
  private authRepository: AuthRepository;

  constructor({ authRepository }: LogoutUseCaseProps) {
    this.authRepository = authRepository;
  }

  execute(): Promise<Either<DataError, void>> {
    return this.authRepository.logOut();
  }
}
