import _dayjs from 'dayjs';
import enLocale from 'dayjs/locale/en';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import localeData from 'dayjs/plugin/localeData';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';

_dayjs.extend(utc);
_dayjs.extend(isBetween);
_dayjs.extend(updateLocale);
_dayjs.extend(timezone);
_dayjs.extend(isoWeek);
_dayjs.extend(relativeTime);
_dayjs.extend(LocalizedFormat);
_dayjs.extend(customParseFormat);
_dayjs.extend(duration);
_dayjs.extend(advancedFormat);
_dayjs.extend(localeData);

enLocale.weekStart = 1;

_dayjs.locale(enLocale);

export type NewDayJs =
  | typeof utc
  | typeof isBetween
  | typeof updateLocale
  | typeof isoWeek
  | typeof localeData
  | typeof timezone
  | typeof relativeTime
  | typeof customParseFormat
  | typeof LocalizedFormat
  | typeof advancedFormat
  | typeof duration;

export default _dayjs;
