import {
  MethodParameters,
  MethodParametersDTO,
} from '@robotrader/common-types';

export class MethodParametersMapper {
  static methodParametersDtoArrayToMethodParametersArray(
    methodParameterDtos: MethodParametersDTO[],
  ): MethodParameters[] {
    return methodParameterDtos.map(
      MethodParametersMapper.methodParametersDtoToMethodParameters,
    );
  }

  static methodParametersDtoToMethodParameters(
    methodParametersDto: MethodParametersDTO,
  ): MethodParameters {
    return MethodParameters.create(methodParametersDto);
  }
}
