import { useEffect, useState } from 'react';

import { JobI, QueueStatusJobsI } from '@robotrader/common-types';
import { dayjs } from '@robotrader/common-utils';
import {
  Block,
  Card,
  Colors,
  DynamicTable,
  Icon,
  LoadingBlock,
  Modal,
  TableObjectProperties,
  UpdateTimeInfo,
} from '@robotrader/design-system';

import { useCradle } from '@/app/contexts';
import { useInterval, useIsMounted } from '@/app/hooks';
import { Cradle } from '@/di/Cradle';

const DELAY_TIME = 30000; // milliseconds

const JobsInfo = () => {
  const { jobBloc } = useCradle<Cradle>();
  const isMounted = useIsMounted();
  const [queueStatusJobs, setQueueStatusJobs] = useState<
    Array<QueueStatusJobsI<unknown>> | undefined
  >(undefined);
  const [selectedQueueStatus, setSelectedQueueStatus] = useState<
    QueueStatusJobsI<unknown> | undefined
  >(undefined);
  const [loadJobsStatus, setLoadJobsStatus] = useState<boolean>(true);
  const [isLoadingJobs, setIsLoadingJobs] = useState<boolean>(false);

  const QUEUE_STATUS_PROPS: Array<TableObjectProperties<QueueStatusJobsI<unknown>>> = [
    { label: 'Name', data: (qSJ) => qSJ.status.name },
    { label: 'Status', data: (qSJ) => JSON.stringify(qSJ.status.jobCounts, null, 2) },
    // { label: 'Jobs', data: (qSJ) => `${qSJ.jobs.length}` },
    {
      label: 'Jobs',
      // eslint-disable-next-line react/no-unstable-nested-components, @typescript-eslint/no-unused-vars
      data: (qSJ) => <Icon.Eye color={Colors.white} size="lg" />,
      onClick: setSelectedQueueStatus,
    },
  ];

  const JOBS_PROPS: Array<TableObjectProperties<JobI<unknown>>> = [
    { label: 'Name', data: (job) => job.name },
    { label: 'Id', data: (job) => job.id.toString() },
    // { label: 'Attempts', data: (job) => job.attemptsMade.toString() },
    {
      label: 'Processed',
      minWidth: '260px',
      data: (job) =>
        job.processedOn ? dayjs(job.processedOn).format('DD-MM-YY HH:mm:ss Z[Z]') : undefined,
    },
    {
      label: 'Params',
      minWidth: '260px',
      color: () => Colors.blue,
      data: (job) => JSON.stringify(job.data, null, 2),
    },
    {
      label: 'Result',
      color: (job) => (job.failedReason ? Colors.red : Colors.green),
      data: (job) => {
        if (job.failedReason) {
          return job.stacktrace ? job.stacktrace.join('\n') : '';
        }

        return typeof job.returnvalue === 'string'
          ? job.returnvalue
          : JSON.stringify(job.returnvalue, null, 2);
      },
    },
    // { label: 'Status', data: (job) => JSON.stringify(job.status.jobCounts, null, 2) },
    // { label: 'Jobs', data: (job) => `${job.jobs.length}` },
    // {
    //   label: 'Jobs',
    //   // eslint-disable-next-line react/no-unstable-nested-components, @typescript-eslint/no-unused-vars
    //   data: (job) => <Icon.Eye color={Colors.white} size="lg" />,
    //   onClick: setSelectedQueueStatus,
    // },
  ];

  useEffect(() => {
    if (!loadJobsStatus) return;

    setIsLoadingJobs(true);

    jobBloc
      .getAllQueuesStatus()
      .then(setQueueStatusJobs)
      .finally(() => {
        setLoadJobsStatus(false);
        setIsLoadingJobs(false);
      });
  }, [loadJobsStatus]);

  useInterval(
    () => {
      setLoadJobsStatus(true);
    },
    // Delay in milliseconds or null to stop it
    isMounted() ? DELAY_TIME : null,
  );

  if (queueStatusJobs === undefined) {
    return <LoadingBlock loading text="Loading Trader Info..." />;
  }

  return (
    <>
      <UpdateTimeInfo milliseconds={DELAY_TIME} textColor={Colors.grey5} loading={isLoadingJobs} />
      <Card.Container style={{ marginTop: '1rem', overflow: 'auto' }}>
        <Card.Body>
          <DynamicTable
            elements={queueStatusJobs}
            properties={QUEUE_STATUS_PROPS}
            uniqueKeyName="name"
          />
        </Card.Body>
      </Card.Container>
      <Modal
        isOpen={selectedQueueStatus !== undefined}
        backdropCloseable
        closeable
        onClose={() => {
          setSelectedQueueStatus(undefined);
        }}
        size="full"
        position="top"
        title={selectedQueueStatus?.name}
      >
        <Block style={{ paddingTop: '2rem', overflow: 'auto', height: 'auto', maxHeight: '80vh' }}>
          <DynamicTable
            elements={selectedQueueStatus ? selectedQueueStatus.jobs : []}
            properties={JOBS_PROPS}
            uniqueKeyName="id"
            pagination={false}
          />
        </Block>
      </Modal>
    </>
  );
};

export default JobsInfo;
