import { AuthId, AuthToken, Email } from '@robotrader/common-types';

interface AuthProps {
  id: AuthId;
  token: AuthToken;
  username: Email;
}

export class Auth {
  id: AuthId;
  token: AuthToken;
  username: Email;

  private constructor(props: AuthProps) {
    this.id = props.id;
    this.token = props.token;
    this.username = props.username;
  }

  static create(props: AuthProps): Auth {
    return new Auth(props);
  }
}
