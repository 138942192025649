import * as React from 'react';
import { SVGProps } from 'react';
const SvgUserIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.333 25v-2.667A5.333 5.333 0 0 0 17 17H6.333A5.333 5.333 0 0 0 1 22.333V25M11.666 11.667a5.333 5.333 0 1 0 0-10.667 5.333 5.333 0 0 0 0 10.667Z"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgUserIcon;
