import { ExchangeId, Pair } from '@robotrader/common-types';
import { DataError, Either, UseCase } from '@robotrader/core-lib';

import { PairRepository } from '@/modules/trader/domain';

interface GetAllPairsUseCaseProps {
  pairRepository: PairRepository;
}

export class GetAllPairsUseCase
  implements UseCase<ExchangeId, Either<DataError, Pair[]>>
{
  private pairRepository: PairRepository;

  constructor({ pairRepository }: GetAllPairsUseCaseProps) {
    this.pairRepository = pairRepository;
  }

  execute(exchangeId?: ExchangeId): Promise<Either<DataError, Pair[]>> {
    return this.pairRepository.getAll(exchangeId);
  }
}
