import styled from 'styled-components/macro';

const Nav = styled.nav`
  font-size: 18px;
  position: sticky;
  top: 0;
  z-index: 999;
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5); */
  /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15); */
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Nav;
