import { ExchangeName, ExchangeOrderI, UserId } from '@robotrader/common-types';
import { DataError, Either, UseCase } from '@robotrader/core-lib';

import { OperationRepository } from '@/modules/trader/domain';

interface GetUserExchangeOrdersProps {
  userId: UserId;
  exchangeName: ExchangeName;
}

interface GetUserExchangeOrdersUseCaseProps {
  operationRepository: OperationRepository;
}

export class GetUserExchangeOrdersUseCase
  implements
    UseCase<GetUserExchangeOrdersProps, Either<DataError, ExchangeOrderI[]>>
{
  private operationRepository: OperationRepository;

  constructor({ operationRepository }: GetUserExchangeOrdersUseCaseProps) {
    this.operationRepository = operationRepository;
  }

  async execute(
    props: GetUserExchangeOrdersProps,
  ): Promise<Either<DataError, ExchangeOrderI[]>> {
    const { userId, exchangeName } = props;

    const result = await this.operationRepository.getAllUserExchangeOrders(
      userId,
      exchangeName,
    );

    return result;
  }
}
