import { DataError, Either, UseCase } from '@robotrader/core-lib';

import {
  MultiScalperV2UseCaseProps,
  TradingSignal,
  TradingSignalRepository,
} from '@/modules/trader/domain';

interface GetMultiScalpingV2SimulationUseCaseProps {
  tradingSignalRepository: TradingSignalRepository;
}

export class GetMultiScalpingV2SimulationUseCase
  implements
    UseCase<MultiScalperV2UseCaseProps, Either<DataError, TradingSignal[]>>
{
  private tradingSignalRepository: TradingSignalRepository;

  constructor({
    tradingSignalRepository,
  }: GetMultiScalpingV2SimulationUseCaseProps) {
    this.tradingSignalRepository = tradingSignalRepository;
  }

  execute(
    props: MultiScalperV2UseCaseProps,
  ): Promise<Either<DataError, TradingSignal[]>> {
    return this.tradingSignalRepository.simulateMultiScalpingV2(props);
  }
}
