import { ApiResponse, isApiResponse } from './ApiResponse';

type TDataError = { error: string; message: string; statusCode: number };

export interface ApiError extends ApiResponse<TDataError> {
  data: TDataError;
}

export const isApiError = (payload: any): payload is ApiError => {
  if (!isApiResponse<TDataError>(payload)) return false;

  return true;
};
