import { useState } from 'react';

import { Action, Direction, ExchangeOrderI, OrderSide } from '@robotrader/common-types';
import { dayjs, humanizeNumber } from '@robotrader/common-utils';
import {
  Card,
  Colors,
  DynamicTable,
  Input,
  TableObjectProperties,
  Text,
} from '@robotrader/design-system';

import { Operation } from '@/modules/trader';

interface ImportOrdersProps {
  exchangeOrders: ExchangeOrderI[];
  operations?: Operation[];
  setOpeningOperation: (o: Operation) => void;
}

const ImportOrders = (props: ImportOrdersProps) => {
  const { exchangeOrders, operations: allUserOperations, setOpeningOperation } = props;
  const [firstEO] = exchangeOrders;
  const [operations] = useState<Operation[] | undefined>(
    allUserOperations?.filter(
      (op) =>
        op.pair.name === firstEO.symbol &&
        op.action === Action.ENTRY &&
        op.closingOperation === undefined,
    ),
  );

  const OPERATIONS_PROPS: Array<TableObjectProperties<Operation>> = [
    {
      label: '#',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (o: Operation) => o.exchangeOrderId,
    },
    { label: 'Date', data: (o: Operation) => dayjs(o.createdAt).format('DD-MM-YY HH:mm:ss Z[Z]') },
    {
      label: 'Action',
      color: (o: Operation) => `${o.action === Action.ENTRY ? Colors.green : Colors.red}`,
      data: 'action',
    },
    { label: 'Exchange', data: (o: Operation) => o.exchange.name, align: 'center' },
    { label: 'Symbol', data: (o: Operation) => o.pair.name, align: 'center' },
    // { label: 'Direction', data: (o: Operation) => `${o.direction}`, align: 'center' },
    {
      label: 'Qty',
      data: (o: Operation) => `${o.direction === Direction.LONG ? '' : '-'}${o.quantity}`,
      align: 'center',
    },
    {
      label: 'Amount',
      data: (o: Operation) => `${humanizeNumber(o.quantity * o.price)} USDT`,
      align: 'center',
    },
    { label: 'Lvrg.', data: (o: Operation) => `${o.leverage}` },
    {
      label: '',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (o: Operation) => (
        <Input.Radio
          key={`${o.id}`}
          value={o.id}
          onChange={() => {
            setOpeningOperation(o);
          }}
        />
      ),
    },
  ];

  const ORDER_PROPS: Array<TableObjectProperties<ExchangeOrderI>> = [
    {
      label: 'Date',
      data: (eo: ExchangeOrderI) => dayjs(eo.createdAt).format('DD-MM-YY HH:mm:ss Z[Z]'),
    },
    {
      label: 'type',
      color: (eo: ExchangeOrderI) => {
        const { closeOrder: isCloseOrder, stopPrice } = eo;
        const filledSize = Math.abs(eo.filledSize);
        const isStopOrder = stopPrice !== null && filledSize === 0;
        let typeColor = Colors.green;

        if (isStopOrder) {
          typeColor = Colors.blue;
        } else if (isCloseOrder) {
          typeColor = Colors.red;
        }

        return typeColor;
      },
      data: (eo: ExchangeOrderI) => {
        const { closeOrder: isCloseOrder, stopPrice } = eo;
        const filledSize = Math.abs(eo.filledSize);
        const isStopOrder = stopPrice !== null && filledSize === 0;
        let type = 'Open';

        if (isStopOrder) {
          type = 'Stop';
        } else if (isCloseOrder) {
          type = 'Close';
        }

        return type;
      },
    },
    { label: 'Exchange', data: (eo: ExchangeOrderI) => eo.exchange, align: 'center' },
    { label: 'Symbol', data: (eo: ExchangeOrderI) => eo.symbol, align: 'center' },
    {
      label: 'Qty',
      data: (eo: ExchangeOrderI) => {
        const filledSize = Math.abs(eo.filledSize);
        const qty = eo.side === OrderSide.SELL.toLowerCase() ? -filledSize : filledSize;

        return `${qty}`;
      },
    },
    { label: 'Amount', data: (eo: ExchangeOrderI) => `${eo.filledValue} USDT` },
    { label: 'Lvrg.', data: 'leverage' },
  ];

  return (
    <>
      <Text.H3 style={{ marginTop: '2rem' }}>Exchange Order</Text.H3>
      <Card.Container style={{ marginTop: '1rem', maxHeight: '65vh', overflow: 'auto' }}>
        <Card.Body style={{ padding: '0' }}>
          <DynamicTable
            elements={exchangeOrders}
            properties={ORDER_PROPS}
            uniqueKeyName="id"
            showIdColumn
            searchable={false}
          />
        </Card.Body>
      </Card.Container>
      <>
        <Text.H3 style={{ marginTop: '2rem' }}>Opening Operation</Text.H3>
        <Card.Container style={{ marginTop: '1rem', maxHeight: '65vh', overflow: 'auto' }}>
          <Card.Body style={{ padding: '0' }}>
            <DynamicTable
              elements={operations || []}
              properties={OPERATIONS_PROPS}
              uniqueKeyName="id"
              fallbackText="User has no operations to link with"
            />
          </Card.Body>
        </Card.Container>
      </>
    </>
  );
};

export default ImportOrders;
