import { useEffect, useState } from 'react';

import { Exchange, ExchangeId, Pair } from '@robotrader/common-types';
import { capitalizeString, stringToNumber } from '@robotrader/common-utils';
import {
  Block,
  Card,
  Colors,
  DynamicTable,
  ExchangeNameAndIcon,
  LoadingBlock,
  Select,
  TableObjectProperties,
} from '@robotrader/design-system';
import { useSearchParams } from 'react-router-dom';

import { useCradle } from '@/app/contexts';
import { useIsMounted } from '@/app/hooks';
import { Cradle } from '@/di/Cradle';
import { EditPairProps } from '@/modules/trader';

const PARAM_EXCHANGE_ID = 'exchangeId';
const PARAM_ENABLED = 'enabled';

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum ENABLED_OPTIONS {
  'ALL' = 'all',
  'ENABLED' = 'enabled',
  'DISABLED' = 'disabled',
}

const PairExchangeIcon = (pair: Pair) => {
  const { exchange } = pair;

  return <ExchangeNameAndIcon exchangeName={exchange.name} />;
};

const PairsPage = () => {
  const { traderBloc } = useCradle<Cradle>();
  const isMounted = useIsMounted();
  const [pairs, setPairs] = useState<Pair[] | undefined>(undefined);
  const [exchanges, setExchanges] = useState<Exchange[] | undefined>(undefined);
  const [exchangeId, setExchangeId] = useState<ExchangeId | 'all'>('all');
  const [pairEnabled, setPairEnabled] = useState<ENABLED_OPTIONS>(ENABLED_OPTIONS.ALL);
  const [searchParams, setSearchParams] = useSearchParams();

  const loadPairs = async () => {
    traderBloc.getPairs(exchangeId === 'all' ? undefined : exchangeId).then((ps) => {
      let filteredPairs = ps;

      if (pairEnabled !== ENABLED_OPTIONS.ALL) {
        const match = pairEnabled === ENABLED_OPTIONS.ENABLED;
        filteredPairs = filteredPairs.filter((p) => p.enabled === match);
      }

      setPairs(filteredPairs);
    });
  };

  const editPair = async (props: EditPairProps) => {
    await traderBloc.editPair(props);
    await loadPairs();
  };

  const PAIRS_PROPS: Array<TableObjectProperties<Pair>> = [
    {
      label: 'Name',
      data: 'name',
      routerLink: (p: Pair) => `/pairs/${p.id}`,
    },
    {
      label: 'Risk Limit',
      data: (p: Pair) =>
        p.riskLimitAmount ? `${p.riskLimitAmount.toLocaleString()} ${p.quoteCurrency}` : undefined,
    },
    {
      label: 'Turnover 24h',
      data: (p: Pair) =>
        p.turnoverOf24h ? `${p.turnoverOf24h.toLocaleString()} ${p.quoteCurrency}` : undefined,
    },
    {
      label: 'Exchange',
      data: PairExchangeIcon,
    },
    {
      label: 'Updated',
      data: (p: Pair) => p.lastCandlesUpdateTime,
    },
    {
      label: 'Open Date',
      data: (p: Pair) => p.firstOpenDateTime,
    },
    {
      label: 'Enabled',
      data: (p: Pair) => (p.enabled ? 'Run Enabled' : 'Run Disabled'),
      color: (p: Pair) => (p.enabled ? Colors.green : Colors.red),
    },
    {
      label: 'Actions',
      data: (p: Pair) => (p.enabled ? 'Disable Run' : 'Enable Run'),
      onClick: async (p: Pair) => {
        const action = p.enabled ? 'Disable Run' : 'Enable Run';
        // eslint-disable-next-line no-alert
        const confirm = window.confirm(`${action} exchange ${p.name}?`);

        if (!confirm) return;

        await editPair({ pairId: p.id, parameters: { enabled: !p.enabled } });
      },
      color: (p: Pair) => (p.enabled ? Colors.red : Colors.blue),
    },
  ];

  useEffect(() => {
    if (!isMounted()) return;

    const paramExchangeId = stringToNumber(searchParams.get(PARAM_EXCHANGE_ID) || '');
    const paramEnabled = searchParams.get(PARAM_ENABLED);

    if (!Number.isNaN(paramExchangeId)) {
      setExchangeId(paramExchangeId);
    } else {
      setExchangeId('all');
    }

    if (Object.values(ENABLED_OPTIONS).includes(paramEnabled as ENABLED_OPTIONS)) {
      setPairEnabled(paramEnabled as ENABLED_OPTIONS);
    } else {
      setPairEnabled(ENABLED_OPTIONS.ALL);
    }
  }, [searchParams, isMounted]);

  useEffect(() => {
    if (!isMounted()) return;

    if (exchanges === undefined) {
      traderBloc.getExchanges().then(setExchanges);
    }

    loadPairs();
  }, [exchangeId, pairEnabled, isMounted]);

  if (pairs === undefined) {
    return <LoadingBlock loading text="Loading pairs..." />;
  }

  return (
    <>
      {/* <Block style={{ textAlign: 'right' }}>
        <RouterLink $size="md" to="/pairs/new">
          <Text.Span style={{ marginRight: '1rem' }}>Create</Text.Span>
          <Icon.Coins width={18} />
        </RouterLink>
      </Block> */}
      <Card.Container style={{ marginTop: '1rem' }}>
        <Card.Body>
          <Block $inline>
            <Select
              isClearable
              hideSelectedOptions
              placeholder="Exchange"
              value={exchanges
                ?.filter((e) => e.id === exchangeId)
                .map((e) => ({ value: e.id, label: capitalizeString(e.name) }))}
              options={
                exchanges
                  ? exchanges.map((e) => ({ value: e.id, label: capitalizeString(e.name) }))
                  : []
              }
              onChange={(newValue) => {
                const { value } = (newValue as any) || {};
                const selectedExchangeId = value !== '' ? stringToNumber(value) : undefined;

                if (selectedExchangeId === undefined || Number.isNaN(selectedExchangeId)) {
                  searchParams.delete(PARAM_EXCHANGE_ID);
                } else {
                  searchParams.set(PARAM_EXCHANGE_ID, selectedExchangeId.toString());
                }

                setSearchParams(searchParams);
              }}
            />
          </Block>
          <Block style={{ marginLeft: '1rem' }} $inline>
            <Select
              isClearable
              hideSelectedOptions
              placeholder="Enabled?"
              value={Object.entries(ENABLED_OPTIONS)
                ?.filter((e) => e[1] === pairEnabled)
                .map((e) => ({
                  value: e[1],
                  label: capitalizeString(e[0]),
                }))}
              // options={exchanges ? exchanges.map((e) => ({ value: e.id, label: e.name })) : []}
              options={Object.entries(ENABLED_OPTIONS).map((e) => ({
                value: e[1],
                label: capitalizeString(e[0]),
              }))}
              onChange={(newValue) => {
                const { value } = (newValue as any) || {};

                if (value === ENABLED_OPTIONS.ALL) {
                  searchParams.delete(PARAM_ENABLED);
                } else {
                  searchParams.set(PARAM_ENABLED, value);
                }

                setSearchParams(searchParams);
              }}
            />
          </Block>
        </Card.Body>
      </Card.Container>
      <Card.Container style={{ marginTop: '1rem', maxHeight: '65vh', overflow: 'auto' }}>
        <Card.Body>
          <DynamicTable elements={pairs} showIdColumn properties={PAIRS_PROPS} uniqueKeyName="id" />
        </Card.Body>
      </Card.Container>
    </>
  );
};

export default PairsPage;
