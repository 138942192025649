import { MethodParameters, MethodParametersId } from '@robotrader/common-types';
import { DataError, Either, UseCase } from '@robotrader/core-lib';

import {
  EditParametersProps,
  MethodParametersRepository,
} from '@/modules/trader/domain';

export interface EditMethodParametersProps extends EditParametersProps {
  methodParametersId: MethodParametersId;
}

interface EditMethodParametersUseCaseProps {
  methodParametersRepository: MethodParametersRepository;
}

export class EditMethodParametersUseCase
  implements
    UseCase<EditMethodParametersProps, Either<DataError, MethodParameters>>
{
  private methodParametersRepository: MethodParametersRepository;

  constructor({
    methodParametersRepository,
  }: EditMethodParametersUseCaseProps) {
    this.methodParametersRepository = methodParametersRepository;
  }

  execute(
    props: EditMethodParametersProps,
  ): Promise<Either<DataError, MethodParameters>> {
    const { methodParametersId, parameters, schedule, enabled } = props;

    return this.methodParametersRepository.editParameters(methodParametersId, {
      parameters,
      schedule,
      enabled,
    });
  }
}
