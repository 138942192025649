import { OperationI } from '@robotrader/common-types';
import { roundNumber } from '@robotrader/common-utils';

import { Flex, Text } from '@/atoms';
import Colors from '@/Colors';

const OutcomeAndPercentage = (o: OperationI) => {
  const { outcome, outcomePercent: oPercent } = o;

  return (
    <Flex.Container flexDirection="column" gap="0.5rem">
      {outcome && oPercent && (
        <>
          <Text.Label color={outcome >= 0 ? Colors.green : Colors.red}>
            {roundNumber(outcome, 5)} USDT
          </Text.Label>
          <Text.Label color={outcome >= 0 ? Colors.green : Colors.red}>
            {roundNumber(oPercent, 3)}% (i. lvrg)
          </Text.Label>
        </>
      )}
    </Flex.Container>
  );
};

export default OutcomeAndPercentage;
