import React from 'react';

import ReactSelect, { GroupBase, Props, SelectInstance } from 'react-select';

import Colors from '@/Colors';

export const Select = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: Props<Option, IsMulti, Group>,
) => {
  const { styles, ...rest } = props;

  return (
    <ReactSelect
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      menuPosition="fixed"
      menuPlacement="auto"
      styles={{
        ...styles,
        menuPortal: (baseStyles) => ({
          ...baseStyles,
          zIndex: 9999,
        }),
        control: (baseStyles) => ({
          ...baseStyles,
          backgroundColor: Colors.greyDarker,
          border: `1px solid ${Colors.grey}`,
        }),
        singleValue: (baseStyles) => ({
          ...baseStyles,
          color: Colors.white,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          backgroundColor: Colors.greyDarker,
          border: `1px solid ${Colors.grey}`,
          width: 'max-content',
          minWidth: '100%',
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          color: Colors.white,
          backgroundColor: state.isFocused ? Colors.grey : Colors.greyDarker,
        }),
        noOptionsMessage: (baseStyles) => ({
          ...baseStyles,
          color: Colors.white,
          backgroundColor: Colors.greyDarker,
        }),
        clearIndicator: (baseStyles) => ({
          ...baseStyles,
          color: Colors.white,
        }),
        input: (baseStyles) => ({
          ...baseStyles,
          color: Colors.white,
        }),
        dropdownIndicator: (baseStyles) => ({
          ...baseStyles,
          color: Colors.white,
        }),
      }}
      theme={(theme) => ({ ...theme })}
    />
  );
};

export default Select;

export type { SelectInstance };
