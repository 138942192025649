import { QueueStatusI, QueueStatusJobsI } from '@robotrader/common-types';
import { DataError, Either, UseCase } from '@robotrader/core-lib';

import { JobRepository } from '@/modules/job/domain';

interface GetAllQueuesStatusUseCaseProps {
  jobRepository: JobRepository;
}

type GetAllQueuesStatusUseCaseResponse = Either<
  DataError,
  QueueStatusJobsI<unknown>[]
>;

export class GetAllQueuesStatusUseCase
  implements UseCase<void, GetAllQueuesStatusUseCaseResponse>
{
  private jobRepository: JobRepository;

  constructor({ jobRepository }: GetAllQueuesStatusUseCaseProps) {
    this.jobRepository = jobRepository;
  }

  async execute(): Promise<GetAllQueuesStatusUseCaseResponse> {
    const allQueuesResult = await this.jobRepository.getAllQueues();

    const queues = allQueuesResult.getOrElse([]);

    const queueStatusResultsPromises = await Promise.allSettled(
      queues.map((qName) => this.jobRepository.getQueueStatus(qName)),
    );

    const queueStatusResults = queueStatusResultsPromises
      .filter((r) => r.status === 'fulfilled')
      .map((r) => (r.status === 'fulfilled' ? r.value : undefined)) as Either<
      DataError,
      QueueStatusI
    >[];

    const queueStatus = queueStatusResults.map((qStatus) =>
      qStatus.getOrThrow(),
    );

    const queueStatusJobsResultsPromises = await Promise.allSettled(
      queueStatus.map(async (qStatus) => ({
        jobs: await this.jobRepository.getQueueLastJobs(qStatus.name),
        status: qStatus,
      })),
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const queueStatusJobsResults = queueStatusJobsResultsPromises
      .filter((r) => r.status === 'fulfilled')
      .map((r) => {
        if (r.status === 'rejected') return undefined;

        const { value: queueStatusJobs } = r;
        const jobs = queueStatusJobs.jobs.getOrThrow();

        return {
          name: queueStatusJobs.status.name,
          jobs,
          status: queueStatusJobs.status,
        };
      }) as QueueStatusJobsI<unknown>[];

    return Either.right(queueStatusJobsResults);
  }
}
