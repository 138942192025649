import { Operation } from '@/modules/trader/domain';

export interface CommonTraderState {
  running: boolean;
}

export interface LoadingTraderState {
  kind: 'LoadingTraderState';
}

export interface LoadedTraderState {
  kind: 'LoadedTraderState';
  operations: Array<Operation>;
  accum: number;
}

export interface ErrorTraderState {
  kind: 'ErrorTraderState';
  operations?: Array<Operation>;
  accum?: number;
  error: string;
}

export type TraderState = (
  | LoadingTraderState
  | LoadedTraderState
  | ErrorTraderState
) &
  CommonTraderState;

export const traderInitialState: TraderState = {
  kind: 'LoadingTraderState',
  running: false,
};
