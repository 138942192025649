import { ExchangeName, SupportedExchange } from '@robotrader/common-types';

import { Icon } from '@/molecules';

const ExchangeIcon = ({ exchangeName }: { exchangeName: ExchangeName }) => (
  <>
    {exchangeName === SupportedExchange.BINANCE && <Icon.Binance width={20} />}
    {exchangeName === SupportedExchange.KUCOIN && <Icon.Kucoin width={20} />}
  </>
);

export default ExchangeIcon;
