"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isExitReason = exports.ExitReason = void 0;
var ExitReason;
(function (ExitReason) {
    ExitReason["EMA"] = "EMA";
    ExitReason["TIME"] = "TIME";
    ExitReason["GOLACHO"] = "GOLACHO";
    ExitReason["STOP"] = "STOP";
    ExitReason["TRAILING_STOP"] = "TRAILING_STOP";
    ExitReason["GOAL"] = "GOAL";
    ExitReason["SEMAT"] = "SEMAT";
    ExitReason["TREND"] = "TREND";
})(ExitReason = exports.ExitReason || (exports.ExitReason = {}));
const EXIT_REASONS = Object.values(ExitReason);
const isExitReason = (x) => EXIT_REASONS.includes(x);
exports.isExitReason = isExitReason;
