import * as React from 'react';
import { SVGProps } from 'react';
const SvgLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 58 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={52} height={52} rx={6} fill="url(#logo_svg__a)" />
    <rect x={6} y={4} width={52} height={52} rx={6} fill="url(#logo_svg__b)" />
    <path
      d="M31.66 32.252h-2.884V39h-4.732V20.52h8.82c2.24 0 3.957.504 5.152 1.512 1.213 1.008 1.82 2.464 1.82 4.368 0 1.344-.308 2.464-.924 3.36-.616.896-1.512 1.559-2.688 1.988L40.928 39h-5.32l-3.948-6.748Zm-2.884-3.416h3.276c1.027 0 1.773-.187 2.24-.56.467-.373.7-.97.7-1.792 0-.803-.233-1.39-.7-1.764-.467-.373-1.213-.56-2.24-.56h-3.276v4.676Z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="logo_svg__a"
        x1={26}
        y1={0}
        x2={26}
        y2={52}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#24E1B4" />
        <stop offset={1} stopColor="#129898" />
      </linearGradient>
      <linearGradient
        id="logo_svg__b"
        x1={32}
        y1={4}
        x2={32}
        y2={56}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2DC1E1" />
        <stop offset={1} stopColor="#4273F2" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgLogo;
