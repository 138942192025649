import { useState } from 'react';

import {
  Button,
  Colors,
  Dropdown,
  Flex,
  Icon,
  Nav,
  NavLogoAdmin,
  RouterLink,
  Text,
} from '@robotrader/design-system';

import { useCradle } from '@/app/contexts';
import { useBlocState, useMediaQuery, useOuterClick } from '@/app/hooks';
import { Cradle } from '@/di/Cradle';

import styles from './NavBar.module.scss';

const NavBar = () => {
  const { authBloc } = useCradle<Cradle>();
  const authState = useBlocState(authBloc);
  const matches = useMediaQuery('(max-width: 920px)');
  const [displayMenu, setDisplayMenu] = useState<boolean>(false);
  const innerRef = useOuterClick(() => {
    setDisplayMenu(false);
  });

  if (authState.kind !== 'LoadedAuthState') {
    return null;
  }

  return (
    <Nav style={{ justifyContent: 'space-between', height: '70px' }}>
      <Flex.Container>
        <NavLogoAdmin style={{ height: '45px', marginRight: matches ? '0' : '2rem' }} to="/" />
        {matches && (
          <Flex.Container ref={innerRef} onClick={() => setDisplayMenu(!displayMenu)}>
            <Icon.Bars style={{ alignSelf: 'center', cursor: 'pointer' }} width={35} />
          </Flex.Container>
        )}
        <ul
          className={`${styles.menu} ${styles['main-menu']}${
            !matches || displayMenu ? ` ${styles.display}` : ''
          }`}
        >
          <li className={styles.menuItem}>
            <RouterLink $transparent $underlineHover to="/" end>
              <Text.Span>Home</Text.Span>
            </RouterLink>
          </li>
          <li className={styles.menuItem}>
            <RouterLink $transparent $underlineHover to="/users">
              <Text.Span>Users</Text.Span>
            </RouterLink>
          </li>
          <li className={styles.menuItem}>
            <RouterLink $transparent $underlineHover to="/trading-signals">
              <Text.Span>Trading Signals</Text.Span>
            </RouterLink>
          </li>
          <li className={styles.menuItem}>
            <RouterLink $transparent $underlineHover to="/operations">
              <Text.Span>Operations</Text.Span>
            </RouterLink>
          </li>
          <li className={styles.menuItem}>
            <RouterLink $transparent $underlineHover to="/exchanges">
              <Text.Span>Exchanges</Text.Span>
            </RouterLink>
          </li>
          <li className={styles.menuItem}>
            <RouterLink $transparent $underlineHover to="/pairs">
              <Text.Span>Pairs</Text.Span>
            </RouterLink>
          </li>
          {/* <li className={styles.menuItem}>
            <RouterLink $transparent $underlineHover to="/candles">
              <Text.Span>Candles</Text.Span>
            </RouterLink>
          </li> */}
        </ul>
      </Flex.Container>

      <Flex.Container alignItems="center" style={{ gap: matches ? '1rem' : '2rem' }}>
        <ul className={styles.menu}>
          <li className={styles.menuItem}>
            <RouterLink $transparent $underlineHover to="/robot">
              <Text.Span>
                <Icon.Robot size="xl" title="Robotrader state" />
              </Text.Span>
            </RouterLink>
          </li>
          <li className={styles.menuItem}>
            <RouterLink $size="md" to="/simulation">
              <Text.Strong>{matches ? 'Sim.' : 'Simulation'}</Text.Strong>
            </RouterLink>
          </li>
        </ul>
        <Dropdown.Container>
          <Dropdown.Button>
            <Icon.User width={21} />
          </Dropdown.Button>
          <Dropdown.Content>
            <Flex.Container flexDirection="column">
              <Button
                $backgroundColorHover={Colors.grey}
                $transparent
                $size="md"
                $full
                style={{ justifyContent: 'space-between' }}
                onClick={() => {
                  authBloc.logOut();
                }}
              >
                Logout <Icon.LogOut width={21} />
              </Button>
            </Flex.Container>
          </Dropdown.Content>
        </Dropdown.Container>
      </Flex.Container>
    </Nav>
  );
};

export default NavBar;
