"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isDirection = exports.Direction = void 0;
var Direction;
(function (Direction) {
    Direction["LONG"] = "LONG";
    Direction["SHORT"] = "SHORT";
})(Direction = exports.Direction || (exports.Direction = {}));
const DIRECTIONS = Object.values(Direction);
const isDirection = (x) => DIRECTIONS.includes(x);
exports.isDirection = isDirection;
