import * as React from 'react';
import { SVGProps } from 'react';
const SvgPlayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={32.762} height={32} rx={6} fill="#00D27A" />
    <path d="m24 17-12 6.928V10.072L24 17Z" fill="#fff" />
  </svg>
);
export default SvgPlayIcon;
