import { Action, Direction, TradingSignalI } from '@robotrader/common-types';
import { humanizeNumber, roundNumber } from '@robotrader/common-utils';

import { Card, Flex, Text } from '@/atoms';
import Colors from '@/Colors';
import { EvolutionGraph } from '@/organisms/EvolutionGraph';

import DynamicTable, { TableObjectProperties } from '../DynamicTable';
import ExchangeNameAndIcon from '../ExchangeNameAndIcon';
import ActionDirection from './ActionDirection';
import CandleCreatedDates from './CandleCreatedDates';

const TradingSignalExchangeIcon = (t: TradingSignalI) => {
  const { exchange: exchangeName } = t;

  return <ExchangeNameAndIcon exchangeName={exchangeName} />;
};

interface TradingSignalsSummaryProps {
  tradingSignals: TradingSignalI[];
  showMethod?: boolean;
  showGraph?: boolean;
  showSummary?: boolean;
  showExchange?: boolean;
  showFees?: boolean;
  showAcum?: boolean;
  pagination?: boolean;
  tableOverflow?: boolean;
  searcheable?: boolean;
  onTradingSignalClick?: (ts: TradingSignalI) => void;
}

const TradingSignalsSummary = (props: TradingSignalsSummaryProps) => {
  const {
    tradingSignals,
    showFees,
    pagination = true,
    tableOverflow = true,
    showMethod = true,
    showSummary = true,
    showExchange = true,
    showGraph = true,
    showAcum = true,
    searcheable = true,
    onTradingSignalClick,
  } = props;

  const exitTradingSignals = tradingSignals.filter((ts) => ts.action === Action.EXIT);
  const exitTSShort = exitTradingSignals.filter((ts) => ts.direction === Direction.SHORT);
  const exitTSLong = exitTradingSignals.filter((ts) => ts.direction === Direction.LONG);
  const fees: Array<number> = tradingSignals
    .map((ts) => ts.fee)
    .filter((val) => val !== undefined) as Array<number>;
  const totalFees = fees.reduce((prev, curr) => prev + curr, 0);
  const outcomes: Array<number> = exitTradingSignals
    .map((ts) => ts.outcome)
    .filter((val) => val !== undefined) as Array<number>;
  const accum = outcomes.reduce((prev, curr) => prev + curr, 0) + totalFees;
  const minOutcome = Math.min(
    ...exitTradingSignals.map((ts) => ts.outcome || Number.MAX_SAFE_INTEGER),
  );
  const maxOutcome = Math.max(
    ...exitTradingSignals.map((ts) => ts.outcome || Number.MIN_SAFE_INTEGER),
  );

  const TRADING_SIGNAL_PROPS: Array<TableObjectProperties<TradingSignalI>> = [
    {
      label: 'Date',
      data: CandleCreatedDates,
    },
    { label: 'Method', data: 'method', visible: showMethod },
    { label: 'Exchange', data: TradingSignalExchangeIcon, visible: showExchange },
    { label: 'Pair', data: 'pair', align: 'center' },
    {
      label: 'Action',
      data: ActionDirection,
      align: 'center',
    },
    { label: 'Price', data: (ts) => `${humanizeNumber(ts.price)}` },
    { label: 'Exit Reason', data: 'exitReason', align: 'center' },
    {
      label: 'Prev. Price',
      data: (ts) => (ts.prevPrice ? `${humanizeNumber(ts.prevPrice)}` : undefined),
    },
    {
      label: 'Fee',
      data: 'fee',
      color: Colors.red,
      visible: showFees !== undefined && showFees === true,
    },
    {
      label: 'Outcome',
      data: (ts) => (ts.outcome ? `${roundNumber(ts.outcome, 2)}%` : undefined),
      color: (ts) => {
        if (ts.outcome === undefined) return undefined;

        return ts.outcome >= 0 ? Colors.green : Colors.red;
      },
    },
    {
      label: 'Accum',
      data: (ts) => `${roundNumber(ts.accum, 2)}%`,
      color: (ts) => (ts.accum >= 0 ? Colors.green : Colors.red),
      visible: showAcum !== undefined && showAcum === true,
    },
  ];

  return (
    <Flex.Container
      flexDirection="column"
      style={{ flex: 1, maxHeight: tableOverflow ? '75vh' : undefined }}
    >
      {showSummary && (
        <Card.Container style={{ marginBottom: '1rem' }}>
          <Card.Body>
            <Flex.Container>
              <Flex.Item>
                <Text.Strong>Accum: </Text.Strong>
                <Text.Span color={accum >= 0 ? Colors.green : Colors.red}>
                  {roundNumber(accum, 3)} %
                </Text.Span>{' '}
                (<Text.Span color={Colors.red}>{roundNumber(minOutcome, 3)} %</Text.Span>/
                <Text.Span color={Colors.green}>{roundNumber(maxOutcome, 3)} %</Text.Span>)
              </Flex.Item>
              {showFees && (
                <Flex.Item>
                  <Text.Strong>Fees: </Text.Strong>
                  <Text.Span color={totalFees >= 0 ? Colors.green : Colors.red}>
                    {roundNumber(totalFees, 3)} %
                  </Text.Span>
                </Flex.Item>
              )}
              <Flex.Item>
                <Text.Strong>Operations: </Text.Strong>
                <Text.Span>
                  {exitTradingSignals.length} (
                  <Text.Span color={Colors.green}>
                    {exitTradingSignals.filter((ts) => ts.outcome && ts.outcome >= 0).length}
                  </Text.Span>
                  /
                  <Text.Span color={Colors.red}>
                    {exitTradingSignals.filter((ts) => ts.outcome && ts.outcome < 0).length}
                  </Text.Span>
                  )
                </Text.Span>
              </Flex.Item>
              <Flex.Item>
                <Text.Strong>Shorts: </Text.Strong>
                <Text.Span>
                  {exitTSShort.length} (
                  <Text.Span color={Colors.green}>
                    {exitTSShort.filter((ts) => ts.outcome && ts.outcome >= 0).length}
                  </Text.Span>
                  /
                  <Text.Span color={Colors.red}>
                    {exitTSShort.filter((ts) => ts.outcome && ts.outcome < 0).length}
                  </Text.Span>
                  )
                </Text.Span>
              </Flex.Item>
              <Flex.Item>
                <Text.Strong>Longs: </Text.Strong>
                <Text.Span>
                  {exitTSLong.length} (
                  <Text.Span color={Colors.green}>
                    {exitTSLong.filter((ts) => ts.outcome && ts.outcome >= 0).length}
                  </Text.Span>
                  /
                  <Text.Span color={Colors.red}>
                    {exitTSLong.filter((ts) => ts.outcome && ts.outcome < 0).length}
                  </Text.Span>
                  )
                </Text.Span>
              </Flex.Item>
            </Flex.Container>
          </Card.Body>
        </Card.Container>
      )}
      {showGraph && (
        <EvolutionGraph
          style={{ flex: 1, minHeight: '20vh', marginTop: '2rem' }}
          operations={tradingSignals.filter((ts) => ts.action !== Action.UPDATE_STOP)}
          lineColor={accum >= 0 ? Colors.green : Colors.red}
        />
      )}
      <Card.Container
        style={{
          flex: 1,
          overflow: tableOverflow ? 'auto' : undefined,
          marginTop: showGraph ? '2rem' : 0,
        }}
      >
        <Card.Body>
          <DynamicTable
            elements={tradingSignals}
            properties={TRADING_SIGNAL_PROPS}
            uniqueKeyName="id"
            pagination={pagination}
            searchable={searcheable}
            onRowSelected={(ts: TradingSignalI) => {
              if (ts.action === Action.EXIT && onTradingSignalClick) onTradingSignalClick(ts);
            }}
            // showIdColumn
          />
        </Card.Body>
      </Card.Container>
    </Flex.Container>
  );
};

export default TradingSignalsSummary;
