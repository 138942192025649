// function handleClickOutside(event: MouseEvent) {
//   if (ref.current && !ref.current.contains(event.target)) {
//     alert('You clicked outside of me!');
//   }
// }
import { useEffect, useRef } from 'react';

type Callback = (event: MouseEvent) => void;

export function useOuterClick(callback: Callback) {
  const callbackRef = useRef<Callback>(); // initialize mutable ref, which stores callback
  const innerRef = useRef<any>(); // returned to client, who marks "border" element

  // update cb on each render, so second useEffect has access to current value
  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    function handleClick(event: MouseEvent) {
      if (
        innerRef.current &&
        callbackRef.current &&
        !innerRef.current.contains(event.target)
      )
        callbackRef.current(event);
    }

    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []); // no dependencies -> stable click listener

  return innerRef; // convenience for client (doesn't need to init ref himself)
}
