import { useEffect, useState } from 'react';

import { Bloc } from '@robotrader/core-lib';

export function useBlocState<S>(bloc: Bloc<S>) {
  const [state, setState] = useState<S>(bloc.state);

  useEffect(() => {
    bloc.subscribe(setState);

    return () => bloc.unsubscribe(setState);
  }, [bloc]);

  return state;
}
