const Sizes = {
  Container: {
    xs: '400px',
    sm: '600px',
    md: '800px',
    lg: '1000px',
    xl: '1200px',
    xxl: '1400px',
    container: '1600px',
  },
};

export default Sizes;
