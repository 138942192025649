import * as React from 'react';
import { SVGProps } from 'react';
const SvgPauseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={32.762} height={32} rx={6} fill="#E60045" />
    <rect x={9} y={8} width={6} height={16} rx={1} fill="#fff" />
    <rect x={18} y={8} width={6} height={16} rx={1} fill="#fff" />
  </svg>
);
export default SvgPauseIcon;
