import styled from 'styled-components/macro';

interface SectionProps {
  readonly backgroundColor?: string;
  readonly py?: number;
  readonly px?: number;
  readonly my?: number;
  readonly mx?: number;
}

const Section = styled.section<SectionProps>`
  display: flex;
  background-color: ${(props) => props.backgroundColor || 'transparent'};
  padding: ${(props) => `${(props.py || 0) * 0.25}rem`} ${(props) => `${(props.px || 0) * 0.25}rem`};
  margin: ${(props) => `${(props.my || 0) * 0.25}rem`} ${(props) => `${(props.mx || 0) * 0.25}rem`};
`;

export default Section;
