"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAction = exports.Action = void 0;
var Action;
(function (Action) {
    Action["ENTRY"] = "ENTRY";
    Action["UPDATE_STOP"] = "UPDATE_STOP";
    Action["EXIT"] = "EXIT";
})(Action = exports.Action || (exports.Action = {}));
const ACTIONS = Object.values(Action);
const isAction = (x) => ACTIONS.includes(x);
exports.isAction = isAction;
