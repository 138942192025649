import { UserId } from '@robotrader/common-types';
import { DataError, Either, UseCase } from '@robotrader/core-lib';

import { User, UserRepository } from '@/modules/user/domain';

interface GetUserProps {
  userId: UserId;
}

interface GetUserUseCaseProps {
  userRepository: UserRepository;
}

export class GetUserUseCase
  implements UseCase<GetUserProps, Either<DataError, User>>
{
  private userRepository: UserRepository;

  constructor({ userRepository }: GetUserUseCaseProps) {
    this.userRepository = userRepository;
  }

  execute(props: GetUserProps): Promise<Either<DataError, User>> {
    const { userId } = props;
    return this.userRepository.getUser(userId);
  }
}
