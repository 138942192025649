import { ExchangeName } from '@robotrader/common-types';

import { Flex, Text } from '@/atoms';

import ExchangeIcon from './ExchangeIcon';

const ExchangeIconAndName = ({ exchangeName }: { exchangeName: ExchangeName }) => (
  <Flex.Container gap="0.5rem">
    <ExchangeIcon exchangeName={exchangeName} />
    <Text.Label style={{ textTransform: 'capitalize' }}>{exchangeName}</Text.Label>
  </Flex.Container>
);

export default ExchangeIconAndName;
