import { Colors, Flex, Footer, Main, Text } from '@robotrader/design-system';
import { Outlet } from 'react-router-dom';

import styles from './AuthLayout.module.scss';

const AuthLayout = () => (
  <Flex.Container
    flexDirection="column"
    className={styles.wrapper}
    style={{ backgroundColor: Colors.black, color: Colors.white }}
  >
    <Main className={styles.main}>
      <Outlet />
    </Main>
    <Footer>
      <Text.Span fontWeight={400} fontSize={16}>
        Robotrader ©
      </Text.Span>
    </Footer>
  </Flex.Container>
);

export default AuthLayout;
