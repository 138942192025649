import { AuthToken, Email, Password, UserId } from '@robotrader/common-types';
import { Bloc, DataError } from '@robotrader/core-lib';

import {
  AuthState,
  authStateInitialState,
  EditUserPasswordUseCase,
  GetAuthUseCase,
  LoginUseCase,
  LogoutUseCase,
} from '@/modules/auth/app';
import { Auth } from '@/modules/auth/domain';
import { StorageRepository } from '@/modules/shared/domain';

interface AuthBlocProps {
  loginUseCase: LoginUseCase;
  logoutUseCase: LogoutUseCase;
  getAuthUseCase: GetAuthUseCase;
  storageRepository: StorageRepository;
  editUserPasswordUseCase: EditUserPasswordUseCase;
}

export class AuthBloc extends Bloc<AuthState> {
  private static readonly AUTH_KEY = 'ROBOTRADER_BO_AUTH';
  private loginUseCase: LoginUseCase;
  private logoutUseCase: LogoutUseCase;
  private getAuthUseCase: GetAuthUseCase;
  private storageRepository: StorageRepository;
  private editUserPasswordUseCase: EditUserPasswordUseCase;

  constructor({
    loginUseCase,
    logoutUseCase,
    getAuthUseCase,
    storageRepository,
    editUserPasswordUseCase,
  }: AuthBlocProps) {
    super(authStateInitialState);

    this.loginUseCase = loginUseCase;
    this.logoutUseCase = logoutUseCase;
    this.getAuthUseCase = getAuthUseCase;
    this.storageRepository = storageRepository;
    this.editUserPasswordUseCase = editUserPasswordUseCase;

    this.init();
  }

  private async init() {
    const storageResult = await this.storageRepository.getItem<AuthState>(
      AuthBloc.AUTH_KEY,
    );

    const authState = storageResult.getOrElse(undefined);

    if (authState === undefined || authState.kind !== 'LoadedAuthState') {
      this.changeState({ kind: 'NotLoggedAuthState' });
      return;
    }

    this.getMeAuth(authState.auth.token);
  }

  async logIn(email: Email, password: Password) {
    if (this.state.kind === 'LoadingAuthState') {
      return;
    }

    this.changeState({ kind: 'LoadingAuthState' });

    const result = await this.loginUseCase.execute({ email, password });

    result.fold(
      (error: DataError) => this.changeState(AuthBloc.handleError(error)),
      (auth: Auth) => {
        this.changeState({ kind: 'LoadedAuthState', auth });
        this.storageRepository.setItem<AuthState>(
          AuthBloc.AUTH_KEY,
          this.state,
        );
      },
    );
  }

  async getMeAuth(token: AuthToken) {
    if (this.state.kind === 'LoadingAuthState') {
      return;
    }

    this.changeState({ kind: 'LoadingAuthState' });

    const result = await this.getAuthUseCase.execute({ token });

    result.fold(
      (error) => this.changeState(AuthBloc.handleError(error)),
      (auth) => this.changeState({ kind: 'LoadedAuthState', auth }),
    );
  }

  async logOut() {
    this.changeState({ kind: 'LoadingAuthState' });
    const result = await this.logoutUseCase.execute();

    result.fold(
      (error) => this.changeState(AuthBloc.handleError(error)),
      () => {
        this.storageRepository.removeItem(AuthBloc.AUTH_KEY);
        this.changeState({ kind: 'NotLoggedAuthState' });
      },
    );
  }

  async editPassword({
    userId,
    password,
  }: {
    userId: UserId;
    password: string;
  }): Promise<boolean> {
    const result = await this.editUserPasswordUseCase.execute({
      userId,
      password,
    });

    if (result.isLeft()) return false;

    return true;
  }

  private static handleError(error: DataError): AuthState {
    const ERROR: AuthState = {
      kind: 'ErrorAuthState',
      error: 'Sorry, an error has ocurred. Please try again later',
    };

    switch (error.kind) {
      case 'ApiError':
        break;
      case 'UnexpectedError':
        break;
      case 'Unauthorized':
        break;
      case 'NotFound':
        break;
      default:
        break;
    }

    return ERROR;
  }
}
