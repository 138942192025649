import styled from 'styled-components/macro';

import Sizes from '@/Sizes';

const Container = styled.div`
  margin: 0 auto;
  padding: 0 50px;
  max-width: 1400px;
  width: 100%;
  height: 100%;
  flex: 1 1 auto;

  @media (max-width: 400px) {
    padding: 0 1rem;
  }
  @media (max-width: 991px) {
    padding: 0 2rem;
  }

  @media (min-width: ${Sizes.Container.container}) {
    max-width: ${Sizes.Container.container};
  }

  /* @media (min-width: 1800px) {
    max-width: 1800px;
    padding: 0 30px;
  } */
`;

export default Container;
