import * as React from 'react';
import { SVGProps } from 'react';
const SvgBinanceIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 126.61 126.61"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <g fill="#f3ba2f">
      <path d="m38.73 53.2 24.59-24.58 24.6 24.6 14.3-14.31L63.32 0l-38.9 38.9zM0 63.31 14.3 49l14.31 14.31-14.31 14.3zM38.73 73.41 63.32 98l24.6-24.6 14.31 14.29-38.9 38.91-38.91-38.88zM98 63.31 112.3 49l14.31 14.3-14.31 14.32z" />
      <path d="M77.83 63.3 63.32 48.78 52.59 59.51l-1.24 1.23-2.54 2.54 14.51 14.5 14.51-14.47z" />
    </g>
  </svg>
);
export default SvgBinanceIcon;
