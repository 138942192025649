import styled from 'styled-components/macro';

interface FlexContainerProps {
  readonly display?: 'flex' | 'inline-flex';
  readonly flex?: number;
  readonly gap?: number | string;
  readonly flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  readonly flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  readonly justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'center';
  readonly alignContent?:
    | 'stretch'
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around';
  readonly alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
}

const FlexContainer = styled.div<FlexContainerProps>`
  display: ${(props) => props.display || 'flex'};
  ${(props) => props.flex && `flex: ${props.flex}`};
  ${(props) => props.gap && `gap: ${props.gap}`};
  flex-direction: ${(props) => props.flexDirection || 'row'};
  flex-wrap: ${(props) => props.flexWrap || 'nowrap'};
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  align-content: ${(props) => props.alignContent || 'stretch'};
  align-items: ${(props) => props.alignItems};
`;

interface FlexItemProps {
  readonly display?: 'flex' | 'inline-flex' | 'block' | 'inline-block';
  readonly flex?: number;
}

const FlexItem = styled.div<FlexItemProps>`
  display: ${(props) => props.display || 'block'};
  flex: ${(props) => props.flex || 'auto'};
`;

const Flex = { Container: FlexContainer, Item: FlexItem };

export default Flex;
