"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pair = void 0;
const common_utils_1 = require("@robotrader/common-utils");
class Pair {
    constructor(props) {
        this.id = props.id;
        this.name = props.name;
        this.exchange = props.exchange;
        this.createdAt = props.createdAt;
        this.firstOpenDate = props.firstOpenDate;
        this.quoteCurrency = props.quoteCurrency;
        this.riskLimitAmount = props.riskLimitAmount;
        this.riskLimitAmount = props.riskLimitAmount;
        this.turnoverOf24h = props.turnoverOf24h;
        this.lastCandlesUpdate = props.lastCandlesUpdate;
        this.enabled = props.enabled;
    }
    static create(props) {
        return new Pair(props);
    }
    get lastCandlesUpdateTime() {
        return this.lastCandlesUpdate
            ? (0, common_utils_1.dayjs)(this.lastCandlesUpdate).format('DD/MM/YY HH:mm:ss ZZ[Z]')
            : undefined;
    }
    get firstOpenDateTime() {
        return this.lastCandlesUpdate
            ? (0, common_utils_1.dayjs)(this.firstOpenDate).format('DD/MM/YY ZZ[Z]')
            : undefined;
    }
}
exports.Pair = Pair;
