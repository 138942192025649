import { TradingSignalI } from '@robotrader/common-types';
import { dayjs } from '@robotrader/common-utils';

import { Flex, Text } from '@/atoms';
import Colors from '@/Colors';

const CandleCreatedDates = (ts: TradingSignalI) => {
  const { createdAt, date } = ts;

  const formatDate = (d: Date) => dayjs(d).format('DD/MM/YY HH:mm:ss ZZ[Z]');

  return (
    <Flex.Container flexDirection="column" gap="0.5rem">
      <Text.Label color={Colors.white}>{formatDate(date)}</Text.Label>
      <Text.Label color={Colors.grey}>{formatDate(createdAt)}</Text.Label>
    </Flex.Container>
  );
};

export default CandleCreatedDates;
