import React from 'react';

import ReactDOM from 'react-dom/client';

import App from './app/App';
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// import ContainerProvider from '@/app/ContainerProvider';
// import { container } from '@/di/container';

// import './index.scss';

// root.render(
//   <React.StrictMode>
//     <ContainerProvider container={container}>
//       <App />
//     </ContainerProvider>
//   </React.StrictMode>,
// );
