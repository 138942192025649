import { createRef } from 'react';

import { generateRandomPassword } from '@robotrader/common-utils';
import { Button, ButtonLoading, Colors, Flex, Icon, Input, Text } from '@robotrader/design-system';
import { toast } from 'react-toastify';

interface UserChangePasswordFormProps {
  submit: (props: { password: string }) => void;
  loading?: boolean;
}

const UserChangePasswordForm = (props: UserChangePasswordFormProps) => {
  const { submit, loading } = props;
  const passwordRef = createRef<HTMLInputElement>();

  const onSubmit = () => {
    if (passwordRef.current === null) {
      toast.error('Password must be provided');
      return;
    }

    const { value } = passwordRef.current;

    if (value.length < 8) {
      toast.error('Password must be at least 8 characters');
      return;
    }

    submit({ password: value });
  };

  const proposePassword = () => {
    if (passwordRef.current === null) return;
    const randomstring = generateRandomPassword(12);

    passwordRef.current.value = randomstring;
  };

  return (
    <Flex.Container>
      <Text.Span style={{ flex: 1 }}>Password</Text.Span>
      <Flex.Container style={{ flex: 1 }}>
        <Input.Text
          style={{ marginRight: '1rem', flex: 1 }}
          min={1}
          max={10}
          ref={passwordRef}
          placeholder="password"
        />
        <Button
          $size="sm"
          $variant="secondary"
          onClick={proposePassword}
          style={{ marginRight: '1rem' }}
        >
          <Icon.Repeat color={Colors.white} size="lg" />
        </Button>
        <ButtonLoading $size="sm" loading={loading} onClick={onSubmit}>
          <Text.Span fontWeight={600}>Change Password</Text.Span>
        </ButtonLoading>
      </Flex.Container>
    </Flex.Container>
  );
};

export default UserChangePasswordForm;
