import { MethodParameters } from '@robotrader/common-types';
import { DataError, Either, UseCase } from '@robotrader/core-lib';

import {
  GetAllMethodParametersProps,
  MethodParametersRepository,
} from '@/modules/trader/domain';

interface GetAllMethodParametersUseCaseProps {
  methodParametersRepository: MethodParametersRepository;
}

export class GetAllMethodParametersUseCase
  implements
    UseCase<
      GetAllMethodParametersProps | undefined,
      Either<DataError, MethodParameters[]>
    >
{
  private methodParametersRepository: MethodParametersRepository;

  constructor({
    methodParametersRepository,
  }: GetAllMethodParametersUseCaseProps) {
    this.methodParametersRepository = methodParametersRepository;
  }

  execute(
    filters?: GetAllMethodParametersProps,
  ): Promise<Either<DataError, MethodParameters[]>> {
    return this.methodParametersRepository.getAll(filters);
  }
}
