import { Method, UserId } from '@robotrader/common-types';
import { DataError, Either } from '@robotrader/core-lib';

import { AppConfig } from '@/core/config';
import { HTTPService } from '@/modules/shared';
import {
  CreateUserProps,
  GetAllUsersProps,
  User,
  UserRepository,
} from '@/modules/user/domain';

import { GetUserResponse } from './GetUserResponse';
import { GetUsersResponse } from './GetUsersResponse';
import { PostUserResponse } from './PostUserResponse';
import { UserMapper } from './UserMapper';

interface UserHTTPRepositoryProps {
  httpService: HTTPService;
  config: AppConfig;
}

export class UserHTTPRepository implements UserRepository {
  private http: HTTPService;
  private config: AppConfig;

  constructor({ httpService, config }: UserHTTPRepositoryProps) {
    this.http = httpService;
    this.config = config;
  }

  async editUserLeverage(
    userId: UserId,
    leverage: number,
  ): Promise<Either<DataError, void>> {
    try {
      await this.http.patch<PostUserResponse>(
        `${this.config.apiUrl}/admin/user/${userId}/leverage`,
        { leverage },
      );

      return Either.right(undefined as void);
    } catch (error) {
      return Either.left({ kind: 'Unauthorized', message: error as Error });
    }
  }

  async editUserMethod(
    userId: UserId,
    method: Method,
  ): Promise<Either<DataError, void>> {
    try {
      await this.http.patch<PostUserResponse>(
        `${this.config.apiUrl}/admin/user/${userId}/method`,
        { method },
      );

      return Either.right(undefined as void);
    } catch (error) {
      return Either.left({ kind: 'Unauthorized', message: error as Error });
    }
  }

  async removeUserMethod(userId: UserId): Promise<Either<DataError, void>> {
    try {
      await this.http.delete<PostUserResponse>(
        `${this.config.apiUrl}/admin/user/${userId}/method`,
      );

      return Either.right(undefined as void);
    } catch (error) {
      return Either.left({ kind: 'Unauthorized', message: error as Error });
    }
  }

  async getAll(props?: GetAllUsersProps): Promise<Either<DataError, User[]>> {
    const { method } = props || {};

    try {
      let {
        data: { data: usersDto },
      } = await this.http.get<GetUsersResponse>(
        `${this.config.apiUrl}/admin/user`,
      );

      usersDto = usersDto
        .filter((uDto) =>
          method !== undefined ? uDto.profile.method === method : true,
        )
        .sort((uDto1, uDto2) => uDto1.id - uDto2.id);

      return Either.right(UserMapper.userDtoArrayToUserArray(usersDto));
    } catch (error) {
      return Either.left({ kind: 'Unauthorized', message: error as Error });
    }
  }

  async getUser(userId: UserId): Promise<Either<DataError, User>> {
    try {
      const {
        data: { data: userDto },
      } = await this.http.get<GetUserResponse>(
        `${this.config.apiUrl}/admin/user/${userId}`,
      );

      return Either.right(UserMapper.userDtoToUser(userDto));
    } catch (error) {
      return Either.left({ kind: 'Unauthorized', message: error as Error });
    }
  }

  async deleteUser(userId: UserId): Promise<Either<DataError, void>> {
    try {
      await this.http.delete(`${this.config.apiUrl}/admin/user/${userId}`);

      return Either.right(undefined as void);
    } catch (error) {
      return Either.left({ kind: 'Unauthorized', message: error as Error });
    }
  }

  async createUser(props: CreateUserProps): Promise<Either<DataError, User>> {
    const { username, password, name, surname } = props;

    try {
      const {
        data: { data: userDto },
      } = await this.http.post<PostUserResponse>(
        `${this.config.apiUrl}/admin/user`,
        { email: username, password, name, surname },
      );

      return Either.right(UserMapper.userDtoToUser(userDto));
    } catch (error) {
      return Either.left({ kind: 'Unauthorized', message: error as Error });
    }
  }
}
