import {
  Action,
  CandleId,
  Direction,
  ExchangeName,
  ExitReason,
  Interval,
  Method,
  PairName,
  TradingSignalI,
  TradingSignalId,
} from '@robotrader/common-types';

export class TradingSignal {
  id: TradingSignalId;
  candleId: CandleId;
  method: Method;
  exchange: ExchangeName;
  pair: PairName;
  interval: Interval;
  timestamp: number;
  date: Date;
  action: Action;
  direction: Direction;
  checkpoint: boolean;
  price: number;
  prevPrice?: number;
  stopPrice?: number;
  outcome?: number;
  fee?: number;
  accum: number;
  exitReason?: ExitReason;
  createdAt: Date;

  private constructor(props: TradingSignalI) {
    this.id = props.id;
    this.candleId = props.candleId;
    this.method = props.method;
    this.exchange = props.exchange;
    this.pair = props.pair;
    this.interval = props.interval;

    if (typeof props.timestamp === 'string') {
      this.timestamp = parseInt(props.timestamp, 10);
    } else {
      this.timestamp = props.timestamp;
    }

    this.date = props.date;
    this.action = props.action;
    this.direction = props.direction;
    this.checkpoint = props.checkpoint;
    this.price = props.price;
    this.prevPrice = props.prevPrice;
    this.stopPrice = props.stopPrice;
    this.outcome = props.outcome;
    this.fee = props.fee;
    this.accum = props.accum;
    this.exitReason = props.exitReason;
    this.createdAt = props.createdAt;
  }

  static create(props: TradingSignalI): TradingSignal {
    return new TradingSignal(props);
  }
}
