import { useEffect, useState } from 'react';

import { UserId } from '@robotrader/common-types';
import { stringToNumber } from '@robotrader/common-utils';
import {
  Block,
  LoadingBlock,
  NavigationTabs,
  NavigationTabsRouteObject,
  Select,
  Text,
} from '@robotrader/design-system';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { useCradle } from '@/app/contexts';
import { useIsMounted } from '@/app/hooks';
import { EditUser, UserExchangeInformation } from '@/app/ui';
import { Cradle } from '@/di/Cradle';
import { User } from '@/modules/user/domain';

const UserPage = () => {
  const { userBloc } = useCradle<Cradle>();
  const isMounted = useIsMounted();
  const { userId } = useParams();
  const [user, setUser] = useState<User | undefined>();
  const [users, setUsers] = useState<User[] | undefined>(undefined);
  const navigate = useNavigate();

  const loadUser = (uId: UserId) => {
    userBloc.getUser(uId).then(setUser);
  };

  const routes: NavigationTabsRouteObject[] = [
    { index: true, element: <Navigate to="profile" /> },
    {
      path: 'profile',
      element: <EditUser />,
      label: 'Profile Info',
    },
    {
      path: 'exchange-info',
      // eslint-disable-next-line react/no-unstable-nested-components, react/jsx-no-useless-fragment
      element: <UserExchangeInformation />,
      label: 'Exchange Info',
    },
    { path: '*', element: <Navigate to="/not-found" replace /> },
  ];

  useEffect(() => {
    if (!isMounted()) return;

    if (userId && !Number.isNaN(userId)) {
      setUser(undefined);
      loadUser(Number(userId));
    }
  }, [isMounted, userId]);

  useEffect(() => {
    if (!isMounted()) return;

    userBloc.getAll().then(setUsers);
  }, [isMounted]);

  if (user === undefined) {
    return <LoadingBlock loading text="Loading user..." />;
  }

  return (
    <>
      <Text.H3 style={{ margin: '1rem 0' }}>
        User:
        <Block $inline style={{ marginLeft: '1rem' }}>
          <Select
            placeholder="User"
            hideSelectedOptions
            value={users
              ?.filter((u) => userId !== undefined && u.id === stringToNumber(userId))
              .map((e) => ({
                value: `${e.id}`,
                label: `${e.profile.name} ${e.profile.surname}`,
              }))}
            options={
              users
                ? users.map((e) => ({
                    value: `${e.id}`,
                    label: `${e.profile.name} ${e.profile.surname}`,
                  }))
                : []
            }
            onChange={(newValue) => {
              const { value } = (newValue as any) || {};

              if (value) {
                navigate(`/users/${value}`);
              }
            }}
          />
        </Block>
      </Text.H3>
      <NavigationTabs
        routes={routes}
        style={{ flex: 1, marginTop: '1rem', marginBottom: '1rem', backgroundColor: 'transparent' }}
        containerStyle={{ paddingTop: '1rem' }}
      />
      {/* <Tabs.Container
        style={{ flex: 1, marginTop: '1rem', backgroundColor: 'transparent' }}
        tabs={tabs}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      >
        {checkCurrentTabIs(TABS.PROFILE_INFO) && (
          <Tabs.Item style={{ overflow: 'auto', marginTop: '1rem' }}>
            <EditUser user={user} onSuccess={() => loadUser(user.id)} />
          </Tabs.Item>
        )}
        {checkCurrentTabIs(TABS.EXCHANGE_INFO) && (
          <Tabs.Item style={{ marginTop: '1rem' }}>
            <UserExchangeInformation user={user} />
          </Tabs.Item>
        )}
      </Tabs.Container> */}
    </>
  );
};

export default UserPage;
