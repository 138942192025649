import { useEffect, useState } from 'react';

import { dayjs } from '@robotrader/common-utils';

import { Text } from '@/atoms';

const SimpleSecondsCountDown = ({ seconds }: { seconds: number }) => {
  const now = dayjs();
  const [targetDate] = useState<dayjs.Dayjs>(now.add(seconds, 'seconds'));
  const [secondsLeft, setSecondsLeft] = useState<number>(targetDate.diff(now, 'seconds'));

  useEffect(() => {
    setSecondsLeft(seconds);

    const interval = setInterval(() => {
      const secsDiff = targetDate.diff(dayjs(), 'seconds');

      if (secsDiff <= 0) {
        setSecondsLeft(0);
        clearInterval(interval);
      }

      setSecondsLeft(secsDiff);
    }, 100);

    return () => clearInterval(interval);
  }, []);

  return <Text.Span>{secondsLeft ? `${secondsLeft} seconds` : 'No time left'}</Text.Span>;
};

export default SimpleSecondsCountDown;
