import React, { createElement } from 'react';

import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components/macro';

import { Text } from '@/atoms';
import Colors from '@/Colors';

import Icon from './Icons';

const LogoLink = (props: LinkProps & React.RefAttributes<HTMLAnchorElement>) =>
  createElement(Link, props, <Icon.Logo width={45} height="auto" />);

const LogoLinkAdmin = (props: LinkProps & React.RefAttributes<HTMLAnchorElement>) =>
  createElement(
    Link,
    props,
    <>
      <Icon.Logo width={45} height="auto" />
      <Text.Small style={{ marginLeft: '1rem', verticalAlign: 'middle', color: Colors.red }}>
        Admin
      </Text.Small>
    </>,
  );

const NavLogo = styled(LogoLink)`
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 2.5rem;
  font-weight: 800;
  transition: all 0.5s ease;
  &:hover {
    transform: scale(1.08);
  }
`;

const NavLogoAdmin = styled(LogoLinkAdmin)`
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 2.5rem;
  font-weight: 800;
  transition: all 0.5s ease;
  &:hover {
    transform: scale(1.08);
  }
`;

const NavLogos = {
  NavLogo,
  NavLogoAdmin,
};

export default NavLogos;
