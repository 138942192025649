import { NavigationTabs, NavigationTabsRouteObject } from '@robotrader/design-system';
import { Navigate } from 'react-router-dom';

import JobsInfo from './JobsInfo';
import TraderInfo from './TraderInfo';

const routes: NavigationTabsRouteObject[] = [
  { index: true, element: <Navigate to="trader" /> },
  { path: 'trader', element: <TraderInfo />, label: 'Trader Info' },
  { path: 'jobs', element: <JobsInfo />, label: 'Jobs Info' },
  { path: '*', element: <Navigate to="/not-found" replace /> },
];

const RobotStatePage = () => (
  <NavigationTabs
    routes={routes}
    style={{ flex: 1, marginTop: '1rem', backgroundColor: 'transparent' }}
    containerStyle={{ paddingTop: '1rem' }}
  />
);

export default RobotStatePage;
