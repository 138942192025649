import { RouteObject, useRoutes } from 'react-router-dom';
import styled from 'styled-components/macro';

import { Flex, RouterLink, Text } from '@/atoms';
import Colors from '@/Colors';

const isObjectIndex = (x: any): x is NavigationTabsRouteObjectIndex => x.index;

type NavigationTabsRouteObjectIndex = Omit<RouteObject, 'children'> & {
  index: true;
};
type NavigationTabsRouteObjectNoIndex = Omit<RouteObject, 'children' | 'index'> & {
  path: string;
  label?: string;
};
export type NavigationTabsRouteObject =
  | NavigationTabsRouteObjectIndex
  | NavigationTabsRouteObjectNoIndex;

interface TabTitleProps {
  route: NavigationTabsRouteObject;
}

const TabTitle = (props: TabTitleProps) => {
  const { route } = props;

  if (isObjectIndex(route) || route.label === undefined) {
    return null;
  }

  return (
    <RouterLink
      to={route.path}
      $transparent
      style={({ isActive }) => ({
        paddingBottom: '1rem',
        borderRadius: 0,
        borderBottom: isActive ? `2px solid ${Colors.white}` : 'none',
      })}
    >
      <Text.Span fontWeight={600}>{route.label}</Text.Span>
    </RouterLink>
  );
};

const Navigator = ({ routes }: { routes: NavigationTabsRouteObject[] }) => {
  const Router = useRoutes(routes);

  return Router;
};

interface TabsProps {
  routes: NavigationTabsRouteObject[];
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  className?: string;
}

const TabsComponent = (props: TabsProps) => {
  const { routes, className, style, containerStyle } = props;

  return (
    <div className={className} style={style}>
      <Flex.Container
        className="TabTitles"
        style={{
          backgroundColor: Colors.notSoBlack,
          paddingTop: '2rem',
          paddingLeft: '2rem',
          borderTopLeftRadius: '6px',
          borderTopRightRadius: '6px',
          gap: '3rem',
        }}
      >
        <>
          {routes.map((route, index) => (
            <TabTitle route={route} key={route.path || `index-${index}`} />
          ))}
        </>
      </Flex.Container>
      <div
        className="TabsContainer"
        style={{
          backgroundColor: style?.backgroundColor || Colors.grey2,
          ...containerStyle,
        }}
      >
        <Navigator routes={routes} />
      </div>
    </div>
  );
};

const NavigationTabs = styled(TabsComponent)``;

export default NavigationTabs;
