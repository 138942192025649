import { useEffect } from 'react';

import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { useCradle } from '@/app/contexts';
import { useBlocState } from '@/app/hooks';
import { Cradle } from '@/di/Cradle';

// eslint-disable-next-line import/prefer-default-export
export const AuthRoute = ({ children }: { children: JSX.Element }) => {
  const { authBloc } = useCradle<Cradle>();
  const authState = useBlocState(authBloc);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (authState.kind === 'NotLoggedAuthState' || authState.kind === 'ErrorAuthState') {
      navigate('/auth/login', { replace: true });
    }
  }, [authState]);

  if (authState.kind === 'NotLoggedAuthState' || authState.kind === 'ErrorAuthState') {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  if (authState.kind === 'LoadingAuthState' || authState.kind === 'InitialAuthState') {
    return null;
  }

  return children;
};

// eslint-disable-next-line import/prefer-default-export
export const NotAuthRoute = ({ children }: { children: JSX.Element }) => {
  const { authBloc } = useCradle<Cradle>();
  const authState = useBlocState(authBloc);
  const location = useLocation();

  if (authState.kind === 'LoadedAuthState') {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.

    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};
