import { createRef, useEffect, useState } from 'react';

import { ExchangeName } from '@robotrader/common-types';
import { dayjs } from '@robotrader/common-utils';
import { Block, Button, ButtonLoading, Flex, Icon, Input, Text } from '@robotrader/design-system';
import { toast } from 'react-toastify';

import { useConfig } from '@/app/hooks';
import { MultiScalperV1UseCaseProps } from '@/modules/trader';

interface MultiscalpingSimulationFormProps {
  submit: (props: MultiScalperV1UseCaseProps) => void;
  loading?: boolean;
  exchangeName: ExchangeName;
}

const MultiscalpingV1SimulationForm = (props: MultiscalpingSimulationFormProps) => {
  const { submit, loading, exchangeName } = props;
  const config = useConfig();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const dateFromRef = createRef<HTMLInputElement>();
  const dateToRef = createRef<HTMLInputElement>();
  const ema8MagicDeltaRef = createRef<HTMLInputElement>();
  const rsiDeltaShapeRef = createRef<HTMLInputElement>();
  const targetDistanceEma21Ref = createRef<HTMLInputElement>();
  const targetDistanceEma8Ref = createRef<HTMLInputElement>();
  const rsiPeriodRef = createRef<HTMLInputElement>();
  const rsiHighRef = createRef<HTMLInputElement>();
  const rsiHighEdgeRef = createRef<HTMLInputElement>();
  const rsiLowRef = createRef<HTMLInputElement>();
  const rsiLowEdgeRef = createRef<HTMLInputElement>();
  const dropRateRef = createRef<HTMLInputElement>();
  const stopMarginRef = createRef<HTMLInputElement>();
  const goalMarginRef = createRef<HTMLInputElement>();
  const acceptedCandleVolatilityRef = createRef<HTMLInputElement>();
  const feeRef = createRef<HTMLInputElement>();

  useEffect(() => {
    if (
      !dateFromRef.current ||
      !dateToRef.current ||
      !ema8MagicDeltaRef.current ||
      !rsiDeltaShapeRef.current ||
      !targetDistanceEma21Ref.current ||
      !targetDistanceEma8Ref.current ||
      !rsiPeriodRef.current ||
      !rsiHighRef.current ||
      !rsiHighEdgeRef.current ||
      !rsiLowRef.current ||
      !rsiLowEdgeRef.current ||
      !dropRateRef.current ||
      !stopMarginRef.current ||
      !goalMarginRef.current ||
      !acceptedCandleVolatilityRef.current ||
      !feeRef.current
    ) {
      return;
    }

    const today = dayjs();
    dateFromRef.current.value = today
      .subtract(config.development ? 1 : 12, 'months')
      .format('YYYY-MM-DD');
    dateToRef.current.value = today.format('YYYY-MM-DD');
    ema8MagicDeltaRef.current.value = '0';
    rsiDeltaShapeRef.current.value = '5';
    targetDistanceEma21Ref.current.value = '0.03';
    targetDistanceEma8Ref.current.value = '0.01';
    rsiPeriodRef.current.value = '14';
    rsiHighRef.current.value = '70';
    rsiHighEdgeRef.current.value = '60';
    rsiLowRef.current.value = '30';
    rsiLowEdgeRef.current.value = '40';
    dropRateRef.current.value = '0';
    stopMarginRef.current.value = '0.25';
    goalMarginRef.current.value = '0.1';
    acceptedCandleVolatilityRef.current.value = '0.05';
    feeRef.current.value = '0.04';
  }, []);

  const onSubmit = () => {
    const dateFrom = dateFromRef.current ? dateFromRef.current.value : undefined;
    const dateTo = dateToRef.current ? dateToRef.current.value : undefined;
    const ema8MagicDelta =
      ema8MagicDeltaRef.current !== null
        ? Number.parseFloat(ema8MagicDeltaRef.current.value)
        : undefined;
    const rsiDeltaShape =
      rsiDeltaShapeRef.current !== null
        ? Number.parseFloat(rsiDeltaShapeRef.current.value)
        : undefined;
    const targetDistanceEma21 =
      targetDistanceEma21Ref.current !== null
        ? Number.parseFloat(targetDistanceEma21Ref.current.value)
        : undefined;
    const targetDistanceEma8 =
      targetDistanceEma8Ref.current !== null
        ? Number.parseFloat(targetDistanceEma8Ref.current.value)
        : undefined;
    const rsiPeriod =
      rsiPeriodRef.current !== null ? Number.parseFloat(rsiPeriodRef.current.value) : undefined;
    const rsiHigh =
      rsiHighRef.current !== null ? Number.parseFloat(rsiHighRef.current.value) : undefined;
    const rsiHighEdge =
      rsiHighEdgeRef.current !== null ? Number.parseFloat(rsiHighEdgeRef.current.value) : undefined;
    const rsiLow =
      rsiLowRef.current !== null ? Number.parseFloat(rsiLowRef.current.value) : undefined;
    const rsiLowEdge =
      rsiLowEdgeRef.current !== null ? Number.parseFloat(rsiLowEdgeRef.current.value) : undefined;
    const dropRate =
      dropRateRef.current !== null ? Number.parseFloat(dropRateRef.current.value) : undefined;
    const stopMargin =
      stopMarginRef.current !== null ? Number.parseFloat(stopMarginRef.current.value) : undefined;
    const goalMargin =
      goalMarginRef.current !== null ? Number.parseFloat(goalMarginRef.current.value) : undefined;
    const acceptedCandleVolatility =
      acceptedCandleVolatilityRef.current !== null
        ? Number.parseFloat(acceptedCandleVolatilityRef.current.value)
        : undefined;
    const fee = feeRef.current !== null ? Number.parseFloat(feeRef.current.value) : undefined;

    if (
      dateFrom !== undefined &&
      dateTo !== undefined &&
      targetDistanceEma21 !== undefined &&
      targetDistanceEma8 !== undefined &&
      ema8MagicDelta !== undefined &&
      rsiDeltaShape !== undefined &&
      rsiPeriod !== undefined &&
      rsiHigh !== undefined &&
      rsiLow !== undefined &&
      rsiHighEdge !== undefined &&
      rsiLowEdge !== undefined &&
      dropRate !== undefined &&
      stopMargin !== undefined &&
      goalMargin !== undefined &&
      acceptedCandleVolatility !== undefined
    ) {
      submit({
        exchange: exchangeName,
        dateFrom,
        dateTo,
        targetDistanceEma21,
        targetDistanceEma8,
        ema8MagicDelta,
        rsiDeltaShape,
        rsiPeriod,
        rsiHigh,
        rsiLow,
        rsiHighEdge,
        rsiLowEdge,
        dropRate,
        stopMargin,
        goalMargin,
        acceptedCandleVolatility,
        fee,
      });
    } else {
      toast.error('You must provide all the fields');
    }
  };

  return (
    <>
      <Flex.Container style={{ marginBottom: isCollapsed ? '0' : '2rem' }}>
        <Text.H3>Simulation Form</Text.H3>
        <Button
          $transparent
          style={{ marginLeft: 'auto' }}
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <Text.Span fontWeight={700}>
            {isCollapsed ? <Icon.ArrowDown /> : <Icon.ArrowUp />}
          </Text.Span>
        </Button>
      </Flex.Container>
      <Block style={{ display: isCollapsed ? 'none' : 'initial' }}>
        <Flex.Container>
          <Flex.Item
            flex={1}
            style={{ flexDirection: 'column', display: 'flex', margin: '0.5rem 0.5rem' }}
          >
            <Flex.Container style={{ marginBottom: '1rem' }}>
              <Text.Span style={{ marginRight: '1rem', flex: 1 }}>Date From</Text.Span>
              <Input.Date style={{ flex: 1 }} ref={dateFromRef} placeholder="YYYY/MM/DD" />
            </Flex.Container>
            <Flex.Container style={{ marginBottom: '1rem' }}>
              <Text.Span style={{ flex: 1, marginRight: '1rem' }}>Date To</Text.Span>
              <Input.Date style={{ flex: 1 }} ref={dateToRef} placeholder="YYYY/MM/DD" />
            </Flex.Container>
            <Flex.Container style={{ marginBottom: '1rem' }}>
              <Text.Span style={{ flex: 1, marginRight: '1rem' }}>RSI Period</Text.Span>
              <Input.Number style={{ flex: 1 }} min={1} max={100} step={1} ref={rsiPeriodRef} />
            </Flex.Container>
            <Flex.Container style={{ marginBottom: '1rem' }}>
              <Text.Span style={{ flex: 1, marginRight: '1rem' }}>T.D. EMA21</Text.Span>
              <Input.Number
                style={{ flex: 1 }}
                min={0.001}
                max={1}
                step={0.001}
                ref={targetDistanceEma21Ref}
              />
            </Flex.Container>
            <Flex.Container style={{ marginBottom: '1rem' }}>
              <Text.Span style={{ flex: 1, marginRight: '1rem' }}>T.D. EMA8</Text.Span>
              <Input.Number
                style={{ flex: 1 }}
                min={0.001}
                max={1}
                step={0.001}
                ref={targetDistanceEma8Ref}
              />
            </Flex.Container>
            <Flex.Container style={{ marginBottom: '1rem' }}>
              <Text.Span style={{ flex: 1, marginRight: '1rem' }}>EMA8 Delta</Text.Span>
              <Input.Number
                style={{ flex: 1 }}
                min={-1}
                max={1}
                step={0.001}
                ref={ema8MagicDeltaRef}
              />
            </Flex.Container>
          </Flex.Item>
          <Flex.Item
            flex={1}
            style={{ flexDirection: 'column', display: 'flex', margin: '0.5rem 0.5rem' }}
          >
            <Flex.Container style={{ marginBottom: '1rem' }}>
              <Text.Span style={{ flex: 1, marginRight: '1rem' }}>RSI Delta Shape</Text.Span>
              <Input.Number style={{ flex: 1 }} min={1} max={100} step={1} ref={rsiDeltaShapeRef} />
            </Flex.Container>

            <Flex.Container style={{ marginBottom: '1rem' }}>
              <Text.Span style={{ flex: 1, marginRight: '1rem' }}>RSI High</Text.Span>
              <Input.Number style={{ flex: 1 }} min={1} max={100} step={1} ref={rsiHighRef} />
            </Flex.Container>

            <Flex.Container style={{ marginBottom: '1rem' }}>
              <Text.Span style={{ flex: 1, marginRight: '1rem' }}>RSI High Edge</Text.Span>
              <Input.Number style={{ flex: 1 }} min={1} max={100} step={1} ref={rsiHighEdgeRef} />
            </Flex.Container>

            <Flex.Container style={{ marginBottom: '1rem' }}>
              <Text.Span style={{ flex: 1, marginRight: '1rem' }}>RSI Low</Text.Span>
              <Input.Number style={{ flex: 1 }} min={1} max={100} step={1} ref={rsiLowRef} />
            </Flex.Container>

            <Flex.Container style={{ marginBottom: '1rem' }}>
              <Text.Span style={{ flex: 1, marginRight: '1rem' }}>RSI Low Edge</Text.Span>
              <Input.Number style={{ flex: 1 }} min={1} max={100} step={1} ref={rsiLowEdgeRef} />
            </Flex.Container>
          </Flex.Item>
          <Flex.Item
            flex={1}
            style={{ flexDirection: 'column', display: 'flex', margin: '0.5rem 0.5rem' }}
          >
            <Flex.Container style={{ marginBottom: '1rem' }}>
              <Text.Span style={{ flex: 1, marginRight: '1rem' }}>Stop Margin</Text.Span>
              <Input.Number
                style={{ flex: 1 }}
                min={0.01}
                max={1}
                step={0.01}
                ref={stopMarginRef}
              />
            </Flex.Container>
            <Flex.Container style={{ marginBottom: '1rem' }}>
              <Text.Span style={{ flex: 1, marginRight: '1rem' }}>Goal Margin</Text.Span>
              <Input.Number
                style={{ flex: 1 }}
                min={0.01}
                max={1}
                step={0.01}
                ref={goalMarginRef}
              />
            </Flex.Container>
            <Flex.Container style={{ marginBottom: '1rem' }}>
              <Text.Span style={{ flex: 1, marginRight: '1rem' }}>Drop Rate</Text.Span>
              <Input.Number style={{ flex: 1 }} min={0.01} max={1} step={0.01} ref={dropRateRef} />
            </Flex.Container>
            <Flex.Container style={{ marginBottom: '1rem' }}>
              <Text.Span style={{ flex: 1, marginRight: '1rem' }}>Candle Volatility</Text.Span>
              <Input.Number
                style={{ flex: 1 }}
                min={0.01}
                max={1}
                step={0.01}
                ref={acceptedCandleVolatilityRef}
              />
            </Flex.Container>
            <Flex.Container style={{ marginBottom: '1rem' }}>
              <Text.Span style={{ flex: 1, marginRight: '1rem' }}>Op. Fee (%)</Text.Span>
              <Input.Number style={{ flex: 1 }} min={0.01} max={100} step={0.01} ref={feeRef} />
            </Flex.Container>
          </Flex.Item>
        </Flex.Container>
        <Flex.Container>
          <Flex.Item
            style={{
              display: 'flex',
              margin: '0.5rem 0.5rem',
              alignItems: 'end',
              justifyContent: 'end',
            }}
          >
            <ButtonLoading $size="md" loading={loading} onClick={onSubmit}>
              <Text.Span fontWeight={600}>Simulate</Text.Span>
            </ButtonLoading>
          </Flex.Item>
        </Flex.Container>
      </Block>
    </>
  );
};

export default MultiscalpingV1SimulationForm;
