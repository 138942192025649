import { UserId } from '@robotrader/common-types';
import { DataError, Either, UseCase } from '@robotrader/core-lib';

import {
  GetAllOperationsFilters,
  Operation,
  OperationRepository,
} from '@/modules/trader/domain';

interface GetAllOperationsProps {
  userId?: UserId;
  filters?: GetAllOperationsFilters;
  flat?: boolean;
  skipTradingSignal?: boolean;
}

interface GetAllOperationsUseCaseProps {
  operationRepository: OperationRepository;
}

export class GetAllOperationsUseCase
  implements UseCase<GetAllOperationsProps, Either<DataError, Operation[]>>
{
  private operationRepository: OperationRepository;

  constructor({ operationRepository }: GetAllOperationsUseCaseProps) {
    this.operationRepository = operationRepository;
  }

  execute(
    props: GetAllOperationsProps,
  ): Promise<Either<DataError, Operation[]>> {
    const { userId, filters, flat } = props;

    if (userId !== undefined) {
      return this.operationRepository.getAllUserOperations(userId, filters);
    }

    return this.operationRepository.getAll(filters, flat);
  }
}
