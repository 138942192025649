import React from 'react';

import styled from 'styled-components/macro';

import { Block, Text } from '@/atoms';

import Icon from './Icons';

interface LoadingBlockLoadingProps {
  loading: boolean;
  text?: string;
}

interface LoadingBlockMessageProps {
  loading?: boolean;
  text: string;
}

type LoadingBlockProps = (LoadingBlockMessageProps | LoadingBlockLoadingProps) & {
  style?: React.CSSProperties;
};

const LoadingBlock = (props: LoadingBlockProps) => {
  const { style, loading, text } = props;

  return (
    <Block style={{ textAlign: 'center', ...style }}>
      {loading && <Icon.Spinner width={32} />}
      {text && <Text.Paragraph>{text}</Text.Paragraph>}
    </Block>
  );
};

export default styled(LoadingBlock)``;
