import { Email, Password } from '@robotrader/common-types';
import { DataError, Either, UseCase } from '@robotrader/core-lib';

import { Auth, AuthRepository } from '@/modules/auth/domain';

interface LoginProps {
  email: Email;
  password: Password;
}

interface LoginUseCaseProps {
  authRepository: AuthRepository;
}

export class LoginUseCase
  implements UseCase<LoginProps, Either<DataError, Auth>>
{
  private authRepository: AuthRepository;

  constructor({ authRepository }: LoginUseCaseProps) {
    this.authRepository = authRepository;
  }

  execute(props: LoginProps): Promise<Either<DataError, Auth>> {
    const { email, password } = props;
    return this.authRepository.logIn(email, password);
  }
}
