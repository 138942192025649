import { Exchange, ExchangeDTO } from '@robotrader/common-types';

export class ExchangeMapper {
  static exchangeDtoArrayToExchangeArray(exDtos: ExchangeDTO[]): Exchange[] {
    return exDtos.map(ExchangeMapper.exchangeDtoToExchange);
  }

  static exchangeDtoToExchange(exDto: ExchangeDTO): Exchange {
    return Exchange.create(exDto);
  }
}
