import { LoadingBlock } from '@robotrader/design-system';
import { useParams } from 'react-router-dom';

import { PairView } from '@/app/ui';

const PairPage = () => {
  const { pairId } = useParams();

  if (pairId === undefined) {
    return <LoadingBlock loading text="No pair provided..." />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{pairId && <PairView pairId={Number(pairId)} showCandles />}</>;
};

export default PairPage;
