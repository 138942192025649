import styled from 'styled-components/macro';

const Main = styled.main`
  height: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;

export default Main;
