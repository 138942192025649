import { OperationI } from '@robotrader/common-types';
import { humanizeNumber } from '@robotrader/common-utils';

import { Flex, Text } from '@/atoms';
import Colors from '@/Colors';

const EntryExitPrice = (o: OperationI) => {
  const { price, closingOperation } = o;

  return (
    <Flex.Container flexDirection="column" gap="0.5rem">
      <Text.Label color={Colors.white}>E: {humanizeNumber(price)}</Text.Label>
      {closingOperation && (
        <Text.Label color={Colors.white}>S: {humanizeNumber(closingOperation.price)}</Text.Label>
      )}
    </Flex.Container>
  );
};

export default EntryExitPrice;
