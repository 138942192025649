import { useEffect, useState } from 'react';

import { Action, Exchange, Pair } from '@robotrader/common-types';
import { roundNumber } from '@robotrader/common-utils';
import { Flex, Icon, LoadingBlock, WidgetCounter } from '@robotrader/design-system';

import { useCradle } from '@/app/contexts';
import { useIsMounted } from '@/app/hooks';
import { Cradle } from '@/di/Cradle';
import { Operation, TradingSignal } from '@/modules/trader';
import { User } from '@/modules/user';

const HomeWidgetContainer = ({ children }: { children: React.ReactNode }) => (
  // <Flex.Item style={{ width: 'calc(25% - 2rem)', minWidth: 220 }}>{children}</Flex.Item>
  <Flex.Item style={{ minWidth: 220 }}>{children}</Flex.Item>
  // <Flex.Item>{children}</Flex.Item>
);

const HomePage = () => {
  const { userBloc, traderBloc } = useCradle<Cradle>();
  const isMounted = useIsMounted();
  const [users, setUsers] = useState<User[] | undefined>(undefined);
  const [tradingSignals, setTradingSignals] = useState<TradingSignal[] | undefined>(undefined);
  const [operations, setOperations] = useState<Operation[] | undefined>(undefined);
  const [exchanges, setExchanges] = useState<Exchange[] | undefined>(undefined);
  const [pairs, setPairs] = useState<Pair[] | undefined>(undefined);

  useEffect(() => {
    if (isMounted()) {
      userBloc.getAll().then(setUsers);
      traderBloc.getTradingSignals().then(setTradingSignals);
      traderBloc
        .getOperations({ flat: true })
        .then((ops) => setOperations(ops.filter((op) => op.action === Action.EXIT)));
      traderBloc.getPairs().then(setPairs);
      traderBloc.getExchanges().then(setExchanges);
    }
  }, [isMounted]);

  if (
    tradingSignals === undefined ||
    users === undefined ||
    operations === undefined ||
    exchanges === undefined ||
    pairs === undefined
  ) {
    return <LoadingBlock loading text="Loading data..." />;
  }

  const outcomes: Array<number> = operations
    .map((op) => op.outcome)
    .filter((val) => val !== undefined) as Array<number>;
  const totalOutcome = outcomes.reduce((prev, curr) => prev + curr, 0);

  return (
    <div
      style={{
        display: 'grid',
        gap: '1rem',
        gridAutoFlow: 'dense',
        // gridAutoRows: '22rem',
        gridTemplateColumns: 'repeat(auto-fill, minmax(220px, 1fr))',
      }}
    >
      <HomeWidgetContainer>
        <WidgetCounter
          label="Global Outcome"
          count={roundNumber(totalOutcome, 2)}
          icon={<Icon.Dollar size="3x" />}
        />
      </HomeWidgetContainer>
      <HomeWidgetContainer>
        <WidgetCounter
          label="Trading Signals"
          count={tradingSignals.length}
          icon={<Icon.Robot size="3x" />}
        />
      </HomeWidgetContainer>
      <HomeWidgetContainer>
        <WidgetCounter
          label="Users"
          count={users.length}
          icon={<Icon.User height={50} width={50} />}
        />
      </HomeWidgetContainer>
      <HomeWidgetContainer>
        <WidgetCounter
          label="Operations"
          count={operations.length}
          icon={<Icon.ArrowTrendUp size="3x" />}
        />
      </HomeWidgetContainer>
      <HomeWidgetContainer>
        <WidgetCounter
          label="Exchanges"
          count={exchanges.length}
          icon={<Icon.Exchanges size="3x" />}
        />
      </HomeWidgetContainer>
      <HomeWidgetContainer>
        <WidgetCounter label="Pairs" count={pairs.length} icon={<Icon.Coins size="3x" />} />
      </HomeWidgetContainer>
    </div>
  );
};

export default HomePage;
