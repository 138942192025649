import { DataError, Either, UseCase } from '@robotrader/core-lib';

import {
  ImportExchangeOrderProps,
  Operation,
  OperationRepository,
} from '@/modules/trader/domain';

interface ImportExchangeOrderUseCaseProps {
  operationRepository: OperationRepository;
}

export class ImportExchangeOrderUseCase
  implements UseCase<ImportExchangeOrderProps, Either<DataError, Operation>>
{
  private operationRepository: OperationRepository;

  constructor({ operationRepository }: ImportExchangeOrderUseCaseProps) {
    this.operationRepository = operationRepository;
  }

  async execute(
    props: ImportExchangeOrderProps,
  ): Promise<Either<DataError, Operation>> {
    return this.operationRepository.importExchangeOrder(props);
  }
}
