import { createRef, useEffect, useState } from 'react';

import {
  Exchange,
  ExchangeName,
  isMethod,
  Method,
  MethodParameters,
  WeekSchedule,
} from '@robotrader/common-types';
import { capitalizeString, dayjs } from '@robotrader/common-utils';
import {
  Block,
  Button,
  Card,
  Colors,
  DynamicTable,
  ExchangeNameAndIcon,
  Flex,
  Input,
  LoadingBlock,
  Select,
  TableObjectProperties,
  Text,
  UpdateTimeInfo,
  WorkingHours,
} from '@robotrader/design-system';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useCradle } from '@/app/contexts';
import { useInterval, useIsMounted } from '@/app/hooks';
import { Cradle } from '@/di/Cradle';
import { EditParametersProps, GetAllMethodParametersProps } from '@/modules/trader';

const PARAM_EXCHANGE_NAME = 'exchangeName';
const PARAM_METHOD = 'method';
const DELAY_TIME = 15000; // milliseconds

const Parameters = (methodParameters: MethodParameters) => {
  const { parameters, exchange, method } = methodParameters;
  const { traderBloc } = useCradle<Cradle>();
  const textRef = createRef<HTMLTextAreaElement>();
  const { id: methodParametersId } = methodParameters;

  const onSave = async () => {
    if (textRef.current === null) return;

    // eslint-disable-next-line no-alert
    const confirm = window.confirm('Confirm edit parameters');

    if (!confirm) return;

    const { value } = textRef.current;

    try {
      const params = JSON.parse(value);

      await traderBloc.editMethodParameters({
        methodParametersId,
        parameters: params,
      });
      toast.success(`Parameters for ${exchange.name} - ${method} saved successfully!`);
    } catch (error: any) {
      if ('error' in error) {
        toast.error(error.error);
      } else if ('message' in error) {
        toast.error(error.message);
      } else {
        toast.error(error);
      }
    }
  };

  return (
    <Flex.Container flexDirection="column" gap="1rem" style={{ minWidth: '250px' }}>
      <Input.Textarea
        ref={textRef}
        style={{ minHeight: 200 }}
        defaultValue={JSON.stringify(parameters, null, 2)}
      />
      <Button $size="sm" style={{ marginLeft: 'auto' }} onClick={onSave}>
        Save
      </Button>
    </Flex.Container>
  );
};

const MethodParametersExchangeIcon = (m: MethodParameters) => {
  const { exchange } = m;
  const [duration, setDuration] = useState<string>();

  useEffect(() => {
    const now = dayjs();
    const dDuration = dayjs.duration(now.diff(exchange.lastCandlesUpdate));

    setDuration(`${dDuration.humanize()} ago`);
  }, [exchange]);

  return (
    <>
      <ExchangeNameAndIcon exchangeName={exchange.name} />
      <Block>{duration}</Block>
    </>
  );
};

const StatusInfo = (props: { mP: MethodParameters; toggleEnabled: (value: boolean) => void }) => {
  const { mP, toggleEnabled } = props;
  const { exchange, lastRun, enabled } = mP;
  const [duration, setDuration] = useState<string>();

  const color = mP.enabled ? Colors.red : Colors.blue;
  const action = mP.enabled ? 'Disable Run' : 'Enable Run';

  useEffect(() => {
    const now = dayjs();
    const dDuration = dayjs.duration(now.diff(lastRun));

    setDuration(`${dDuration.humanize()} ago`);
  }, [exchange]);

  return (
    <>
      <Block>
        <Text.Span color={enabled ? Colors.green : Colors.red}>
          {enabled ? 'Run Enabled' : 'Run Disabled'}
        </Text.Span>
      </Block>
      <Block>{duration}</Block>
      <Button
        style={{ marginTop: '1rem', display: 'inline-block' }}
        $backgroundColor={color}
        $backgroundColorHover={color}
        $size="sm"
        onClick={() => toggleEnabled(!mP.enabled)}
      >
        {action}
      </Button>
    </>
  );
};

const TraderInfo = () => {
  const { traderBloc } = useCradle<Cradle>();
  const isMounted = useIsMounted();
  const [searchParams, setSearchParams] = useSearchParams();
  const [methods, setMethods] = useState<MethodParameters[] | undefined>(undefined);
  const [exchanges, setExchanges] = useState<Exchange[] | undefined>(undefined);
  const [loadMethodParameters, setLoadMethodParameters] = useState<boolean>(false);
  const [isLoadingParemeters, setIsLoadingParameters] = useState<boolean>(false);
  const [selectedMethod, setSelectedMethod] = useState<Method | undefined>(undefined);
  const [exchangeName, setExchangeName] = useState<ExchangeName | undefined>(undefined);

  const editMethodParameters = async (mP: MethodParameters, parameters: EditParametersProps) => {
    try {
      await traderBloc.editMethodParameters({
        methodParametersId: mP.id,
        ...parameters,
      });

      toast.success(`${mP.exchange.name} - ${mP.method} edited successfully!`);
      setLoadMethodParameters(true);
    } catch (error: any) {
      if ('error' in error) {
        toast.error(error.error);
      } else if ('message' in error) {
        toast.error(error.message);
      } else {
        toast.error(error);
      }
    }
  };

  const editSchedule = async (
    mP: MethodParameters,
    parameters: Pick<EditParametersProps, 'schedule'>,
  ) => {
    try {
      await traderBloc.editMethodParameters({
        methodParametersId: mP.id,
        ...parameters,
      });

      setLoadMethodParameters(true);
    } catch (error: any) {
      if ('error' in error) {
        toast.error(error.error);
      } else if ('message' in error) {
        toast.error(error.message);
      } else {
        toast.error(error);
      }
    }
  };

  const getMethodParameters = async (filters?: GetAllMethodParametersProps) => {
    if (isLoadingParemeters) return;

    setIsLoadingParameters(true);

    try {
      await traderBloc.getMethodParameters(filters).then(setMethods);
    } catch (error) {
      // TODO: Handle Error
    } finally {
      setIsLoadingParameters(false);
    }
  };

  const METHOD_PARAMETERS_PROPS: Array<TableObjectProperties<MethodParameters>> = [
    { label: 'Exchange', data: MethodParametersExchangeIcon },
    { label: 'Method', data: (m: MethodParameters) => m.method },
    {
      label: 'Schedule',
      align: 'center',
      // eslint-disable-next-line react/no-unstable-nested-components, @typescript-eslint/no-unused-vars
      data: (mP: MethodParameters) => (
        <WorkingHours
          style={{ minWidth: '280px' }}
          schedule={mP.schedule}
          scheduleChanged={async (schedule: WeekSchedule) => {
            await editSchedule(mP, { schedule });

            setLoadMethodParameters(true);
          }}
        />
      ),
    },
    {
      label: 'Status',
      align: 'center',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (mP: MethodParameters) => (
        <StatusInfo
          mP={mP}
          toggleEnabled={async (value: boolean) => {
            const action = value ? 'Enable Run' : 'Disable Run';
            // eslint-disable-next-line no-alert
            const confirm = window.confirm(`${action} ${mP.exchange.name} - ${mP.method} ?`);

            if (!confirm) return;

            await editMethodParameters(mP, { enabled: value });

            setLoadMethodParameters(true);
          }}
        />
      ),
    },
    {
      label: 'Params',
      data: Parameters,
    },
  ];

  // Disabled due to backend disabled support
  // const stopTrader = async () => {
  //   setLoadingTraderStop(true);

  //   try {
  //     // eslint-disable-next-line no-alert
  //     const confirm = window.confirm(
  //       'This action will stop the trading module. Are you sure you want to continue?',
  //     );

  //     if (!confirm) return;

  //     await traderBloc.stopTrader();
  //     setLoadMethodParameters(true);
  //   } catch (error: any) {
  //     if ('error' in error) {
  //       toast.error(error.error);
  //     } else if ('message' in error) {
  //       toast.error(error.message);
  //     } else {
  //       toast.error(error);
  //     }
  //   }

  //   setLoadingTraderStop(false);
  // };

  useEffect(() => {
    if (!isMounted()) return;

    if (exchanges === undefined) {
      traderBloc.getExchanges().then(setExchanges);
    }
  }, [isMounted]);

  useEffect(() => {
    if (!isMounted()) return;

    const eName = searchParams.get(PARAM_EXCHANGE_NAME);
    const method = searchParams.get(PARAM_METHOD);

    if (eName) {
      setExchangeName(eName);
    } else {
      setExchangeName(undefined);
    }

    if (isMethod(method)) {
      setSelectedMethod(method);
    } else {
      setSelectedMethod(undefined);
    }

    setLoadMethodParameters(true);
  }, [searchParams, isMounted]);

  useEffect(() => {
    if (!loadMethodParameters) return;

    getMethodParameters({ exchangeName, method: selectedMethod }).finally(() => {
      setLoadMethodParameters(false);
    });
  }, [loadMethodParameters, exchangeName, selectedMethod]);

  useInterval(
    () => {
      setLoadMethodParameters(true);
    },
    // Delay in milliseconds or null to stop it
    isMounted() ? DELAY_TIME : null,
  );

  if (methods === undefined || exchanges === undefined) {
    return <LoadingBlock loading text="Loading Trader Info..." />;
  }

  return (
    <>
      <UpdateTimeInfo
        milliseconds={DELAY_TIME}
        textColor={Colors.grey5}
        loading={isLoadingParemeters}
      />
      <Card.Container style={{ marginTop: '1rem' }}>
        <Card.Body>
          <Block $inline>
            <Select
              isClearable
              hideSelectedOptions
              placeholder="Method"
              value={Object.entries(Method)
                .filter((e) => e[1] === selectedMethod)
                .map((e) => ({
                  value: e[1],
                  label: capitalizeString(e[0]),
                }))}
              options={Object.entries(Method).map((e) => ({
                value: e[1],
                label: capitalizeString(e[0]),
              }))}
              onChange={(newValue) => {
                const { value } = (newValue as any) || {};

                if (isMethod(value)) {
                  searchParams.set(PARAM_METHOD, value);
                } else {
                  searchParams.delete(PARAM_METHOD);
                }

                setSearchParams(searchParams);
              }}
            />
          </Block>
          <Block $inline style={{ marginLeft: '1rem' }}>
            <Select
              isClearable
              hideSelectedOptions
              placeholder="Exchange"
              value={exchanges
                .filter((e) => e.name === exchangeName)
                .map((e) => ({ value: e.name, label: capitalizeString(e.name) }))}
              options={
                exchanges
                  ? exchanges.map((e) => ({ value: e.name, label: capitalizeString(e.name) }))
                  : []
              }
              onChange={(newValue) => {
                const { value: selectedExchangeName } = newValue || {};

                if (!selectedExchangeName) {
                  searchParams.delete(PARAM_EXCHANGE_NAME);
                } else {
                  searchParams.set(PARAM_EXCHANGE_NAME, selectedExchangeName);
                }

                setSearchParams(searchParams);
              }}
            />
          </Block>
        </Card.Body>
      </Card.Container>
      <Card.Container style={{ marginTop: '1rem', overflow: 'auto' }}>
        <Card.Body>
          <DynamicTable
            elements={methods}
            showIdColumn
            properties={METHOD_PARAMETERS_PROPS}
            uniqueKeyName="id"
          />
        </Card.Body>
      </Card.Container>
    </>
  );
};

export default TraderInfo;
