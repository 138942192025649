import React, { createRef, useEffect, useRef } from 'react';

import { dayjs } from '@robotrader/common-utils';
import {
  ChartOptions,
  createChart,
  DeepPartial,
  IChartApi,
  ISeriesApi,
  LineData,
  UTCTimestamp,
} from 'lightweight-charts';

import Colors from '@/Colors';

interface OperationObject {
  accum: number;
  timestamp: number;
}

interface EvolutionGraphProps {
  operations: OperationObject[];
  lineColor?: string;
  style?: React.CSSProperties;
}

const CHART_OPTIONS: DeepPartial<ChartOptions> = {
  watermark: {
    visible: true,
    fontSize: 24,
    horzAlign: 'center',
    vertAlign: 'center',
    color: 'rgb(0, 137, 172, 0.3)',
    text: 'Robotrader by Nakima',
  },
  crosshair: {
    mode: 0,
  },
  timeScale: {
    timeVisible: true,
    borderColor: Colors.grey5,
    rightOffset: 1,
    barSpacing: 5,
    // fixLeftEdge: true,
    lockVisibleTimeRangeOnResize: true,
    rightBarStaysOnScroll: true,
    borderVisible: true,
    visible: true,
    secondsVisible: false,
  },
  layout: {
    backgroundColor: Colors.black,
    textColor: Colors.white,
  },
  grid: {
    horzLines: {
      color: Colors.grey4,
    },
    vertLines: {
      color: Colors.grey4,
    },
  },
  localization: {
    timeFormatter: (time: UTCTimestamp) => dayjs.unix(time).format('DD-MM-YYYY HH:mm:ss Z[Z]'),
  },
};

const EvolutionGraph = (props: EvolutionGraphProps) => {
  const { operations, style, lineColor } = props;
  const operationsReversed = [...operations].reverse();

  const chartContainerRef = createRef<HTMLDivElement>();
  const chart = useRef<IChartApi>();
  const resizeObserver = useRef<ResizeObserver>();
  const lineSeriesRef = useRef<ISeriesApi<'Line'>>();

  useEffect(() => {
    if (chartContainerRef.current === null) return;

    if (chart.current === undefined) {
      chart.current = createChart(chartContainerRef.current, CHART_OPTIONS);
    }

    chart.current.timeScale().fitContent();

    if (lineSeriesRef.current === undefined) {
      lineSeriesRef.current = chart.current.addLineSeries({
        color: lineColor || '#28A79B',
      });
    }

    const lineData: Array<LineData> = operationsReversed
      .map((op, index, array) => {
        const { accum } = op;
        let { timestamp } = op;

        // Hack: lightweight-charts does not support duplication on data in timestamp
        if (index > 0 && timestamp === array[index - 1].timestamp) {
          timestamp += 1; // Add 1 sec to timestamp
        }

        const line: LineData = {
          time: (timestamp / 1000) as UTCTimestamp,
          value: accum,
        };

        return line;
      })
      .filter((line) => !!line.value);

    lineSeriesRef.current.setData(lineData);
  }, [operationsReversed]);

  // Resize chart on container resizes.
  useEffect(() => {
    resizeObserver.current = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;

      if (chart.current) {
        chart.current.applyOptions({ width, height });
        setTimeout(() => {
          if (chart.current) {
            chart.current.timeScale().fitContent();
          }
        }, 10);
      }
    });

    if (chartContainerRef.current) {
      resizeObserver.current.observe(chartContainerRef.current);
    }

    return () => {
      if (resizeObserver.current) resizeObserver.current.disconnect();
    };
  }, []);

  useEffect(() => {
    if (lineSeriesRef.current === undefined) return;

    lineSeriesRef.current.applyOptions({
      ...lineSeriesRef.current.options(),
      color: lineColor,
    });
  }, [lineColor]);

  return (
    <div
      ref={chartContainerRef}
      style={{ height: '300px', ...style }}
      className="chart-container"
    />
  );
};

export default EvolutionGraph;
