import * as React from 'react';
import { SVGProps } from 'react';
const SvgKucoinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2500 2500"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      style={{
        fill: 'none',
      }}
      d="M0 0h2500v2500H0z"
    />
    <path
      d="m830.3 1250.3 740.5 740.6 467.4-467.4c83.6-75.2 211.4-71.9 290.9 7.6s82.9 207.4 7.6 290.9l-616.8 616.9c-82.9 81.5-215.7 81.5-298.6 0l-889.8-890.1v529c0 116.8-94.6 211.4-211.4 211.4-116.8 0-211.4-94.6-211.4-211.4V422c0-116.7 94.6-211.4 211.4-211.4 116.8 0 211.4 94.7 211.4 211.4v529l889.7-889.9c82.8-81.5 215.8-81.5 298.6 0L2337 677.9c75.2 83.6 71.9 211.4-7.6 290.9s-207.4 82.9-290.9 7.6L1571.1 509l-740.8 741.3zm740.8-211.7c-85.6 0-162.8 51.5-195.6 130.6-32.8 79.1-14.7 170.1 45.8 230.7 60.5 60.6 151.6 78.7 230.7 46 79.1-32.8 130.7-109.9 130.7-195.6 0-56.1-22.2-109.9-61.9-149.7-39.6-39.7-93.5-62-149.6-62h-.1z"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: '#23af91',
      }}
    />
  </svg>
);
export default SvgKucoinIcon;
