import { Auth } from '@/modules/auth/domain';

export interface InitialAuthState {
  kind: 'InitialAuthState';
}

export interface NotLoggedAuthState {
  kind: 'NotLoggedAuthState';
}

export interface LoadingAuthState {
  kind: 'LoadingAuthState';
}

export interface LoadedAuthState {
  kind: 'LoadedAuthState';
  auth: Auth;
}

export interface ErrorAuthState {
  kind: 'ErrorAuthState';
  auth?: Auth;
  error: string;
}

export type AuthState =
  | InitialAuthState
  | NotLoggedAuthState
  | LoadingAuthState
  | LoadedAuthState
  | ErrorAuthState;

export const authStateInitialState: AuthState = {
  kind: 'InitialAuthState',
};
