import { Email, UserId } from '@robotrader/common-types';

import { Profile } from '@/modules/profile/domain';

interface UserProps {
  id: UserId;
  email: Email;
  isAdmin: boolean;
  lastLogin: Date;
  createdAt: Date;
  updatedAt: Date;
  profile: Profile;
}

export class User {
  id: UserId;
  email: Email;
  isAdmin: boolean;
  lastLogin: Date;
  createdAt: Date;
  updatedAt: Date;
  profile: Profile;

  private constructor(props: UserProps) {
    this.id = props.id;
    this.email = props.email;
    this.isAdmin = props.isAdmin;
    this.lastLogin = props.lastLogin;
    this.createdAt = props.createdAt;
    this.updatedAt = props.updatedAt;
    this.profile = props.profile;
  }

  static create(props: UserProps): User {
    return new User(props);
  }
}
