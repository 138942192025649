import styled, { css } from 'styled-components/macro';

import Colors from '@/Colors';

interface ContainerProps {
  readonly borderStyle?: 'solid' | 'dashed' | 'dotted';
  readonly borderSize?: number;
}

const commonCSS = css`
  padding: 1.5rem;
`;

const Container = styled.div<ContainerProps>`
  color: ${Colors.white};
  border: ${(props) => `${props.borderSize !== undefined ? props.borderSize : 0}px`}
    ${(props) => (props.borderStyle ? props.borderStyle : 'solid')} ${Colors.primary};
  background: ${Colors.notSoBlack};
  height: fit-content;
  border-radius: 4px;
`;

const Header = styled.div`
  ${commonCSS}
  border-bottom: 1px solid ${Colors.secondary};
`;

const Body = styled.div`
  ${commonCSS}
`;

const Footer = styled.div`
  ${commonCSS}
  border-top: 1px solid ${Colors.secondary};
`;

const Card = { Container, Body, Header, Footer };

export default Card;
