export interface AppConfig {
  development: boolean;
  production: boolean;
  staging: boolean;
  apiUrl: string;
}

export interface EnvironmentsConfig {
  dev: AppConfig;
  prod: AppConfig;
  staging: AppConfig;
}

export const config: EnvironmentsConfig = {
  dev: {
    development: true,
    staging: false,
    production: false,
    apiUrl: '//localhost:5000/api',
  },
  staging: {
    development: false,
    staging: true,
    production: false,
    apiUrl: '//api.robotrader.proj.nakimasolutions.com/api',
  },
  prod: {
    development: false,
    staging: false,
    production: true,
    apiUrl: '//api.nakimainvestments.com/api',
  },
};
