import { OperationI } from '@robotrader/common-types';

import ExchangeNameAndIcon from '../ExchangeNameAndIcon';

const OperationExchangeIcon = (o: OperationI) => {
  const { exchange } = o;

  return <ExchangeNameAndIcon exchangeName={exchange.name} />;
};

export default OperationExchangeIcon;
