import { UserId } from '@robotrader/common-types';
import { DataError, Either, UseCase } from '@robotrader/core-lib';

import { UserRepository } from '@/modules/user/domain';

interface EditUserLeverageProps {
  userId: UserId;
  leverage: number;
}

interface EditUserLeverageUseCaseProps {
  userRepository: UserRepository;
}

export class EditUserLeverageUseCase
  implements UseCase<EditUserLeverageProps, Either<DataError, void>>
{
  private userRepository: UserRepository;

  constructor({ userRepository }: EditUserLeverageUseCaseProps) {
    this.userRepository = userRepository;
  }

  execute(props: EditUserLeverageProps): Promise<Either<DataError, void>> {
    const { userId, leverage } = props;
    return this.userRepository.editUserLeverage(userId, leverage);
  }
}
