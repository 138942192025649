import { Email, Name, Password, Surname } from '@robotrader/common-types';
import { DataError, Either, UseCase } from '@robotrader/core-lib';

import { User, UserRepository } from '@/modules/user/domain';

interface CreateUserProps {
  username: Email;
  password: Password;
  name?: Name;
  surname?: Surname;
}

interface CreateUserUseCaseProps {
  userRepository: UserRepository;
}

export class CreateUserUseCase
  implements UseCase<CreateUserProps, Either<DataError, User>>
{
  private userRepository: UserRepository;

  constructor({ userRepository }: CreateUserUseCaseProps) {
    this.userRepository = userRepository;
  }

  execute(props: CreateUserProps): Promise<Either<DataError, User>> {
    return this.userRepository.createUser(props);
  }
}
