import {
  ExchangeName,
  ExchangeOrderI,
  ExchangePositionI,
  OperationDTO,
  OperationId,
  TradingSignalId,
  UserId,
} from '@robotrader/common-types';
import { dayjs } from '@robotrader/common-utils';
import { DataError, Either } from '@robotrader/core-lib';

import { AppConfig } from '@/core/config';
import { HTTPService } from '@/modules/shared';
import {
  CloseExchangeOrderProps,
  GetAllOperationsFilters,
  ImportExchangeOrderProps,
  ImportExchangeOrdersProps,
  Operation,
  OperationRepository,
} from '@/modules/trader/domain';

import { GetOperationsResponse } from './GetOperationsResponse';
import { OperationMapper } from './OperationMapper';

interface OperationHTTPRepositoryProps {
  httpService: HTTPService;
  config: AppConfig;
}

export class OperationHTTPRepository implements OperationRepository {
  private http: HTTPService;
  private config: AppConfig;

  constructor({ httpService, config }: OperationHTTPRepositoryProps) {
    this.http = httpService;
    this.config = config;
  }

  async getAll(
    filters?: GetAllOperationsFilters,
    flat?: boolean,
  ): Promise<Either<DataError, Operation[]>> {
    const { timeFrom, timeTo, exchangeId, method } = filters || {};

    try {
      let {
        data: { data: operationsDto },
      } = await this.http.get<GetOperationsResponse>(
        `${this.config.apiUrl}/admin/operation`,
        {
          params: {
            timeTo,
            timeFrom,
            exchangeId,
            flat,
            method,
          },
        },
      );

      operationsDto = operationsDto.sort((op1, op2) => {
        const diff =
          dayjs(op1.createdAt).valueOf() - dayjs(op2.createdAt).valueOf();

        if (diff === 0) {
          return op1.id - op2.id;
        }

        return diff;
      });

      return Either.right(
        OperationMapper.operationDtoArrayToOperationArray(
          operationsDto,
          false,
          flat,
        ),
      );
    } catch (error) {
      return Either.left({ kind: 'UnexpectedError', message: error as Error });
    }
  }

  async getAllUserOperations(
    userId: UserId,
    filters?: GetAllOperationsFilters,
  ): Promise<Either<DataError, Operation[]>> {
    const { timeFrom, timeTo, exchangeId, method } = filters || {};

    try {
      let {
        data: { data: operationsDto },
      } = await this.http.get<GetOperationsResponse>(
        `${this.config.apiUrl}/admin/operation`,
        {
          params: {
            timeTo,
            timeFrom,
            exchangeId,
            userId,
            method,
          },
        },
      );

      operationsDto = operationsDto.sort((op1, op2) => {
        const diff =
          dayjs(op1.createdAt).valueOf() - dayjs(op2.createdAt).valueOf();

        if (diff === 0) {
          return op1.id - op2.id;
        }

        return diff;
      });

      return Either.right(
        OperationMapper.operationDtoArrayToOperationArray(operationsDto, true),
      );
    } catch (error) {
      return Either.left({ kind: 'UnexpectedError', message: error as Error });
    }
  }

  async getTradingSignalOperations(
    tradingSignalId: TradingSignalId,
  ): Promise<Either<DataError, Operation[]>> {
    try {
      let {
        data: { data: operationsDto },
      } = await this.http.get<GetOperationsResponse>(
        `${this.config.apiUrl}/admin/operation/trading-signal/${tradingSignalId}`,
      );

      operationsDto = operationsDto.sort((op1, op2) => {
        const diff =
          dayjs(op1.createdAt).valueOf() - dayjs(op2.createdAt).valueOf();

        if (diff === 0) {
          return op1.id - op2.id;
        }

        return diff;
      });

      return Either.right(
        OperationMapper.operationDtoArrayToOperationArray(operationsDto, true),
      );
    } catch (error) {
      return Either.left({ kind: 'UnexpectedError', message: error as Error });
    }
  }

  async delete(id: OperationId): Promise<Either<DataError, boolean>> {
    try {
      const {
        data: { data },
      } = await this.http.delete<{ data: boolean }>(
        `${this.config.apiUrl}/admin/operation/${id}`,
      );

      return Either.right(data);
    } catch (error) {
      return Either.left({ kind: 'UnexpectedError', message: error as Error });
    }
  }

  async getAllUserExchangePositions(
    userId: UserId,
    exchangeName: ExchangeName,
  ): Promise<Either<DataError, ExchangePositionI[]>> {
    try {
      const {
        data: { positions: positionsDto },
      } = await this.http.get<{ positions: ExchangePositionI[] }>(
        `${this.config.apiUrl}/admin/operation/exchange-positions`,
        { params: { userId, exchangeName } },
      );

      positionsDto.sort(
        (op1, op2) => op1.openingTimestamp - op2.openingTimestamp,
      );

      return Either.right(positionsDto);
    } catch (error) {
      return Either.left({ kind: 'UnexpectedError', message: error as Error });
    }
  }

  async getAllUserExchangeOrders(
    userId: UserId,
    exchangeName: ExchangeName,
  ): Promise<Either<DataError, ExchangeOrderI[]>> {
    try {
      const {
        data: { orders: ordersDto },
      } = await this.http.get<{ orders: ExchangeOrderI[] }>(
        `${this.config.apiUrl}/admin/operation/exchange-orders`,
        { params: { userId, exchangeName } },
      );

      return Either.right(ordersDto);
    } catch (error) {
      return Either.left({ kind: 'UnexpectedError', message: error as Error });
    }
  }

  async closeExchangeOrder(
    props: CloseExchangeOrderProps,
  ): Promise<Either<DataError, ExchangeOrderI>> {
    const { userId, exchangeName, orderId, symbol } = props;

    try {
      const {
        data: { order: orderDto },
      } = await this.http.post<{ order: ExchangeOrderI }>(
        `${this.config.apiUrl}/admin/operation/close-exchange-order`,
        { userId, exchangeName, orderId, symbol },
      );

      return Either.right(orderDto);
    } catch (error) {
      return Either.left({ kind: 'UnexpectedError', message: error as Error });
    }
  }

  async importExchangeOrder(
    props: ImportExchangeOrderProps,
  ): Promise<Either<DataError, Operation>> {
    const { userId, exchangeName, orderId, openingOperation } = props;

    try {
      const {
        data: { data: operationDto },
      } = await this.http.post<{ data: OperationDTO }>(
        `${this.config.apiUrl}/admin/operation/import-exchange-order`,
        {
          userId,
          exchangeName,
          orderId,
          openingOperationId: openingOperation
            ? openingOperation.id
            : undefined,
        },
      );

      return Either.right(
        OperationMapper.operationDtoToOperation(operationDto, 0, 0),
      );
    } catch (error) {
      return Either.left({ kind: 'UnexpectedError', message: error as Error });
    }
  }

  async importExchangeOrders(
    props: ImportExchangeOrdersProps,
  ): Promise<Either<DataError, Operation>> {
    const { userId, exchangeName, orderIds, openingOperation } = props;

    try {
      const {
        data: { data: operationDto },
      } = await this.http.post<{ data: OperationDTO }>(
        `${this.config.apiUrl}/admin/operation/import-exchange-orders`,
        {
          userId,
          exchangeName,
          orderIds,
          openingOperationId: openingOperation
            ? openingOperation.id
            : undefined,
        },
      );

      return Either.right(
        OperationMapper.operationDtoToOperation(operationDto, 0, 0),
      );
    } catch (error) {
      return Either.left({ kind: 'UnexpectedError', message: error as Error });
    }
  }
}
