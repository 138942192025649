import { InputHTMLAttributes } from 'react';

import styled, { css } from 'styled-components/macro';

import Colors from '@/Colors';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {}
interface SelectProps extends InputHTMLAttributes<HTMLSelectElement> {}

const commonCSS = css<InputProps | SelectProps>`
  color: ${Colors.white};
  background-color: ${Colors.greyDarker};
  border: 1px solid ${Colors.grey};
  border-radius: 4px;
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;

  &:focus-visible,
  &:focus {
    outline: none;
    border-color: ${Colors.white};
  }

  &::placeholder {
    color: ${Colors.grey};
  }

  ${(props) => {
    if (props.disabled) {
      return `
      background-color: transparent;
      border-color: transparent;
    `;
    }

    return null;
  }}
`;

const TextInput = styled.input.attrs({
  type: 'text',
})`
  ${commonCSS}
`;

const SearchInput = styled.input.attrs({
  type: 'search',
})`
  ${commonCSS}
`;

const PasswordInput = styled.input.attrs({
  type: 'password',
})`
  ${commonCSS}
`;

const CheckboxInput = styled.input.attrs({
  type: 'checkbox',
})`
  ${commonCSS}
  width: auto;
`;

const RadioInput = styled.input.attrs({
  type: 'radio',
})`
  ${commonCSS}
  width: auto;
`;

const DateInput = styled.input.attrs({
  type: 'date',
})`
  ${commonCSS}
`;

const NumberInput = styled.input.attrs({
  type: 'number',
})`
  ${commonCSS}
`;

const EmailInput = styled.input.attrs({
  type: 'email',
})`
  ${commonCSS}
`;

const TimeInput = styled.input.attrs({
  type: 'time',
})`
  ${commonCSS}
`;

const DateTimeInput = styled.input.attrs({
  type: 'datetime-local',
})`
  ${commonCSS}
`;

const SelectInput = styled.select`
  ${commonCSS}
`;

const TextareaInput = styled.textarea`
  ${commonCSS}
`;

const Input = {
  Text: TextInput,
  Search: SearchInput,
  Email: EmailInput,
  Password: PasswordInput,
  Checkbox: CheckboxInput,
  Radio: RadioInput,
  Date: DateInput,
  Number: NumberInput,
  Select: SelectInput,
  Textarea: TextareaInput,
  DateTime: DateTimeInput,
  Time: TimeInput,
};

export default Input;
