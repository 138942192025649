import { ChangeEvent, createRef, useEffect, useState } from 'react';

import {
  Action,
  Direction,
  Exchange,
  ExchangeId,
  isMethod,
  Method,
  OperationId,
  UserId,
} from '@robotrader/common-types';
import { capitalizeString, dayjs, roundNumber, stringToNumber } from '@robotrader/common-utils';
import { isDataError } from '@robotrader/core-lib';
import {
  Block,
  Button,
  ButtonLoading,
  Card,
  Colors,
  DynamicTable,
  EvolutionGraph,
  Flex,
  Icon,
  Input,
  LoadingBlock,
  OperationEntryExitAmount,
  OperationEntryExitDates,
  OperationEntryExitFee,
  OperationEntryExitPrice,
  OperationExchangeIcon,
  OperationOutcomeAndPercentage,
  Select,
  TableObjectProperties,
  Text,
} from '@robotrader/design-system';
import { useSearchParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';

import { useCradle } from '@/app/contexts';
import { useIsMounted } from '@/app/hooks';
import { Cradle } from '@/di/Cradle';
import { Operation } from '@/modules/trader';
import { User } from '@/modules/user';

const EXCHANGE_ID = 'exchangeId';
const USER_ID = 'userId';
const PARAM_TIME_FROM = 'timeFrom';
const PARAM_TIME_TO = 'timeTo';
const PARAM_METHOD = 'method';
const PARAM_ACCUM_TYPE = 'accumType';

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum ACCUM_TYPE_OPTIONS {
  'USDT' = 'usdt',
  'PERCENT' = 'percent',
}

const DEBOUNCE_TIME = 1000; // 1000 ms = 1 second,

const OperationsPage = () => {
  const { traderBloc, userBloc } = useCradle<Cradle>();
  const isMounted = useIsMounted();
  const [searchParams, setSearchParams] = useSearchParams({
    timeFrom: dayjs().subtract(2, 'months').valueOf().toString(),
  });
  const [users, setUsers] = useState<User[] | undefined>(undefined);
  const [userId, setUserId] = useState<UserId | 'all' | undefined>(undefined);
  const [selectedMethod, setSelectedMethod] = useState<Method | undefined>(undefined);
  const [loadingOperations, setLoadingOperations] = useState<boolean>(false);
  const [operations, setOperations] = useState<Operation[] | undefined>(undefined);
  const [allOperationsMap, setAllOperationsMap] = useState<Map<OperationId, Operation> | undefined>(
    undefined,
  );
  const [exchanges, setExchanges] = useState<Exchange[] | undefined>(undefined);
  const [exchangeId, setExchangeId] = useState<ExchangeId | 'all'>('all');
  const [selectedAccumType, setSelectedAccumType] = useState<ACCUM_TYPE_OPTIONS>(
    ACCUM_TYPE_OPTIONS.PERCENT,
  );
  const [timeFrom, setTimeFrom] = useState<number | undefined>(undefined);
  const [timeTo, setTimeTo] = useState<number | undefined>(undefined);
  const pageRef = createRef<HTMLDivElement>();
  const handlePrint = useReactToPrint({
    content: () => pageRef.current,
    copyStyles: true,
  });

  const getOperationAccum = (o: Operation) => {
    const { closingOperation } = o;
    let { accum } = o;

    if (closingOperation !== undefined) {
      const foundOperation = allOperationsMap?.get(closingOperation.id);

      if (foundOperation)
        accum =
          selectedAccumType === ACCUM_TYPE_OPTIONS.PERCENT
            ? foundOperation.accumPercent
            : foundOperation.accum;
    }

    return accum;
  };

  const loadOperations = (uId?: UserId | 'all') => {
    if (loadingOperations) return;

    setLoadingOperations(true);

    traderBloc
      .getOperations({
        userId: uId === 'all' ? undefined : uId,
        filters: {
          timeFrom,
          timeTo,
          exchangeId: exchangeId !== 'all' ? exchangeId : undefined,
          method: selectedMethod,
        },
      })
      .then((ops) => {
        // Create a map with all operations indexed by its id
        const opsMap = ops.reduce((map, op) => {
          map.set(op.id, op);
          return map;
        }, new Map<OperationId, Operation>());
        setAllOperationsMap(opsMap);

        // work only with ENTRY operations
        const filteredOperations = ops.filter((op) => op.action === Action.ENTRY);

        setOperations(filteredOperations);
      })
      .finally(() => setLoadingOperations(false));
  };

  const deleteOperation = async (o: Operation) => {
    try {
      await traderBloc.deleteOperation(o.id);
      toast.success(`Operation deleted successfully!`);
      loadOperations(userId);
    } catch (error: any) {
      const errorMessage = JSON.parse(error.message);

      if (isDataError(error)) {
        if ('message' in error) {
          toast.error(error.message);
        } else {
          toast.error(error);
        }
      } else if (isDataError(errorMessage)) {
        if ('message' in errorMessage) {
          toast.error(JSON.stringify(errorMessage.message));
        } else {
          toast.error(errorMessage);
        }
      } else {
        toast.error(error);
      }
    }
  };

  const OPERATIONS_PROPS: Array<TableObjectProperties<Operation>> = [
    { label: 'Date', data: OperationEntryExitDates },
    {
      label: 'User',
      data: (o: Operation) =>
        `${o.user.profile.name} ${o.user.profile.surname ? o.user.profile.surname[0] : ''}`,
      routerLink: (o: Operation) =>
        `/users/${o.user.id}/exchange-info?exchangeName=${o.exchange.name}`,
    },
    {
      label: 'Exchange',
      data: OperationExchangeIcon,
      align: 'center',
      visible: () => exchangeId === 'all',
    },
    {
      label: 'Symbol',
      data: (o: Operation) => o.pair.name,
      routerLink: (o: Operation) => `/pairs/${o.pair.id}`,
      align: 'center',
    },
    // { label: 'Direction', data: (o: Operation) => `${o.direction}`, align: 'center' },
    {
      label: 'Qty',
      color: (o: Operation) => (o.direction === Direction.SHORT ? Colors.red : Colors.green),
      data: (o: Operation) => {
        const qty = o.direction === Direction.SHORT ? -o.quantity : o.quantity;

        return `${qty}`;
      },
      align: 'center',
    },
    { label: 'Lvrg', data: (o: Operation) => `${o.leverage}`, align: 'center' },
    { label: 'Price', data: OperationEntryExitPrice },
    { label: 'Amount', data: OperationEntryExitAmount },
    { label: 'Fee', data: OperationEntryExitFee },
    { label: 'Net Outcome', data: OperationOutcomeAndPercentage },
    {
      label: `Acc ${selectedAccumType === ACCUM_TYPE_OPTIONS.PERCENT ? '%' : 'USDT'}`,
      data: (o: Operation) => `${roundNumber(getOperationAccum(o), 4)}`,
      color: (o: Operation) => {
        const accum = getOperationAccum(o);

        return accum >= 0 ? Colors.green : Colors.red;
      },
      visible: () => userId !== 'all',
    },
    {
      label: '',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: () => <Icon.Trash width={16} />,
      color: Colors.red,
      onClick: async (o: Operation) => {
        // eslint-disable-next-line no-alert
        const confirm = window.confirm(
          'Deleting an operation is an irreversible action. Are you sure you want to continue?',
        );

        if (!confirm) return;

        await deleteOperation(o);
      },
    },
  ];

  useEffect(() => {
    if (isMounted()) {
      userBloc.getAll().then(setUsers);
      traderBloc.getExchanges().then(setExchanges);
    }
  }, [isMounted]);

  useEffect(() => {
    if (!isMounted()) return;

    // const setupSearchParams = setTimeout(() => {
    const eId = stringToNumber(searchParams.get(EXCHANGE_ID) || '');
    const uId = stringToNumber(searchParams.get(USER_ID) || '');
    const dateFrom = searchParams.get(PARAM_TIME_FROM);
    const dateTo = searchParams.get(PARAM_TIME_TO);
    const method = searchParams.get(PARAM_METHOD);
    const accumType = searchParams.get(PARAM_ACCUM_TYPE);

    if (Object.values(ACCUM_TYPE_OPTIONS).includes(accumType as ACCUM_TYPE_OPTIONS)) {
      setSelectedAccumType(accumType as ACCUM_TYPE_OPTIONS);
    } else {
      setSelectedAccumType(ACCUM_TYPE_OPTIONS.PERCENT);
    }

    if (!Number.isNaN(eId)) {
      setExchangeId(eId);
    } else {
      setExchangeId('all');
    }

    if (isMethod(method)) {
      setSelectedMethod(method);
    } else {
      setSelectedMethod(undefined);
    }

    if (!Number.isNaN(uId)) {
      setUserId(uId);
    } else {
      setUserId('all');
    }

    if (dateFrom && !Number.isNaN(dateFrom)) {
      setTimeFrom(stringToNumber(dateFrom));
    } else {
      setTimeFrom(undefined);
    }

    if (dateTo && !Number.isNaN(dateTo)) {
      setTimeTo(stringToNumber(dateTo));
    } else {
      setTimeTo(undefined);
    }
    // }, 1000);

    // // eslint-disable-next-line consistent-return
    // return () => {
    //   clearTimeout(setupSearchParams);
    // };
  }, [searchParams, isMounted]);

  useEffect(() => {
    if (!isMounted() || userId === undefined) return;

    const setupOpartions = setTimeout(() => {
      loadOperations(userId);
    }, DEBOUNCE_TIME);

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(setupOpartions);
    };
  }, [userId, exchangeId, timeFrom, timeTo, selectedMethod, isMounted]);

  if (operations === undefined) {
    return <LoadingBlock loading text="Loading operations..." />;
  }

  const netOutcomes: Array<number> = operations
    .map((op) =>
      selectedAccumType === ACCUM_TYPE_OPTIONS.PERCENT ? op.outcomePercent : op.outcome,
    )
    .filter((val) => val !== undefined) as Array<number>;
  const fees: Array<number> = operations
    .map((op) => {
      const { closingOperation } = op;

      if (closingOperation) return op.fee + closingOperation.fee;

      return op.fee;
    })
    .filter((val) => val !== undefined) as Array<number>;
  const totalNetOutcome = netOutcomes.reduce((prev, curr) => prev + curr, 0);
  const totalFees = fees.reduce((prev, curr) => prev + curr, 0);
  const totalOutcome = totalNetOutcome + Math.abs(totalFees);

  return (
    <div ref={pageRef} className="page-container">
      <Card.Container>
        <Card.Body style={{ display: 'flex', overflow: 'auto', alignItems: 'end' }}>
          <Block style={{ marginLeft: '1rem' }} $inline>
            <Select
              isClearable
              placeholder="User"
              hideSelectedOptions
              value={users
                ?.filter((u) => u.id === userId)
                .map((e) => ({
                  value: `${e.id}`,
                  label: `${e.profile.name} ${e.profile.surname}`,
                }))}
              options={
                users
                  ? users.map((e) => ({
                      value: `${e.id}`,
                      label: `${e.profile.name} ${e.profile.surname}`,
                    }))
                  : []
              }
              onChange={(newValue) => {
                const { value } = (newValue as any) || {};
                const selectedUserId = value !== '' ? stringToNumber(value) : undefined;

                if (selectedUserId === undefined || Number.isNaN(selectedUserId)) {
                  searchParams.delete(USER_ID);
                } else {
                  searchParams.set(USER_ID, selectedUserId.toString());
                }

                setSearchParams(searchParams);
              }}
            />
          </Block>
          <Block style={{ marginLeft: '1rem' }} $inline>
            <Select
              isClearable
              hideSelectedOptions
              placeholder="Exchange"
              value={exchanges
                ?.filter((e) => e.id === exchangeId)
                .map((e) => ({ value: e.id, label: capitalizeString(e.name) }))}
              options={
                exchanges
                  ? exchanges.map((e) => ({ value: e.id, label: capitalizeString(e.name) }))
                  : []
              }
              onChange={(newValue) => {
                const { value } = (newValue as any) || {};
                const selectedExchangeId = value !== '' ? stringToNumber(value) : undefined;

                if (selectedExchangeId === undefined || Number.isNaN(selectedExchangeId)) {
                  searchParams.delete(EXCHANGE_ID);
                } else {
                  searchParams.set(EXCHANGE_ID, selectedExchangeId.toString());
                }

                setSearchParams(searchParams);
              }}
            />
          </Block>
          <Block style={{ marginLeft: '1rem' }} $inline>
            <Select
              isClearable
              placeholder="Method"
              hideSelectedOptions
              value={Object.entries(Method)
                ?.filter((e) => e[1] === selectedMethod)
                .map((e) => ({
                  value: e[1],
                  label: capitalizeString(e[0]),
                }))}
              // options={exchanges ? exchanges.map((e) => ({ value: e.id, label: e.name })) : []}
              options={Object.entries(Method).map((e) => ({
                value: e[1],
                label: capitalizeString(e[0]),
              }))}
              onChange={(newValue) => {
                const { value } = (newValue as any) || {};

                if (isMethod(value)) {
                  searchParams.set(PARAM_METHOD, value);
                } else {
                  searchParams.delete(PARAM_METHOD);
                }

                setSearchParams(searchParams);
              }}
            />
          </Block>
          <Block $inline style={{ marginLeft: '1rem' }}>
            <Text.Strong style={{ marginRight: '1rem' }}>Date From:</Text.Strong>
            <Input.DateTime
              defaultValue={timeFrom ? dayjs(timeFrom).format('YYYY-MM-DDTHH:mm') : undefined}
              style={{ width: 'auto' }}
              onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                const { value } = evt.target;

                const date = dayjs(value);
                let timestamp: number | undefined;

                if (date.isValid()) {
                  timestamp = date.valueOf();
                } else {
                  timestamp = undefined;
                }

                if (!timestamp) {
                  searchParams.delete(PARAM_TIME_FROM);
                } else {
                  searchParams.set(PARAM_TIME_FROM, `${timestamp}`);
                }

                setSearchParams(searchParams);
              }}
            />
          </Block>
          <Block $inline style={{ marginLeft: '1rem', marginRight: '1rem' }}>
            <Text.Strong style={{ marginRight: '1rem' }}>Date To:</Text.Strong>
            <Input.DateTime
              defaultValue={timeTo ? dayjs(timeTo).format('YYYY-MM-DDTHH:mm') : undefined}
              style={{ width: 'auto' }}
              onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                const { value } = evt.target;

                const date = dayjs(value);
                let timestamp: number | undefined;

                if (date.isValid()) {
                  timestamp = date.valueOf();
                } else {
                  timestamp = undefined;
                }

                if (!timestamp) {
                  searchParams.delete(PARAM_TIME_TO);
                } else {
                  searchParams.set(PARAM_TIME_TO, `${timestamp}`);
                }

                setSearchParams(searchParams);
              }}
            />
          </Block>
          <Block $inline style={{ marginLeft: 'auto' }}>
            <Flex.Container alignItems="center">
              <Button $size="md" onClick={handlePrint} style={{ marginRight: '1rem' }}>
                <Text.Span style={{ marginRight: '1rem' }}>Export</Text.Span>
                <Icon.Print color={Colors.white} size="lg" />
              </Button>
            </Flex.Container>
          </Block>
          <Block $inline style={{ marginLeft: '1rem' }}>
            <ButtonLoading
              $size="md"
              onClick={() => loadOperations(userId)}
              disabled={loadingOperations}
              loading={loadingOperations}
            >
              <Text.Span fontWeight={700} wordBreak="normal">
                Load
              </Text.Span>
            </ButtonLoading>
          </Block>
        </Card.Body>
      </Card.Container>
      <Card.Container
        style={{
          marginTop: '1rem',
        }}
      >
        <Card.Body
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingTop: '1rem',
            paddingBottom: '1rem',
          }}
        >
          {selectedAccumType === ACCUM_TYPE_OPTIONS.PERCENT ? null : (
            <Block $inline>
              <Text.Strong style={{ marginRight: '1rem' }}>Outcome:</Text.Strong>
              <Text.Span color={totalOutcome >= 0 ? Colors.green : Colors.red}>
                {roundNumber(totalOutcome, 3)}
              </Text.Span>{' '}
              USDT
            </Block>
          )}

          <Block
            $inline
            style={{ marginLeft: selectedAccumType === ACCUM_TYPE_OPTIONS.PERCENT ? 0 : '2rem' }}
          >
            <Text.Strong style={{ marginRight: '1rem' }}>Net Outcome:</Text.Strong>
            <Text.Span color={totalNetOutcome >= 0 ? Colors.green : Colors.red}>
              {roundNumber(totalNetOutcome, 3)}
            </Text.Span>{' '}
            {selectedAccumType === ACCUM_TYPE_OPTIONS.PERCENT ? '%' : 'USDT'}
          </Block>
          <Block $inline style={{ marginLeft: '2rem' }}>
            <Text.Strong style={{ marginRight: '1rem' }}>Fees:</Text.Strong>
            <Text.Span color={Colors.red}>{roundNumber(totalFees, 5)}</Text.Span> USDT
          </Block>
          <Block style={{ marginLeft: 'auto' }} $inline>
            <Select
              isClearable
              hideSelectedOptions
              placeholder="Outcome type"
              value={Object.entries(ACCUM_TYPE_OPTIONS)
                ?.filter((e) => e[1] === selectedAccumType)
                .map((e) => ({
                  value: e[1],
                  label: e[0].toLocaleUpperCase(),
                }))}
              // options={exchanges ? exchanges.map((e) => ({ value: e.id, label: e.name })) : []}
              options={Object.entries(ACCUM_TYPE_OPTIONS).map((e) => ({
                value: e[1],
                label: e[0].toLocaleUpperCase(),
              }))}
              onChange={(newValue) => {
                const { value } = (newValue as any) || {};

                searchParams.set(PARAM_ACCUM_TYPE, value);

                setSearchParams(searchParams);
              }}
            />
          </Block>
        </Card.Body>
      </Card.Container>
      {userId && userId !== 'all' && operations && !loadingOperations && (
        <EvolutionGraph
          style={{ flex: 1, minHeight: '20vh', maxHeight: '30vh', marginTop: '1rem' }}
          operations={operations.map((op) => {
            // eslint-disable-next-line no-param-reassign
            op.accum = getOperationAccum(op);
            return op;
          })}
          lineColor={totalNetOutcome >= 0 ? Colors.green : Colors.red}
        />
      )}
      <Card.Container
        id="table-container"
        style={{ marginTop: '1rem', maxHeight: '65vh', overflow: 'auto' }}
      >
        <Card.Body>
          <DynamicTable
            elements={loadingOperations ? [] : operations}
            properties={OPERATIONS_PROPS}
            uniqueKeyName="id"
            exportable
            // showIdColumn
          />
        </Card.Body>
      </Card.Container>
    </div>
  );
};

export default styled(OperationsPage)`
  @media print {
    body {
      zoom: 80%;
    }

    #table-container {
      max-height: 100%;
      height: 100%;
      zoom: 70%;
    }

    @page {
      size: portrait;
    }
  }
`;
