import React from 'react';

import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import ContainerProvider from '@/app/contexts/ContainerContext';
import Navigator from '@/app/pages';
import { container } from '@/di/container';

import './styles/index.scss';
import 'react-toastify/dist/ReactToastify.css';

const App = () => (
  // <React.StrictMode>
  <BrowserRouter>
    <ContainerProvider container={container}>
      <Navigator />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </ContainerProvider>
  </BrowserRouter>
  // </React.StrictMode>
);

export default App;
