/* eslint-disable class-methods-use-this */
import { DataError, Either } from '@robotrader/core-lib';

import { StorageRepository } from '@/modules/shared';

export class LocalStorageRepository implements StorageRepository {
  async getItem<T>(key: string): Promise<Either<DataError, T | undefined>> {
    const valueObject = localStorage.getItem(key);

    if (valueObject) {
      try {
        const value: T | undefined = JSON.parse(valueObject) as T;
        return Either.right(value);
      } catch (error) {
        return Either.left({
          kind: 'UnexpectedError',
          message: error as Error,
        });
      }
    }

    return Either.left({ kind: 'NotFound' });
  }

  async setItem<T>(key: string, value: T): Promise<Either<DataError, void>> {
    localStorage.setItem(key, JSON.stringify(value));
    return Either.right(undefined as void);
  }

  async removeItem(key: string): Promise<Either<DataError, void>> {
    localStorage.removeItem(key);
    return Either.right(undefined as void);
  }
}
