import { TradingSignalId } from '@robotrader/common-types';
import { DataError, Either, UseCase } from '@robotrader/core-lib';

import { Operation, OperationRepository } from '@/modules/trader/domain';

interface GetTradingSignalOperationsProps {
  tradingSignalId: TradingSignalId;
}

interface GetTradingSignalOperationsUseCaseProps {
  operationRepository: OperationRepository;
}

export class GetTradingSignalOperationsUseCase
  implements
    UseCase<GetTradingSignalOperationsProps, Either<DataError, Operation[]>>
{
  private operationRepository: OperationRepository;

  constructor({ operationRepository }: GetTradingSignalOperationsUseCaseProps) {
    this.operationRepository = operationRepository;
  }

  execute(
    props: GetTradingSignalOperationsProps,
  ): Promise<Either<DataError, Operation[]>> {
    const { tradingSignalId } = props;

    return this.operationRepository.getTradingSignalOperations(tradingSignalId);
  }
}
