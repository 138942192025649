import styled from 'styled-components/macro';

const Footer = styled.footer`
  margin-top: auto;
  height: 80px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Footer;
