import { DataError, Either, UseCase } from '@robotrader/core-lib';

import { GetAllUsersProps, User, UserRepository } from '@/modules/user/domain';

interface GetUsersUseCaseProps {
  userRepository: UserRepository;
}

export class GetUsersUseCase
  implements UseCase<GetAllUsersProps, Either<DataError, User[]>>
{
  private userRepository: UserRepository;

  constructor({ userRepository }: GetUsersUseCaseProps) {
    this.userRepository = userRepository;
  }

  execute(props?: GetAllUsersProps): Promise<Either<DataError, User[]>> {
    return this.userRepository.getAll(props);
  }
}
