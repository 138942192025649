import React, { createRef, useEffect, useRef, useState } from 'react';

import { EMA, RSI } from '@debut/indicators';
import { Action, Direction, Pair, TradingSignalI } from '@robotrader/common-types';
import { dayjs, humanizeNumber, roundNumber } from '@robotrader/common-utils';
import {
  BarPrice,
  BarPrices,
  ChartOptions,
  createChart,
  DeepPartial,
  HistogramData,
  IChartApi,
  ISeriesApi,
  LineData,
  LineStyle,
  MouseEventParams,
  OhlcData,
  SeriesMarker,
  SeriesMarkerPosition,
  SeriesMarkerShape,
  TickMarkType,
  UTCTimestamp,
} from 'lightweight-charts';

import { Block } from '@/atoms';
import Colors from '@/Colors';
import { LabelValue } from '@/molecules';

interface OHLCObject {
  time: number;
  open: number;
  high: number;
  low: number;
  close: number;
  volume?: number;
}

interface LightweightChartProps {
  pair: Pair;
  candles: OHLCObject[];
  tradingSignals?: TradingSignalI[];
  style?: React.CSSProperties;
}

const CHART1_OPTIONS: DeepPartial<ChartOptions> = {
  watermark: {
    visible: true,
    fontSize: 24,
    horzAlign: 'center',
    vertAlign: 'center',
    color: 'rgb(0, 137, 172, 0.3)',
    text: 'Robotrader by Nakima',
  },
  crosshair: {
    mode: 0,
  },
  timeScale: {
    timeVisible: true,
    borderColor: Colors.grey5,
    rightOffset: 0,
    barSpacing: 12,
    fixRightEdge: true,
    // fixLeftEdge: true,
    lockVisibleTimeRangeOnResize: true,
    rightBarStaysOnScroll: true,
    borderVisible: true,
    visible: true,
    secondsVisible: false,
    tickMarkFormatter: (time: UTCTimestamp, tickMarkType: TickMarkType) => {
      const formattedTime = dayjs.unix(time);

      switch (tickMarkType) {
        case TickMarkType.Year:
          return formattedTime.format('YYYYMMDD');
        case TickMarkType.Month:
          return formattedTime.format('MMM');
        case TickMarkType.DayOfMonth:
          return formattedTime.format('HH:mm');
        case TickMarkType.Time:
          return formattedTime.format('HH:mm');
        case TickMarkType.TimeWithSeconds:
          return formattedTime.format('HH:mm:ss');
        default:
          break;
      }

      return '';
    },
  },
  layout: {
    backgroundColor: Colors.black,
    textColor: Colors.white,
  },
  grid: {
    horzLines: {
      color: Colors.grey4,
    },
    vertLines: {
      color: Colors.grey4,
    },
  },
  localization: {
    timeFormatter: (time: UTCTimestamp) => dayjs.unix(time).format('DD-MM-YYYY HH:mm:ss Z[Z]'),
    priceFormatter: (bar: BarPrice) => bar.toPrecision(8).padEnd(20),
  },
  rightPriceScale: {
    scaleMargins: {
      top: 0.3,
      bottom: 0.25,
    },
    borderVisible: false,
  },
};

const CHART2_OPTIONS: DeepPartial<ChartOptions> = {
  ...CHART1_OPTIONS,
  watermark: undefined,
  timeScale: {
    timeVisible: false,
    borderVisible: false,
    visible: false,
    barSpacing: 10,
  },
};
const CHART3_OPTIONS: DeepPartial<ChartOptions> = {
  ...CHART2_OPTIONS,
};

const getCandlestickData = (candles: OHLCObject[]): Array<OhlcData> =>
  candles.map((candle) => {
    const { time, open, high, low, close } = candle;
    const data: OhlcData = {
      time: (time / 1000) as UTCTimestamp,
      open,
      high,
      low,
      close,
    };

    return data;
  });

const getCandlestickMarkers = (signals: TradingSignalI[]): Array<SeriesMarker<UTCTimestamp>> =>
  signals.map((signal) => {
    const { timestamp } = signal;
    let position: SeriesMarkerPosition = 'belowBar';
    let text = '';
    let shape: SeriesMarkerShape = 'arrowDown';
    let color: string = '';
    if (signal.action === Action.ENTRY) {
      color = Colors.blue;
      if (signal.direction === Direction.SHORT) {
        text = `Sell @ ${humanizeNumber(signal.price)}`;
        position = 'aboveBar';
        shape = 'arrowDown';
      } else if (signal.direction === Direction.LONG) {
        text = `Buy @ ${humanizeNumber(signal.price)}`;
        position = 'belowBar';
        shape = 'arrowUp';
      }
    } else if (signal.action === Action.EXIT && signal.outcome) {
      color = signal.outcome && signal.outcome >= 0 ? Colors.green : Colors.red;
      text = `${signal.exitReason} @ ${roundNumber(signal.outcome, 2)}%`;
      if (signal.direction === Direction.SHORT) {
        position = 'belowBar';
        shape = 'circle';
      } else if (signal.direction === Direction.LONG) {
        position = 'aboveBar';
        shape = 'circle';
      }
    }

    const marker: SeriesMarker<UTCTimestamp> = {
      time: (timestamp / 1000) as UTCTimestamp,
      position,
      color,
      shape,
      text,
    };

    return marker;
  });

const getVolumeData = (candles: OHLCObject[]): Array<HistogramData> =>
  candles.map((candle) => {
    const { time, volume, open, close } = candle;
    const data: HistogramData = {
      time: (time / 1000) as UTCTimestamp,
      value: volume || 0,
      color: close > open ? '#EF5350' : undefined,
    };

    return data;
  });

const getEmaData = (candles: OHLCObject[], period: number): Array<LineData> => {
  const ema = new EMA(period);

  return candles.map((candle) => {
    const { time, close } = candle;
    const data: LineData = {
      time: (time / 1000) as UTCTimestamp,
      value: ema.nextValue(close),
    };

    return data;
  });
};

const getRSIData = (candles: OHLCObject[], period: number): Array<LineData> => {
  const rsi = new RSI(period);

  return candles.map((candle) => {
    const { time, close } = candle;
    const data: LineData = {
      time: (time / 1000) as UTCTimestamp,
      value: rsi.nextValue(close),
    };

    return data;
  });
};

const LightweightChart = (props: LightweightChartProps) => {
  const { candles, style, pair, tradingSignals } = props;

  const [selectedCandle, setSelectedCandle] = useState<OHLCObject | undefined>();
  const chartContainerRef = createRef<HTMLDivElement>();
  const chart2ContainerRef = createRef<HTMLDivElement>();
  const chart3ContainerRef = createRef<HTMLDivElement>();
  const chart1 = useRef<IChartApi>();
  const chart2 = useRef<IChartApi>();
  const chart3 = useRef<IChartApi>();
  const resizeObserver = useRef<ResizeObserver>();
  const candleSeriesRef = useRef<ISeriesApi<'Candlestick'>>();
  const ema21SeriesRef = useRef<ISeriesApi<'Line'>>();
  const ema8SeriesRef = useRef<ISeriesApi<'Line'>>();
  const rsi14SeriesRef = useRef<ISeriesApi<'Line'>>();
  const volumeSeriesRef = useRef<ISeriesApi<'Histogram'>>();

  useEffect(() => {
    setSelectedCandle(undefined);
  }, [pair]);

  useEffect(() => {
    if (
      chartContainerRef.current === null ||
      chart2ContainerRef.current === null ||
      chart3ContainerRef.current === null
    )
      return;

    if (chart1.current === undefined) {
      chart1.current = createChart(chartContainerRef.current, CHART1_OPTIONS);
      chart1.current.timeScale().fitContent();
    }

    if (chart2.current === undefined) {
      chart2.current = createChart(chart2ContainerRef.current, CHART2_OPTIONS);
      chart2.current.timeScale().fitContent();
    }

    if (chart3.current === undefined) {
      chart3.current = createChart(chart3ContainerRef.current, CHART3_OPTIONS);
      chart3.current.timeScale().fitContent();
    }

    chart1.current.timeScale().subscribeVisibleLogicalRangeChange((range) => {
      if (
        chart1.current === undefined ||
        chart2.current === undefined ||
        chart3.current === undefined ||
        range === null
      )
        return;

      chart2.current.timeScale().setVisibleLogicalRange(range);
      chart3.current.timeScale().setVisibleLogicalRange(range);
    });
    chart2.current.timeScale().subscribeVisibleLogicalRangeChange((range) => {
      if (
        chart1.current === undefined ||
        chart2.current === undefined ||
        chart3.current === undefined ||
        range === null
      )
        return;

      chart1.current.timeScale().setVisibleLogicalRange(range);
      chart3.current.timeScale().setVisibleLogicalRange(range);
    });
    chart3.current.timeScale().subscribeVisibleLogicalRangeChange((range) => {
      if (
        chart1.current === undefined ||
        chart2.current === undefined ||
        chart3.current === undefined ||
        range === null
      )
        return;

      chart1.current.timeScale().setVisibleLogicalRange(range);
      chart2.current.timeScale().setVisibleLogicalRange(range);
    });

    const onCrosshairMove = (param: MouseEventParams) => {
      // get price and time from param or lastBar
      const { seriesPrices } = param;

      if (candleSeriesRef.current === undefined || !seriesPrices.size) {
        return;
      }

      const prices = seriesPrices.get(candleSeriesRef.current) as BarPrices;

      if (prices === undefined || param.time === undefined) {
        return;
      }

      const candle = candles.find((c) => c.time / 1000 === param.time);

      if (candle) {
        setSelectedCandle({
          time: param.time as UTCTimestamp,
          open: prices.open,
          high: prices.high,
          low: prices.low,
          close: prices.close,
          volume: candle.volume,
        });
      }
    };

    chart1.current.subscribeCrosshairMove(onCrosshairMove);
    // chart2.current.subscribeCrosshairMove(onCrosshairMove);

    if (candleSeriesRef.current === undefined) {
      candleSeriesRef.current = chart1.current.addCandlestickSeries({
        priceScaleId: 'right',
      });
    }

    if (ema21SeriesRef.current === undefined) {
      ema21SeriesRef.current = chart1.current.addLineSeries({
        color: Colors.blue,
        title: 'EMA 21',
        lineWidth: 2,
      });
    }

    if (ema8SeriesRef.current === undefined) {
      ema8SeriesRef.current = chart1.current.addLineSeries({
        color: Colors.red,
        title: 'EMA 8',
        lineWidth: 2,
      });
    }

    if (rsi14SeriesRef.current === undefined) {
      rsi14SeriesRef.current = chart3.current.addLineSeries({
        color: Colors.purple,
        title: 'RSI 14',
        lineWidth: 1,
      });
      rsi14SeriesRef.current.createPriceLine({
        price: 30,
        lineVisible: true,
        color: Colors.purple,
        lineWidth: 1,
        lineStyle: LineStyle.LargeDashed,
        axisLabelVisible: false,
        title: '',
      });
      rsi14SeriesRef.current.createPriceLine({
        price: 70,
        lineVisible: true,
        color: Colors.purple,
        lineWidth: 1,
        lineStyle: LineStyle.LargeDashed,
        axisLabelVisible: false,
        title: '',
      });
    }

    if (volumeSeriesRef.current === undefined) {
      volumeSeriesRef.current = chart2.current.addHistogramSeries({
        title: 'Volume',
        priceScaleId: 'right',
        priceFormat: {
          type: 'volume',
        },
      });
    }

    const candlestickData = getCandlestickData(candles);
    candleSeriesRef.current.setData(candlestickData);

    if (tradingSignals) {
      const tSs = tradingSignals.filter((ts) => ts.action !== Action.UPDATE_STOP);
      const markers = getCandlestickMarkers(tSs);
      candleSeriesRef.current.setMarkers(markers.sort((a, b) => a.time - b.time));
    }

    const ema21Data = getEmaData(candles, 21);
    ema21SeriesRef.current.setData(ema21Data);

    const ema8Data = getEmaData(candles, 8);
    ema8SeriesRef.current.setData(ema8Data);

    const rsi14Data = getRSIData(candles, 14);
    rsi14SeriesRef.current.setData(rsi14Data);

    const volumeData = getVolumeData(candles);
    volumeSeriesRef.current.setData(volumeData);

    // eslint-disable-next-line consistent-return
    return () => {
      if (chart1.current) {
        chart1.current.unsubscribeCrosshairMove(onCrosshairMove);
      }

      if (chart2.current) {
        chart2.current.unsubscribeCrosshairMove(onCrosshairMove);
      }
    };
  }, [candles]);

  // Resize chart on container resizes.
  useEffect(() => {
    resizeObserver.current = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;

      if (chart1.current) {
        chart1.current.applyOptions({ width, height });
        chart1.current.priceScale('').applyOptions({
          scaleMargins: {
            top: 0.8,
            bottom: 0,
          },
        });
      }

      if (chart2.current) {
        chart2.current.applyOptions({ width });
      }

      if (chart3.current) {
        chart3.current.applyOptions({ width });
      }

      setTimeout(() => {
        if (chart1.current) {
          chart1.current.timeScale().fitContent();
        }

        if (chart2.current) {
          chart2.current.timeScale().fitContent();
        }

        if (chart3.current) {
          chart3.current.timeScale().fitContent();
        }
      }, 10);
    });

    if (chartContainerRef.current) {
      resizeObserver.current.observe(chartContainerRef.current);
    }

    return () => {
      if (resizeObserver.current) resizeObserver.current.disconnect();
    };
  }, []);

  return (
    <>
      <Block style={{ marginBottom: '1rem' }}>
        {selectedCandle ? (
          <>
            <LabelValue
              label="time"
              value={dayjs(selectedCandle.time * 1000).format('DD-MM-YY HH:mm:ss Z[Z]')}
            />
            <LabelValue
              style={{ marginLeft: '1rem' }}
              valueColor={selectedCandle.close > selectedCandle.open ? Colors.green : Colors.red}
              label="open"
              value={`${selectedCandle.open}`}
            />
            <LabelValue
              style={{ marginLeft: '1rem' }}
              valueColor={selectedCandle.close > selectedCandle.open ? Colors.green : Colors.red}
              label="high"
              value={`${selectedCandle.high}`}
            />
            <LabelValue
              style={{ marginLeft: '1rem' }}
              valueColor={selectedCandle.close > selectedCandle.open ? Colors.green : Colors.red}
              label="low"
              value={`${selectedCandle.low}`}
            />
            <LabelValue
              style={{ marginLeft: '1rem' }}
              valueColor={selectedCandle.close > selectedCandle.open ? Colors.green : Colors.red}
              label="close"
              value={`${selectedCandle.close}`}
            />
            <LabelValue
              style={{ marginLeft: '1rem' }}
              valueColor={selectedCandle.close > selectedCandle.open ? Colors.green : Colors.red}
              label="volume"
              value={`${selectedCandle.volume}`}
            />
          </>
        ) : (
          // <pre>
          //   {JSON.stringify({
          //     ...selectedCandle,
          //     time: dayjs(selectedCandle.time * 1000).format('DD-MM-YY HH:mm:ss Z[Z]'),
          //   })}
          // </pre>
          'No candle selected...'
        )}
      </Block>
      <div
        ref={chartContainerRef}
        style={{ height: '300px', ...style }}
        className="chart-container"
      />
      <div ref={chart3ContainerRef} style={{ height: '140px' }} />
      <div ref={chart2ContainerRef} style={{ height: '120px' }} />
    </>
  );
};

export default LightweightChart;
