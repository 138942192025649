import { useEffect, useState } from 'react';

import { Method, UserId } from '@robotrader/common-types';
import { capitalizeString } from '@robotrader/common-utils';
import {
  Block,
  ButtonLoading,
  Card,
  Colors,
  Flex,
  LoadingBlock,
  Select,
  Text,
} from '@robotrader/design-system';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useCradle } from '@/app/contexts';
import { useIsMounted } from '@/app/hooks';
import { Cradle } from '@/di/Cradle';
import { User } from '@/modules/user/domain';

import UserChangePasswordForm from './UserChangePasswordForm';
import UserEditLeverageForm from './UserEditLeverageForm';
import UserForm from './UserForm';

interface EditUserProps {
  onSuccess?: () => void;
  onError?: () => void;
}

const EditUser = (props: EditUserProps) => {
  const { userBloc, authBloc } = useCradle<Cradle>();
  const { onSuccess, onError } = props;
  const isMounted = useIsMounted();
  const { userId } = useParams();
  const [user, setUser] = useState<User | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  const loadUser = (uId: UserId) => {
    userBloc.getUser(uId).then(setUser);
  };

  const deleteUser = async () => {
    if (!user) return;

    if (user.isAdmin) {
      toast.error('Admin User can not be deleted');
      return;
    }

    if (user.profile.isTrading) {
      toast.error("Can't delete a user while is trading");
      return;
    }

    // eslint-disable-next-line no-alert
    const confirm = window.confirm('Delete the user?');

    if (!confirm) return;

    try {
      const deleted = await userBloc.deleteUser(user.id);

      if (deleted) {
        toast.success(`User deleted successfully`);

        if (onSuccess) onSuccess();
      } else {
        toast.error('Some error ocurred while deleting the user');
        if (onError) onError();
      }
    } catch (error) {
      toast.error('Some error ocurred while editing the user');
      if (onError) onError();
    }
  };

  const editUser = async (params: { name?: string; surname?: string }) => {
    if (!user) return;

    setLoading(true);

    try {
      const edited = await userBloc.editUser(user.id, params);

      if (!edited) {
        toast.error('Some error ocurred while editing the user');
        if (onError) onError();
      } else {
        toast.success(`User edited successfully!`);
        if (onSuccess) onSuccess();
      }
    } catch (error) {
      toast.error('Some error ocurred while editing the user');
      if (onError) onError();
    } finally {
      setLoading(false);
    }
  };

  const changeMethod = async (method: Method) => {
    if (!user) return;

    setLoading(true);

    try {
      const edited = await userBloc.changeMethod({ userId: user.id, method });

      if (!edited) {
        toast.error('Some error ocurred while editing the user');
        if (onError) onError();
      } else {
        toast.success(`User edited successfully!`);
        if (onSuccess) onSuccess();
      }
    } catch (error) {
      toast.error('Some error ocurred while editing the user');
      if (onError) onError();
    } finally {
      setLoading(false);
    }
  };

  const removeMethod = async () => {
    if (!user) return;

    setLoading(true);

    try {
      const edited = await userBloc.removeMethod({ userId: user.id });

      if (!edited) {
        toast.error('Some error ocurred while editing the user');
        if (onError) onError();
      } else {
        toast.success(`User edited successfully!`);
        if (onSuccess) onSuccess();
      }
    } catch (error) {
      toast.error('Some error ocurred while editing the user');
      if (onError) onError();
    } finally {
      setLoading(false);
    }
  };

  const editLeverage = async ({ leverage }: { leverage: number }) => {
    if (!user) return;

    // eslint-disable-next-line no-alert
    const confirm = window.confirm(`Are you sure to change user leverage to ${leverage}?`);

    if (!confirm) return;

    setLoading(true);

    try {
      const edited = await userBloc.editLeverage({ userId: user.id, leverage });

      if (!edited) {
        toast.error('Some error ocurred while editing the user');
        if (onError) onError();
      } else {
        toast.success(`User edited successfully!`);
        if (onSuccess) onSuccess();
      }
    } catch (error) {
      toast.error('Some error ocurred while editing the user');
      if (onError) onError();
    } finally {
      setLoading(false);
    }
  };

  const editPassword = async ({ password }: { password: string }) => {
    if (!user) return;

    // eslint-disable-next-line no-alert
    const confirm = window.confirm(`Are you sure to change user's password?`);

    if (!confirm) return;

    setLoading(true);

    try {
      const edited = await authBloc.editPassword({ userId: user.id, password });

      if (!edited) {
        toast.error('Some error ocurred while editing the user');
        if (onError) onError();
      } else {
        toast.success(`User edited successfully!`);
        if (onSuccess) onSuccess();
      }
    } catch (error) {
      toast.error('Some error ocurred while editing the user');

      if (onError) onError();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isMounted()) return;

    if (userId && !Number.isNaN(userId)) {
      setUser(undefined);
      loadUser(Number(userId));
    }
  }, [isMounted, userId]);

  if (user === undefined) {
    return <LoadingBlock loading text="Loading user..." />;
  }

  return (
    <Card.Container>
      <Card.Body>
        <Flex.Container gap="1rem" flexDirection="column">
          <Flex.Item style={{ flex: 1 }}>
            <Block style={{ marginBottom: '3rem' }}>
              <UserForm user={user} submit={editUser} loading={loading} />
            </Block>
            <Flex.Container style={{ marginBottom: '1rem' }}>
              <Text.Span style={{ flex: 1 }}>Method</Text.Span>
              <Block $inline style={{ flex: 1 }}>
                <Select
                  isClearable
                  placeholder="Method"
                  hideSelectedOptions
                  isDisabled={loading}
                  value={Object.entries(Method)
                    ?.filter((e) => e[1] === user.profile.method)
                    .map((e) => ({
                      value: e[1],
                      label: capitalizeString(e[0]),
                    }))}
                  // options={exchanges ? exchanges.map((e) => ({ value: e.id, label: e.name })) : []}
                  options={Object.entries(Method).map((e) => ({
                    value: e[1],
                    label: capitalizeString(e[0]),
                  }))}
                  onChange={(newValue) => {
                    const { value } = newValue || {};

                    if (!value) {
                      // eslint-disable-next-line no-alert
                      const confirm = window.confirm(
                        'Removing the user method while a running operation is in progress may prevent the trader from closing it properly. Have you checked this, and do you still wish to proceed?',
                      );

                      if (!confirm) return;

                      removeMethod();
                    } else if (value !== user.profile.method) {
                      // eslint-disable-next-line no-alert
                      const confirm = window.confirm(
                        'Changing the user method while a running operation is in progress may prevent the trader from closing it properly. Have you checked this, and do you still wish to proceed?',
                      );

                      if (!confirm) return;

                      changeMethod(value);
                    }
                  }}
                />
              </Block>
            </Flex.Container>
            <Block style={{ marginBottom: '1rem' }}>
              <UserEditLeverageForm user={user} submit={editLeverage} loading={loading} />
            </Block>
            <Block>
              <UserChangePasswordForm submit={editPassword} loading={loading} />
            </Block>
          </Flex.Item>
        </Flex.Container>
        <Flex.Container
          style={{
            alignItems: 'end',
            justifyContent: 'end',
            marginTop: '2rem',
            paddingTop: '2rem',
            borderTop: `1px solid ${Colors.grey}`,
          }}
        >
          <ButtonLoading $variant="cancel" $size="md" loading={loading} onClick={deleteUser}>
            <Text.Span fontWeight={600}>Delete User</Text.Span>
          </ButtonLoading>
        </Flex.Container>
      </Card.Body>
    </Card.Container>
  );
};

export default EditUser;
