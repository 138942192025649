import { DataError, Either, UseCase } from '@robotrader/core-lib';

import {
  MultiScalperV3UseCaseProps,
  TradingSignal,
  TradingSignalRepository,
} from '@/modules/trader/domain';

interface GetMultiScalpingV3SimulationUseCaseProps {
  tradingSignalRepository: TradingSignalRepository;
}

export class GetMultiScalpingV3SimulationUseCase
  implements
    UseCase<MultiScalperV3UseCaseProps, Either<DataError, TradingSignal[]>>
{
  private tradingSignalRepository: TradingSignalRepository;

  constructor({
    tradingSignalRepository,
  }: GetMultiScalpingV3SimulationUseCaseProps) {
    this.tradingSignalRepository = tradingSignalRepository;
  }

  execute(
    props: MultiScalperV3UseCaseProps,
  ): Promise<Either<DataError, TradingSignal[]>> {
    return this.tradingSignalRepository.simulateMultiScalpingV3(props);
  }
}
