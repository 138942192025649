import { createRef, KeyboardEvent } from 'react';

import { generateRandomPassword } from '@robotrader/common-utils';
import {
  Block,
  Button,
  ButtonLoading,
  Colors,
  Flex,
  Icon,
  Input,
  Text,
} from '@robotrader/design-system';
import { toast } from 'react-toastify';

interface NewUserFormProps {
  submit: (props: { username: string; password: string; name?: string; surname?: string }) => void;
  loading?: boolean;
}

const NewUserForm = (props: NewUserFormProps) => {
  const { submit, loading } = props;
  const usernameRef = createRef<HTMLInputElement>();
  const passwordRef = createRef<HTMLInputElement>();
  const nameRef = createRef<HTMLInputElement>();
  const surnameRef = createRef<HTMLInputElement>();

  const onSubmit = () => {
    const username = usernameRef.current ? usernameRef.current.value : undefined;
    const password = passwordRef.current ? passwordRef.current.value : undefined;
    const name = nameRef.current ? nameRef.current.value : undefined;
    const surname = surnameRef.current ? surnameRef.current.value : undefined;

    if (username && password) {
      submit({
        username,
        password,
        name,
        surname,
      });
    } else {
      toast.error('You must provide all the fields');
    }
  };

  const proposePassword = () => {
    if (passwordRef.current === null) return;
    const randomstring = generateRandomPassword(12);

    passwordRef.current.value = randomstring;
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && !loading) {
      onSubmit();
    }
  };

  return (
    <>
      <Flex.Container>
        <Flex.Item
          flex={1}
          style={{ flexDirection: 'column', display: 'flex', margin: '0.5rem 0.5rem' }}
        >
          <Flex.Container style={{ marginBottom: '1rem' }}>
            <Text.Span style={{ flex: 1 }}>Name</Text.Span>
            <Block style={{ flex: 1 }}>
              <Input.Text
                style={{ flex: 1 }}
                ref={nameRef}
                placeholder="Name"
                onKeyDown={handleKeyDown}
              />
            </Block>
          </Flex.Container>
          <Flex.Container style={{ marginBottom: '1rem' }}>
            <Text.Span style={{ flex: 1 }}>Surname</Text.Span>
            <Block style={{ flex: 1 }}>
              <Input.Text
                style={{ flex: 1 }}
                ref={surnameRef}
                placeholder="Surname"
                onKeyDown={handleKeyDown}
              />
            </Block>
          </Flex.Container>
          <Flex.Container style={{ marginBottom: '1rem' }}>
            <Text.Span style={{ flex: 1 }}>Username</Text.Span>
            <Block style={{ flex: 1 }}>
              <Input.Text
                style={{ flex: 1 }}
                ref={usernameRef}
                placeholder="Username"
                onKeyDown={handleKeyDown}
              />
            </Block>
          </Flex.Container>
          <Flex.Container style={{ marginBottom: '1rem' }}>
            <Text.Span style={{ flex: 1 }}>Password</Text.Span>
            <Flex.Container style={{ flex: 1 }}>
              <Input.Text
                style={{ marginRight: '1rem', flex: 1 }}
                ref={passwordRef}
                placeholder="Password"
                onKeyDown={handleKeyDown}
              />
              <Button $size="sm" $variant="secondary" onClick={proposePassword}>
                <Icon.Repeat color={Colors.white} size="lg" />
              </Button>
            </Flex.Container>
          </Flex.Container>
        </Flex.Item>
      </Flex.Container>
      <Flex.Container>
        <Flex.Item
          style={{
            display: 'flex',
            margin: '0.5rem 0.5rem',
            alignItems: 'end',
            justifyContent: 'end',
          }}
        >
          <ButtonLoading $size="md" loading={loading} onClick={onSubmit}>
            <Text.Span fontWeight={600}>Create</Text.Span>
          </ButtonLoading>
        </Flex.Item>
      </Flex.Container>
    </>
  );
};

export default NewUserForm;
