import { Action, TradingSignalI } from '@robotrader/common-types';

import { Flex, Text } from '@/atoms';
import Colors from '@/Colors';

const ActionDirection = (ts: TradingSignalI) => {
  const { action, direction } = ts;
  let color: string = Colors.white;

  if (action === Action.UPDATE_STOP) {
    color = Colors.purple;
  } else if (action === Action.ENTRY) {
    color = Colors.green;
  } else if (action === Action.EXIT) {
    color = Colors.red;
  }

  return (
    <Flex.Container flexDirection="column" gap="0.5rem">
      <Text.Label color={Colors.white}>{direction}</Text.Label>
      <Text.Label color={color}>{action}</Text.Label>
    </Flex.Container>
  );
};

export default ActionDirection;
