import * as React from 'react';
import { SVGProps } from 'react';
const SvgBoxIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 100 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M98 40.4H69.2l-9.6 14.4H40.4l-9.6-14.4H2"
      stroke="#fff"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.56 7.328 2 40.4v28.8a9.6 9.6 0 0 0 9.6 9.6h76.8a9.6 9.6 0 0 0 9.6-9.6V40.4L81.44 7.328A9.6 9.6 0 0 0 72.848 2H27.152a9.6 9.6 0 0 0-8.592 5.328v0Z"
      stroke="#fff"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgBoxIcon;
