import { UserId } from '@robotrader/common-types';
import { DataError, Either, UseCase } from '@robotrader/core-lib';

import { UserRepository } from '@/modules/user/domain';

interface DeleteUserProps {
  userId: UserId;
}

interface DeleteUserUseCaseProps {
  userRepository: UserRepository;
}

export class DeleteUserUseCase
  implements UseCase<DeleteUserProps, Either<DataError, void>>
{
  private userRepository: UserRepository;

  constructor({ userRepository }: DeleteUserUseCaseProps) {
    this.userRepository = userRepository;
  }

  execute(props: DeleteUserProps): Promise<Either<DataError, void>> {
    const { userId } = props;
    return this.userRepository.deleteUser(userId);
  }
}
