export const sleep = (miliseconds: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, miliseconds);
  });

export const truncateString = (
  fullString: string,
  strLen: number,
  separator = '...',
): string => {
  if (!fullString) return '';

  if (fullString.length <= strLen) return fullString;

  const sepLen = separator.length;
  const charsToShow = strLen - sepLen;
  const frontChars = Math.ceil(charsToShow / 2);
  const backChars = Math.floor(charsToShow / 2);

  return (
    fullString.substr(0, frontChars) +
    separator +
    fullString.substr(fullString.length - backChars)
  );
};

export const capitalizeString = (fullString: string): string => {
  if (!fullString || !fullString.length) return '';

  return fullString.charAt(0).toUpperCase() + fullString.slice(1).toLowerCase();
};

export const capitalizeFirstLetterOfEachWord = (fullString: string): string => {
  if (!fullString || !fullString.length) return '';

  const words = fullString.split(' ');

  return words.map((word) => capitalizeString(word)).join(' ');
};

export const stringToNumber = (val: string): number => Number.parseInt(val, 10);

export const hexToNumber = (hex: string): number => Number.parseInt(hex, 16);

export const roundNumber = (num: number, decimals: number = 2): number => {
  const base = 10 ** decimals;

  return Math.round((num + Number.EPSILON) * base) / base;
};

export const humanizeNumber = (num: number): string => {
  if (Number.isNaN(num)) {
    return '';
  }

  let exponential: boolean = false;
  let roundTo = 3;

  if (num === 0) {
    roundTo = 0;
    exponential = false;
  } else if (num < 0.00001) {
    roundTo = 12;
    exponential = true;
  } else if (num < 0.0001) {
    roundTo = 10;
    exponential = true;
  } else if (num < 0.001) {
    roundTo = 8;
    exponential = true;
  } else if (num < 0.01) {
    roundTo = 6;
    exponential = true;
  } else if (num < 0.1) {
    roundTo = 4;
    exponential = false;
  }

  const number = roundNumber(num, roundTo);
  return exponential ? number.toExponential() : `${number}`;
};

export function assertNever(value: never, noThrow?: boolean): never {
  if (noThrow) {
    return value;
  }

  throw new Error(
    `Unhandled discriminated union member: ${JSON.stringify(value)}`,
  );
}

export function generateRandomPassword(length: number = 12) {
  const chars =
    '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let password = '';

  for (let i = 0; i <= length; i += 1) {
    const randomNumber = Math.floor(Math.random() * chars.length);
    password += chars.substring(randomNumber, randomNumber + 1);
  }

  return password;
}
