import { CandleI, PairId } from '@robotrader/common-types';
import { DataError, Either, UseCase } from '@robotrader/core-lib';

import { PairRepository } from '@/modules/trader/domain';

interface GetPairCandlesUseCaseProps {
  pairRepository: PairRepository;
}

export class GetPairCandlesUseCase
  implements UseCase<PairId, Either<DataError, CandleI[]>>
{
  private pairRepository: PairRepository;

  constructor({ pairRepository }: GetPairCandlesUseCaseProps) {
    this.pairRepository = pairRepository;
  }

  execute(pairId: PairId): Promise<Either<DataError, CandleI[]>> {
    return this.pairRepository.getAllPairCandles(pairId);
  }
}
