"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isMethod = exports.METHODS = exports.Method = void 0;
var Method;
(function (Method) {
    Method["MultiScalpingV1"] = "MultiScalpingV1";
    Method["MultiScalpingV2"] = "MultiScalpingV2";
    Method["MultiScalpingV3"] = "MultiScalpingV3";
    Method["TrailingStopV1"] = "TrailingStopV1";
})(Method = exports.Method || (exports.Method = {}));
exports.METHODS = Object.values(Method);
const isMethod = (x) => exports.METHODS.includes(x);
exports.isMethod = isMethod;
