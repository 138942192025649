import { useEffect, useState } from 'react';

import { Direction } from '@robotrader/common-types';
import {
  Colors,
  DynamicTable,
  Flex,
  LoadingBlock,
  Modal,
  OperationEntryExitAmount,
  OperationEntryExitDates,
  OperationEntryExitFee,
  OperationEntryExitPrice,
  // OperationExchangeIcon,
  OperationOutcomeAndPercentage,
  TableObjectProperties,
  TradingSignalsSummary,
} from '@robotrader/design-system';
import { toast } from 'react-toastify';

import { useCradle } from '@/app/contexts';
import { Cradle } from '@/di/Cradle';
import { Operation, TradingSignal } from '@/modules/trader';

interface ModalTradingSignalOperationsProps {
  isOpen: boolean;
  onClose?: () => void;
  tradingSignal: TradingSignal;
}

const ModalTradingSignalOperations = (props: ModalTradingSignalOperationsProps) => {
  const { isOpen, onClose, tradingSignal } = props;
  const { traderBloc } = useCradle<Cradle>();
  const [loading, setLoading] = useState<boolean>(false);
  const [operations, setOperations] = useState<Operation[] | undefined>(undefined);

  const OPERATIONS_PROPS: Array<TableObjectProperties<Operation>> = [
    { label: 'Date', data: OperationEntryExitDates },
    {
      label: 'User',
      data: (o: Operation) =>
        `${o.user.profile.name} ${o.user.profile.surname ? o.user.profile.surname[0] : ''}`,
      routerLink: (o: Operation) =>
        `/users/${o.user.id}/exchange-info?exchangeName=${o.exchange.name}`,
    },
    // {
    //   label: 'Exchange',
    //   data: OperationExchangeIcon,
    //   align: 'center',
    // },
    {
      label: 'Symbol',
      data: (o: Operation) => o.pair.name,
      routerLink: (o: Operation) => `/pairs/${o.pair.id}`,
      align: 'center',
    },
    // { label: 'Direction', data: (o: Operation) => `${o.direction}`, align: 'center' },
    {
      label: 'Qty',
      color: (o: Operation) => (o.direction === Direction.SHORT ? Colors.red : Colors.green),
      data: (o: Operation) => {
        const qty = o.direction === Direction.SHORT ? -o.quantity : o.quantity;

        return `${qty}`;
      },
      align: 'center',
    },
    { label: 'Lvrg', data: (o: Operation) => `${o.leverage}`, align: 'center' },
    { label: 'Price', data: OperationEntryExitPrice },
    { label: 'Amount', data: OperationEntryExitAmount },
    { label: 'Fee', data: OperationEntryExitFee },
    { label: 'Net Outcome', data: OperationOutcomeAndPercentage },
  ];

  const loadOpeationsFromTradingSignal = async (ts: TradingSignal) => {
    setLoading(true);

    try {
      const ops = await traderBloc.getOperationsFromTradingSignal(ts.id);
      setOperations(ops);
    } catch (error) {
      toast.error('An error ocurred while importing order');
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!isOpen) return;

    loadOpeationsFromTradingSignal(tradingSignal);
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeable
      backdropCloseable
      size="container"
      position="center"
      title="TradingSignal Operations"
    >
      {loading && <LoadingBlock loading text="Loading operations..." />}
      {!loading && (
        <Flex.Container flexDirection="column" gap="2rem" style={{ marginTop: '2rem' }}>
          <Flex.Item>
            <TradingSignalsSummary
              showMethod={false}
              showGraph={false}
              showAcum={false}
              showSummary={false}
              tradingSignals={[tradingSignal]}
              searcheable={false}
              pagination={false}
            />
          </Flex.Item>
          <Flex.Item style={{ overflow: 'auto', maxHeight: '60vh' }}>
            <DynamicTable
              elements={loading || operations === undefined ? [] : operations}
              properties={OPERATIONS_PROPS}
              uniqueKeyName="id"
              searchable={false}
              pagination={false}
              // showIdColumn
            />
          </Flex.Item>
        </Flex.Container>
      )}
    </Modal>
  );
};

export default ModalTradingSignalOperations;
