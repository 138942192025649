import { CandleI, ExchangeId, Pair, PairId } from '@robotrader/common-types';
import { DataError, Either } from '@robotrader/core-lib';

import { AppConfig } from '@/core/config';
import { HTTPService } from '@/modules/shared';
import { EditPairParameters, PairRepository } from '@/modules/trader/domain';

import { GetPairCandlesResponse } from './GetPairCandlesResponse';
import { GetPairResponse } from './GetPairResponse';
import { GetPairsResponse } from './GetPairsResponse';
import { PairMapper } from './PairMapper';

interface PairHTTPRepositoryProps {
  httpService: HTTPService;
  config: AppConfig;
}

export class PairHTTPRepository implements PairRepository {
  private http: HTTPService;
  private config: AppConfig;

  constructor({ httpService, config }: PairHTTPRepositoryProps) {
    this.http = httpService;
    this.config = config;
  }

  async getAll(exchangeId?: ExchangeId): Promise<Either<DataError, Pair[]>> {
    const params = exchangeId ? { exchangeId } : {};
    try {
      const {
        data: { data: pairsDto },
      } = await this.http.get<GetPairsResponse>(`${this.config.apiUrl}/pairs`, {
        params,
      });

      return Either.right(
        PairMapper.pairDtoArrayToPairArray(
          pairsDto.sort((p1, p2) => p1.name.localeCompare(p2.name)),
        ),
      );
    } catch (error) {
      return Either.left({ kind: 'UnexpectedError', message: error as Error });
    }
  }

  async getPair(pairId: PairId): Promise<Either<DataError, Pair>> {
    try {
      const {
        data: { data: pairDto },
      } = await this.http.get<GetPairResponse>(
        `${this.config.apiUrl}/admin/pair/${pairId}`,
      );

      return Either.right(PairMapper.pairDtoToPair(pairDto));
    } catch (error) {
      return Either.left({ kind: 'UnexpectedError', message: error as Error });
    }
  }

  async getAllPairCandles(
    pairId: PairId,
  ): Promise<Either<DataError, CandleI[]>> {
    try {
      const {
        data: { data: candlesDto },
      } = await this.http.get<GetPairCandlesResponse>(
        `${this.config.apiUrl}/admin/pair/${pairId}/candles`,
      );

      return Either.right(
        candlesDto.sort((c1, c2) => c1.openTime - c2.openTime),
      );
    } catch (error) {
      return Either.left({ kind: 'UnexpectedError', message: error as Error });
    }
  }

  async editPair(
    pairId: PairId,
    props: EditPairParameters,
  ): Promise<Either<DataError, Pair>> {
    try {
      const {
        data: { data: pairDto },
      } = await this.http.put<GetPairResponse>(
        `${this.config.apiUrl}/admin/pair/${pairId}`,
        props,
      );

      return Either.right(PairMapper.pairDtoToPair(pairDto));
    } catch (error) {
      return Either.left({ kind: 'UnexpectedError', message: error as Error });
    }
  }
}
