import { Exchange } from '@robotrader/common-types';
import { DataError, Either, UseCase } from '@robotrader/core-lib';

import { ExchangeRepository } from '@/modules/trader/domain';

interface GetAllExchangesUseCaseProps {
  exchangeRepository: ExchangeRepository;
}

export class GetAllExchangesUseCase
  implements UseCase<void, Either<DataError, Exchange[]>>
{
  private exchangeRepository: ExchangeRepository;

  constructor({ exchangeRepository }: GetAllExchangesUseCaseProps) {
    this.exchangeRepository = exchangeRepository;
  }

  execute(): Promise<Either<DataError, Exchange[]>> {
    return this.exchangeRepository.getAll();
  }
}
