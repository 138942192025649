import { createRef, KeyboardEvent, useEffect } from 'react';

import { Email, Password } from '@robotrader/common-types';
import { Block, ButtonLoading, Card, Colors, Icon, Input, Text } from '@robotrader/design-system';
import { toast } from 'react-toastify';

import { useCradle } from '@/app/contexts';
import { useBlocState, useConfig } from '@/app/hooks';
import { Cradle } from '@/di/Cradle';

interface LoginFormProps {
  submit: (username: Email, password: Password) => void;
  loading?: boolean;
}

const LoginForm = (props: LoginFormProps) => {
  const config = useConfig();
  const { submit, loading } = props;
  const usernameRef = createRef<HTMLInputElement>();
  const passwordRef = createRef<HTMLInputElement>();

  useEffect(() => {
    if (!usernameRef.current || !passwordRef.current) {
      return;
    }

    usernameRef.current.value = config.development ? 'admin@nakima.es' : '';
    passwordRef.current.value = config.development ? '123qweQWE' : '';
  }, []);

  const onSubmit = () => {
    const username = usernameRef.current?.value;
    const password = passwordRef.current?.value;

    if (username && password) {
      submit(username, password);
    } else {
      toast.error('You must provide all the fields');
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && !loading) {
      onSubmit();
    }
  };

  return (
    <>
      <Input.Text
        style={{ width: '100%', marginBottom: '2rem' }}
        ref={usernameRef}
        placeholder="Username"
        onKeyDown={handleKeyDown}
      />
      <Input.Password
        style={{ width: '100%', marginBottom: '2rem' }}
        ref={passwordRef}
        placeholder="Password"
        onKeyDown={handleKeyDown}
      />
      <Block>
        <ButtonLoading $size="md" style={{ width: '100%' }} loading={loading} onClick={onSubmit}>
          <Text.Span fontWeight={600}>Entrar</Text.Span>
        </ButtonLoading>
      </Block>
    </>
  );
};

const LoginPage = () => {
  const { authBloc } = useCradle<Cradle>();
  const authState = useBlocState(authBloc);

  const login = (username: Email, password: Password) => {
    authBloc.logIn(username, password);
  };

  useEffect(() => {
    if (authState.kind === 'ErrorAuthState') {
      toast.error(authState.error);
    }

    // if (authState.kind === 'LoadedAuthState') {
    //   navigate('/', { replace: true });
    // }
  }, [authState]);

  return (
    <Card.Container borderSize={0} style={{ backgroundColor: Colors.notSoBlack, width: '450px' }}>
      <Card.Body style={{ textAlign: 'center', padding: '4rem' }}>
        <Icon.Logo width={56} height="auto" style={{ marginBottom: '1rem', marginTop: '1rem' }} />
        <Text.H2 fontSize={32} fontWeight={600} style={{ marginBottom: '4rem' }}>
          ROBOTRADER
          <Text.Small style={{ marginLeft: '1rem', verticalAlign: 'middle', color: Colors.red }}>
            Admin
          </Text.Small>
        </Text.H2>
        <LoginForm submit={login} />
      </Card.Body>
    </Card.Container>
  );
};

export default LoginPage;
