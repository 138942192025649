import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

import { AuthLayout, MainLayout } from '@/app/ui/layouts';

import ExchangesPage from './Exchanges/ExchangesPage';
import HomePage from './HomePage/HomePage';
import LoginPage from './LoginPage/LoginPage';
import NotFoundPage from './NotFoundPage/NotFoundPage';
import OperationsPage from './OperationsPage/OperationsPage';
import NewPairPage from './Pairs/NewPairPage';
import PairPage from './Pairs/PairPage';
import PairsPage from './Pairs/PairsPage';
import { AuthRoute, NotAuthRoute } from './ProtectedRoutes';
import RobotStatePage from './RobotState/RobotStatePage';
import SimulationPage from './SimulationPage/SimulationPage';
import TradingSignalsPage from './TradingSignalsPage/TradingSignalsPage';
import NewUserPage from './Users/NewUserPage';
import UserPage from './Users/UserPage';
import UsersPage from './Users/UsersPage';
// import NewExchangePage from './Exchanges/NewExchangePage';

const routes: RouteObject[] = [
  {
    children: [
      {
        element: (
          <AuthRoute>
            <MainLayout />
          </AuthRoute>
        ),
        children: [
          { path: '/', index: true, element: <HomePage /> },
          {
            path: 'users',
            children: [
              { index: true, element: <UsersPage /> },
              { path: 'new', element: <NewUserPage /> },
              { path: ':userId/*', element: <UserPage /> },
            ],
          },
          {
            path: 'exchanges',
            children: [
              { index: true, element: <ExchangesPage /> },
              // { path: 'new', element: <NewExchangePage /> },
            ],
          },
          {
            path: 'pairs',
            children: [
              { index: true, element: <PairsPage /> },
              { path: 'new', element: <NewPairPage /> },
              { path: ':pairId', element: <PairPage /> },
            ],
          },
          // { path: 'candles', element: <CandlesPage /> },
          { path: 'trading-signals', element: <TradingSignalsPage /> },
          { path: 'operations', element: <OperationsPage /> },
          {
            path: 'robot',
            children: [
              { index: true, element: <Navigate to="state" replace /> },
              { path: 'state/*', element: <RobotStatePage /> },
            ],
          },
          { path: 'simulation', element: <SimulationPage /> },
        ],
      },
      {
        path: '/auth',
        element: <AuthLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="/auth/login" replace />,
          },
          {
            path: 'login',
            element: (
              <NotAuthRoute>
                <LoginPage />
              </NotAuthRoute>
            ),
          },
        ],
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
];

const Navigator = () => {
  const Router = useRoutes(routes);

  return Router;
};

export default Navigator;
