"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSupportedExchange = exports.SUPPORTED_EXCHANGES = exports.SupportedExchange = void 0;
var SupportedExchange;
(function (SupportedExchange) {
    SupportedExchange["BINANCE"] = "binance";
    SupportedExchange["KUCOIN"] = "kucoin";
})(SupportedExchange = exports.SupportedExchange || (exports.SupportedExchange = {}));
exports.SUPPORTED_EXCHANGES = Object.values(SupportedExchange);
const isSupportedExchange = (x) => exports.SUPPORTED_EXCHANGES.includes(x);
exports.isSupportedExchange = isSupportedExchange;
