import React, { createElement } from 'react';

import { LinkProps } from 'react-router-dom';

import { Button, Link as LinkAtom, RouterLink as RouterLinkAtom } from '@/atoms';

import Icon from './Icons';

type ButtonProps = React.ComponentProps<typeof Button>;

interface ArrowSizeProps {
  readonly arrowPosition?: 'left' | 'right';
}

export const LinkArrow = (
  props: LinkProps & React.RefAttributes<HTMLAnchorElement> & ArrowSizeProps,
) => {
  const { children, arrowPosition = 'right' } = props;

  return (
    <>
      {arrowPosition === 'left' && <Icon.ArrowLeft size="lg" style={{ marginRight: '0.5rem' }} />}
      {createElement(
        LinkAtom,
        { ...{ ...props, arrowPosition: undefined }, $transparent: true, $underlineHover: true },
        children as React.ReactNode,
      )}
      {arrowPosition === 'right' && <Icon.ArrowRight size="lg" style={{ marginLeft: '0.5rem' }} />}
    </>
  );
};

export const RouterLinkArrow = (
  props: LinkProps & React.RefAttributes<HTMLAnchorElement> & ArrowSizeProps,
) => {
  const { children, arrowPosition = 'right' } = props;

  return (
    <>
      {arrowPosition === 'left' && <Icon.ArrowLeft size="lg" style={{ marginRight: '1rem' }} />}
      {createElement(
        RouterLinkAtom,
        { ...{ ...props, arrowPosition: undefined }, $transparent: true, $underlineHover: true },
        children as React.ReactNode,
      )}
      {arrowPosition === 'right' && <Icon.ArrowRight size="lg" style={{ marginLeft: '0.5rem' }} />}
    </>
  );
};

type LoadingButtonProps = ButtonProps & { loading?: boolean; disabled?: boolean };

export const ButtonLoading = (props: LoadingButtonProps) => {
  const { loading, disabled, children } = props;

  return createElement(
    Button,
    { ...{ ...props, loading: undefined }, disabled: disabled || loading },
    loading ? (
      <Icon.Spinner width={26} style={{ padding: '0.5rem 1rem' }} />
    ) : (
      (children as React.ReactNode)
    ),
  );
};
