import { ChangeEvent, useState } from 'react';

import styled from 'styled-components/macro';

import { Input, Text } from '@/atoms';
import Colors from '@/Colors';

type CheckboxSize = 'sm' | 'md' | 'lg' | 'xl';

type CheckboxProps = {
  label: string;
  checked: boolean;
  rounded: boolean;
  size?: CheckboxSize;
  onChange?: (value: boolean) => void;
};

const Container = styled(Text.Label)`
  display: table;
  cursor: pointer;

  .checkmark {
    /* display: inline-block; */
    display: table-cell;
    vertical-align: middle;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: ${Colors.blue};
    }
  }
`;

const Checkbox = (props: CheckboxProps) => {
  const { label, checked: initialChecked, onChange, rounded, size = 'md' } = props;

  const [checked, setChecked] = useState<boolean>(initialChecked || false);

  const getSize = (checkboxSize: CheckboxSize) => {
    switch (checkboxSize) {
      case 'sm':
        return 16;
      case 'md':
        return 24;
      case 'lg':
        return 32;
      case 'xl':
      default:
        return 40;
    }
  };

  return (
    <Container className="container">
      <Input.Checkbox
        checked={checked}
        onChange={(evt: ChangeEvent<HTMLInputElement>) => {
          if (onChange) onChange(evt.target.checked);
          setChecked(evt.target.checked);
        }}
      />
      <Text.Span
        className="checkmark"
        wordBreak="normal"
        style={{
          marginLeft: '0.5rem',
          width: getSize(size),
          height: getSize(size),
          borderRadius: rounded ? getSize(size) : undefined,
        }}
      >
        {label}
      </Text.Span>
    </Container>
  );
};

export default Checkbox;
