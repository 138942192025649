import { dayjs } from '@robotrader/common-utils';

import { Flex, Text } from '@/atoms';
import { Icon } from '@/molecules';

// eslint-disable-next-line import/prefer-default-export
const UpdateTimeInfo = (props: {
  milliseconds: number;
  textColor?: string;
  loading?: boolean;
  style?: React.CSSProperties;
}) => {
  const { milliseconds, textColor, loading, style } = props;

  return (
    <Flex.Container justifyContent="flex-end" style={style}>
      <Text.Small style={{ marginLeft: '0.5rem', color: textColor }}>
        Automatic update every {dayjs.duration(milliseconds, 'milliseconds').asSeconds()} seconds
      </Text.Small>
      {loading && <Icon.Spinner width={32} color={textColor} />}
    </Flex.Container>
  );
};

export default UpdateTimeInfo;
