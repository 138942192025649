import { createRef, KeyboardEvent } from 'react';

import { Block, ButtonLoading, Flex, Input, Text } from '@robotrader/design-system';

import { User } from '@/modules/user/domain';

interface UserFormProps {
  user: User;
  submit: (props: { name?: string; surname?: string }) => void;
  loading?: boolean;
}

const UserForm = (props: UserFormProps) => {
  const { submit, loading, user } = props;
  const nameRef = createRef<HTMLInputElement>();
  const surnameRef = createRef<HTMLInputElement>();

  const onSubmit = () => {
    const name = nameRef.current ? nameRef.current.value : undefined;
    const surname = surnameRef.current ? surnameRef.current.value : undefined;

    submit({
      name,
      surname,
    });
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && !loading) {
      onSubmit();
    }
  };

  return (
    <>
      <Flex.Container flexDirection="column">
        <Flex.Container style={{ marginBottom: '1rem' }}>
          <Text.Span style={{ flex: 1 }}>Email</Text.Span>
          <Input.Email style={{ flex: 1 }} defaultValue={user.email} disabled />
        </Flex.Container>
        <Flex.Container style={{ marginBottom: '1rem' }}>
          <Text.Span style={{ flex: 1 }}>Is Admin</Text.Span>
          <Block $inline style={{ flex: 1, padding: '1rem' }}>
            <Input.Checkbox checked={user.isAdmin} disabled />
          </Block>
        </Flex.Container>
        <Flex.Container style={{ marginBottom: '1rem' }}>
          <Text.Span style={{ flex: 1 }}>Name</Text.Span>
          <Block style={{ flex: 1 }}>
            <Input.Text
              ref={nameRef}
              placeholder="Name"
              onKeyDown={handleKeyDown}
              defaultValue={user.profile.name}
            />
          </Block>
        </Flex.Container>
        <Flex.Container style={{ marginBottom: '1rem' }}>
          <Text.Span style={{ flex: 1 }}>Surname</Text.Span>
          <Block style={{ flex: 1 }}>
            <Input.Text
              style={{ flex: 1 }}
              ref={surnameRef}
              placeholder="Surname"
              onKeyDown={handleKeyDown}
              defaultValue={user.profile.surname}
            />
          </Block>
        </Flex.Container>
      </Flex.Container>
      <Flex.Container
        style={{
          alignItems: 'end',
          justifyContent: 'end',
        }}
      >
        <ButtonLoading $size="sm" loading={loading} onClick={onSubmit}>
          <Text.Span fontWeight={600}>Edit</Text.Span>
        </ButtonLoading>
      </Flex.Container>
    </>
  );
};

export default UserForm;
